import { Grid } from "@mui/material";
import MenuDesktopComponent from "./desktop/menuDesktopComponent.jsx";
import MenuMobileComponent from "./mobile/menuMobileComponent.jsx";
import { CODES } from "../../../consts/codes.jsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GetTeamcoreToken } from "../../../services/user/userServices.js";
import { openQlikSense } from "../../../helpers/helpers.js";
import { resetUserStatus } from "../../../pages/userSlice.js";
import { resetCurrentCompany } from "../../../parts/listUsers/listUsersSlice.js";
import { useDispatch } from "react-redux";
import { LogoutService } from "../../../services/auth/authServices.js";
/**
 * Componente que renderiza las opciones de menú móvil o de escritorio
 *   -------------------------- PROPS ---------------------
 * open: estado para mostrar u ocultar las opciones de menú nivel 2 o 3
 * setOpen: función que permite abrir o cerrar el menú para ver las opciones de nivel 2 o 3
 * userInfo: información del usuario en sesión
 * listMenuOptionsIcons: lista de opciones de menú con icono
 * fullNameUser: nombre completo del usuario en sesión
 * emailUser: correo del usuario en sesión
 * i18n: objeto con las traducciones
 * role: rol del usuario en sesión
 * setSelectedOption: función que permite seleccionar una opción de menú
 * optionsLvl1Admin: opciones de menú para administrador
 * selectedOption: opción de menú seleccionada
 * navigate: función que permite navegar a la ruta seleccionada
 * excludeUrl: función que permite excluir una url de las opciones de menú
 * homeRoutes: rutas de inicio de la aplicación
 * openQlikSense: función que permite abrir Qlik Sense
 *  idToken: token de autenticación
 * fetchTeamcore: función que permite iniciar sesión en Teamcore
 * routeHome: ruta de inicio de la aplicación
 * t: función que permite traducir las etiquetas a otros idiomas
 * isDesktop: estado para determinar si es desktop o no
 * setShowMenu: función que permite mostrar u ocultar el menú lateral pero solo para móvil
 */
const MenuOptionsComponent = ({
  open,
  setOpen,
  userInfo,
  listMenuOptionsIcons,
  fullNameUser,
  emailUser,
  role,
  excludeUrl,
  homeRoutes,
  routeHome,
  isDesktop,
  setShowMenu,
  setIsOpenModalInfo,
  setModalTitle,
  setModalContent,
}) => {
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState({
    menuOptionName: "Página principal",
    nameEnglish: "Home",
  });

  //Token temcore
  const [idToken, setIdToken] = useState("");

  // Opciones de nivel 1 para admin

  const optionsLvl1Admin = [
    {
      name: t("App.listUsers.title"),
      navigateTo: "/admin/listUsers",
    },
    {
      name: t("App.listMenuOptions.title"),
      navigateTo: "/admin/listMenuOptions",
    },
    {
      name: t("App.listRoles.title"),
      navigateTo: "/admin/listRoles",
    },
    {
      name: t("App.geographies.title"),
      navigateTo: "/admin/adminGeographies",
    },
    {
      name: t("App.manageBusinessGroup.title"),
      navigateTo: "/admin/manageBusinessGroup",
    },
    {
      name: t("App.stores.title"),
      navigateTo: "/admin/stores",
    },
    {
      name: t("App.deleteAndReload.title"),
      navigateTo: "/admin/deleteAndReload",
    },
    {
      name: t("App.adminSendAllies.title"),
      navigateTo: "/admin/adminSendAllies",
    },
    {
      name: t("App.allies.title"),
      navigateTo: "/admin/reloadAllies",
    },
    {
      name: t("App.adminCompanies.title"),
      navigateTo: "/admin/adminCompanies",
    },
    {
      name: t("App.specialFilesCTyS.title"),
      navigateTo: "/admin/adminSpecialFiles",
    },
    {
      name: t("App.specialFilesClient.titleSpecialFiles"),
      navigateTo: "/admin/adminSpecialFilesClient",
    },
    {
      name: t("App.businessRules.title"),
      navigateTo: "/admin/adminBusinessRules",
    },
    {
      name: t("App.indicatorCards.title"),
      navigateTo: "/admin/adminIndicatorCards",
    },
    {
      name: t("App.adminPurchaseOrder.menuOptionTitle"),
      navigateTo: "/admin/purchaseOrders",
    },
    {
      name: t("App.indicadors.title"),
      navigateTo: "/admin/adminIndicators",
    },
    {
      name: t("App.adminTransmittedValue.title"),
      navigateTo: "/admin/adminTransmittedValue",
    },
  ];

  const dispatch = useDispatch();

  /**
   * Función para cerrar sesión
   */
  const handleLogOut = async () => {
    try {
      dispatch(resetUserStatus());
      dispatch(resetCurrentCompany());
      const service = await LogoutService();

      if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          service.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          service.data.responseCode === CODES.COD_RESPONSE_HTTP_FORBIDDEN
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      }
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
      console.log("==============Error handleLogout======================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Hace submit del formulario para iniciar sesión en teamcore
   */
  const fetchTeamcore = async () => {
    if (userInfo) {
      //Limpio el email para token
      let userEmail = userInfo.email;
      let cleanEmail = userEmail.replaceAll(/[^a-zA-Z0-9]/g, "");

      const { status, data } = await GetTeamcoreToken({
        userName: "colgatecenanaliticos@gmail.com",
        companyCode: 21,
      });
      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        setIdToken(data);

        setTimeout(() => {
          // Ocultar las opciones de menú
          setOpen(false);
          const form = document.getElementById("teamcore-form");
          form.submit();
        }, [500]);
      } else {
        setModalTitle(t("App.validationMessages.error"));
        setModalContent({
          data: { responseMessage: t("App.validationMessages.systemError2") },
        });
        setIsOpenModalInfo(true);
      }
    }
  };

  return (
    <Grid container width={"100%"}>
      {isDesktop ? (
        <MenuDesktopComponent
          open={open}
          setOpen={setOpen}
          userInfo={userInfo}
          fullNameUser={fullNameUser}
          emailUser={emailUser}
          i18n={i18n}
          role={role}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          navigate={navigate}
          excludeUrl={excludeUrl}
          homeRoutes={homeRoutes}
          openQlikSense={openQlikSense}
          idToken={idToken}
          fetchTeamcore={fetchTeamcore}
          optionsLvl1Admin={optionsLvl1Admin}
          routeHome={routeHome}
          t={t}
          listMenuOptionsIcons={listMenuOptionsIcons}
          handleLogOut={handleLogOut}
        />
      ) : (
        <MenuMobileComponent
          setOpen={setOpen}
          fullNameUser={fullNameUser}
          emailUser={emailUser}
          i18n={i18n}
          role={role}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          navigate={navigate}
          excludeUrl={excludeUrl}
          homeRoutes={homeRoutes}
          openQlikSense={openQlikSense}
          idToken={idToken}
          fetchTeamcore={fetchTeamcore}
          optionsLvl1Admin={optionsLvl1Admin}
          routeHome={routeHome}
          listMenuOptionsIcons={listMenuOptionsIcons}
          setShowMenu={setShowMenu}
          handleLogOut={handleLogOut}
        />
      )}
    </Grid>
  );
};

export default MenuOptionsComponent;
