import { React, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

import { CODES } from "../../../consts/codes";
import { useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import LoopIcon from "@mui/icons-material/Loop";
import { FilterSalesForce } from "../../../components/common/filterSalesForce";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MUIDataTable from "mui-datatables";
import Chip from "@mui/material/Chip";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import {
  DownloadSalesForceErrorLog,
  DownloadSalesForceFile,
  GetSalesForceGuide,
  GetSalesForceList,
  GetSalesForceTemplate,
  UploadSalesForceService,
} from "../../../services/user/salesForceServices";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import CircularProgress from "@mui/material/CircularProgress";
import ModalPreviewSalesForce from "../../../components/common/Modals/modalPreviewSalesForce";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import FileState from "../../../components/common/fileState";
import DownloadTemplate from "../../../components/common/UploadFile/downloadTemplate";
import DownloadGuide from "../../../components/common/UploadFile/downloadGuide";
import FilterBusinessGroupWB from "../../../components/common/Filters/businessGroup/filterWithoutButtons";
import UploadFileBG from "../../../components/common/UploadFile/uploadFileBusinessGroup";
import { getIsBusinessGroup } from "../../../parts/businessGroup/businessGroupSlice";
import FilterBusinessGroupUniqueWB from "../../../components/common/Filters/businessGroup/filterUniqueWithoutButton";
import UploadFile from "../../../components/common/UploadFile/uploadFile";
import ModalSeeCompanies from "../../../components/common/AdminUsersClient/modalSeeCompanies";

export const UploadSalesForce = () => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Selector
   */

  //User info
  const userInfo = useSelector(getUser);

  // Grupo empresarial
  const isBusinessGroup = useSelector(getIsBusinessGroup);

  /**
   * Use State
   */
  //Archivo seleccionado
  const [fileSelected, setFileSelected] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);

  //Manejo de datos de tabla
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  //FIltros
  const [expanded, setExpanded] = useState(false);
  const [filterApply, setFilterApply] = useState(null);
  const [unfilteredData, setUnfilteredData] = useState([]);

  //Carga de resultados
  const [foundResults, setFoundResults] = useState(false);
  const [loading, setLoading] = useState(true);

  //Refresh
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [refresh, setRefresh] = useState(false);

  //Modal success
  const [openSuccessLoadModal, setOpenSuccessLoadModal] = useState(false);

  //Modales de error
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [responseLoadSalesForce, setResponseLoadSalesForce] = useState({});

  //Manejo de comportamientos y datos grupo empresarial
  const [showUpload, setShowUpload] = useState(null);
  const [checkedReplicate, setCheckedReplicate] = useState(false);
  const [companySelected, setCompanySelected] = useState(null);

  //No Filter Found
  const [filterNotFound, setFilterNotFound] = useState(false);

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "startDate",
      label: t("App.salesForce.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "endDate",
      label: t("App.salesForce.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.salesForce.user"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "companies",
      label: t("App.listUsers.enterpriseN"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "file",
      label: t("App.salesForce.file"),
      options: {
        filter: false,
        sort: true,

        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value}
              control={
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) =>
                    handleDownloadFile(value.fileName, value.pathFileName)
                  }
                >
                  {value.fileName}
                </a>
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
    {
      name: "state",
      label: t("App.salesForce.status"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FileState
              value={value}
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
    {
      name: "errors",
      label: t("App.salesForce.errors"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value}
              control={
                value.awsUploadLog ? (
                  <a
                    className="default-text__linkBlue__sm"
                    onClick={(event) => setOpenErrorModal(true)}
                  >
                    ERROR
                  </a>
                ) : (
                  <a
                    className="default-text__linkBlue__sm"
                    onClick={(event) =>
                      handleDownloadLog(
                        value.pathLogFileName,
                        value.logFileName
                      )
                    }
                  >
                    {value.logFileName}
                  </a>
                )
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
  ];

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.salesForce.placeholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.salesForce.placeholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * useEffect para traer la información a mostrar en la tabla
   */
  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      setIsLoadingRefresh(true);

      try {
        setLoading(true);
        setData([]);

        if (mounted && userInfo) {
          //TODO: Ajustar el objeto GetSalesForceList por integracion de grupo empresarial (yo lo ajuste creo q esta bien jajaja)
          const obj = {
            country: userInfo.company.country,
            eanProvider: userInfo.company.eanCompany,
            emailLoad: userInfo.email,
            idFileType: 2,
          };

          const salesForceService = await GetSalesForceList(obj);

          if (salesForceService.status === CODES.COD_RESPONSE_HTTP_OK) {
            setLoading(false);
            if (
              salesForceService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
            ) {
              const responseMessage = salesForceService.data.data;
              if (responseMessage.length > 0) {
                setFoundResults(true);
                //Retornando el objeto creado para la tabla
                const ProcessRows = responseMessage.map((item, idx) => {
                  return {
                    id: !item.idLoad ? "  " : item.idLoad,
                    startDate:
                      item.starDate === null
                        ? "  "
                        : moment(item.starDate).format("YYYY-MM-DD hh:mm"),
                    endDate:
                      item.endDate === null
                        ? "  "
                        : moment(item.endDate).format("YYYY-MM-DD hh:mm"),
                    user: !item.userLoad ? "  " : item.userLoad,
                    companies:
                      item.lstCompanies?.length > 1 ? (
                        <ModalSeeCompanies
                          data={item.lstCompanies}
                          userName={item.userLoad}
                        />
                      ) : (
                        item.lstCompanies !== null &&
                        item.lstCompanies.length > 0 &&
                        item.lstCompanies[0].renameCompany
                      ),
                    file: !item.fileName
                      ? "  "
                      : {
                          fileName: item.fileName,
                          pathFileName: item.pathFileName,
                        },
                    state: !item.state ? "  " : item.state,
                    errors: item.awsUploadLog
                      ? {
                          awsUploadLog: item.awsUploadLog,
                        }
                      : {
                          logFileName: item.logFileName,
                          pathLogFileName: item.pathLogFileName,
                          awsUploadLog: item.awsUploadLog,
                        },
                  };
                });

                setUnfilteredData(ProcessRows);
                setData(ProcessRows);
              }
            }
          }
        }

        setIsLoadingRefresh(false);
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();

    return () => {
      mounted = false;
    };
  }, [refresh, userInfo]);

  /**
   * useEffect que muestra el componente por default si no es grupo
   */
  useEffect(() => {
    if (!isBusinessGroup) {
      setShowUpload(true);
    } else {
      setShowUpload(false);
    }
  }, [isBusinessGroup]);

  /**
   * useEffect que elimina el archivo previamente cargado si la carga se oculta
   */
  useEffect(() => {
    if (!showUpload) {
      setFileSelected(null);
      setCheckedReplicate(false);
    }
  }, [showUpload]);

  /**
   *
   * Función para manejar la actualización del archivo de fuerza de ventas
   *
   * @param {Event} event
   */
  const handleUploadFile = async (event) => {
    try {
      const obj = {
        file: fileSelected[0],
        companies: companySelected,
        checkReplicateBusinessGroup: isBusinessGroup
          ? checkedReplicate
            ? 1
            : 0
          : 0,
      };

      const salesForceService = await UploadSalesForceService(obj);
      setCheckedReplicate(false);

      if (salesForceService.status === CODES.COD_RESPONSE_HTTP_OK) {
        setResponseLoadSalesForce(salesForceService);
        setOpenSuccessLoadModal(true);
        setRefresh(!refresh);
        if (
          salesForceService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          setFileSelected(null);
        } else {
          setFileUploaded(false);
        }
      } else {
        setFileUploaded(false);
      }
    } catch (error) {
      setFileUploaded(false);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar la plantilla (archivo con la estructura adecuada para la carga)
   *
   * @param {Event} event
   */
  const handleDownloadTemplate = async (event) => {
    event.preventDefault();

    try {
      const templateService = await GetSalesForceTemplate();

      if (templateService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          templateService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(templateService.data.data.fileContent),
            templateService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar la guía de cómo llenar la plantilla
   *
   * @param {Event} event
   */
  const handleDownloadGuide = async (event) => {
    event.preventDefault();

    try {
      const guideService = await GetSalesForceGuide();

      if (guideService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          guideService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(guideService.data.data.fileContent),
            guideService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar el archivo cargado (subido)
   *
   * @param {String} fileName
   * @param {String} pathFileName
   */
  const handleDownloadFile = async (fileName, pathFileName) => {
    const obj = {
      pathFileName: pathFileName,
      fileName: fileName,
    };

    try {
      const fileService = await DownloadSalesForceFile(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (fileService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar el archivo con los errores del archivo cargado (subido)
   *
   * @param {String} pathLogFileName
   * @param {String} logFileName
   */
  const handleDownloadLog = async (pathLogFileName, logFileName) => {
    const obj = {
      logFileName: logFileName,
      pathLogFileName: pathLogFileName,
    };

    try {
      const logService = await DownloadSalesForceErrorLog(obj);

      if (logService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (logService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(
            decode(logService.data.data.fileContent),
            logService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <>
      <ModalInfo
        title={"Carga de Fuerza De Venta"}
        responseData={responseLoadSalesForce}
        open={openSuccessLoadModal}
        onClose={() => {
          setOpenSuccessLoadModal(false);
        }}
      />

      <ModalInfo
        title={errorTitle}
        responseData={{ status: 0 }}
        open={isOpenErrorModal}
        onClose={() => {
          setIsOpenErrorModal(false);
        }}
      />

      <ModalInfo
        title={t("App.states.systemError")}
        responseData={{
          data: {
            responseCode: 0,
            responseMessage: t("App.validationMessages.systemError"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />

      <Grid
        item
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className="u-marginB"
        spacing={2}
      >
        <DownloadTemplate
          helpText={t("App.salesForce.template")}
          buttonText={t("App.salesForce.downloadTemplate")}
          handleDownloadTemplate={handleDownloadTemplate}
        />
      </Grid>

      <Grid item style={{ width: "100%" }}>
        <Grid
          container
          className="grid__container"
          style={{ marginTop: "0.2%", marginBottom: "1%", padding: "0" }}
          direction="column"
          rowSpacing={2}
        >
          <Grid item className="guide-file">
            <DownloadGuide
              helpText={"App.salesForce.guide"}
              handleDownloadGuide={handleDownloadGuide}
            />
          </Grid>

          {isBusinessGroup && (
            <Grid item>
              <FilterBusinessGroupUniqueWB
                title={t("App.manageBusinessGroup.filter.title4")}
                setCompanySelected={setCompanySelected}
                setShowUpload={setShowUpload}
              />
            </Grid>
          )}

          <Grid item className="upload-file">
            {isBusinessGroup ? (
              showUpload && (
                <UploadFileBG
                  dragText={t("App.salesForce.dragging")}
                  handleUploadFile={handleUploadFile}
                  fileSelected={fileSelected}
                  setFileSelected={setFileSelected}
                  setErrorModal={setIsOpenErrorModal}
                  setErrorTitle={setErrorTitle}
                  userInfo={userInfo}
                  showUpload={showUpload}
                  setCheckedReplicate={setCheckedReplicate}
                />
              )
            ) : (
              <UploadFile
                dragText={t("App.salesForce.dragging")}
                handleUploadFile={handleUploadFile}
                fileSelected={fileSelected}
                setFileSelected={setFileSelected}
                setErrorModal={setIsOpenErrorModal}
                setErrorTitle={setErrorTitle}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          className="u-marginB u-marginT "
          spacing={2}
        >
          <Grid item xs={12} sm={4} md={4} xl={1.8} lg={3} >
            {showUpload && (
              <ModalPreviewSalesForce
                companySelected={companySelected}
                isBusinessGroup={isBusinessGroup}
              />
            )}
          </Grid>

          {foundResults && (
            <Grid item xs={12} sm={2.9} md={1.2} lg={1.9} xl={1.16} >
              <Button
              sx={{width:{sm:"100% !important"},
                borderRadius:"8px !important"}}
                disableRipple
                startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
                className="btn__outlined__blue prev-refresh-buttons"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                {!isLoadingRefresh ? (
                  t("App.buttonLabels.refresh")
                ) : (
                  <CircularProgress size={18} color="inherit" />
                )}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item className="u-marginB">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
            </Grid>

            <Grid item>
              <p className="display-small__primary-one">
                {t("App.salesForce.subtitle")}
              </p>
            </Grid>
          </Grid>
        </Grid>
        {foundResults && (
          <>
            <Grid className="u-marginB grid__container__fullW">
              <Accordion
                onChange={() => {
                  setExpanded(!expanded);
                }}
                sx={{boxShadow:"none"}}
              >
                <AccordionSummary className={
                  expanded ? "accordion__filter" : "accordion__filter"
                }
                  expandIcon={
                    <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
                  }
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <TuneOutlinedIcon
                    sx={{
                      fontSize: 20,
                      color: "#543ab4",
                      transform:"rotate(90deg)"
                    }}
                  />

                  <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                    {" "}
                    {t("App.listUsers.filter")}
                  </p>
                </AccordionSummary>

                <AccordionDetails>
                  <FilterSalesForce
                    userInfo={userInfo}
                    unfilteredData={unfilteredData}
                    setData={setData}
                    setFilterApply={setFilterApply}
                    idFile={2}
                    setIsLoadingData={setIsLoadingData}
                    setFilterNotFound={setFilterNotFound}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item className="u-marginB" style={{ width: "100%" }}>
              {filterApply && (
                <Chip className="chip__filter" label={filterApply.label} />
              )}
            </Grid>

            <Grid item className="u-marginB" style={{ width: "100%" }}>
              {loading ? (
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={skeletonOptions}
                  className="dataTable__salesForce"
                />
              ) : !filterNotFound ? (
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}
                  className="dataTable__salesForce"
                />
              ) : (
                <NoMatchImage />
              )}
            </Grid>
          </>
        )}

        {!foundResults && <NoInfoComponent type="load" />}
      </>
    </>
  );
};
