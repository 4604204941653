import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChangePasswordImage from "../../assets/common/changePasswordImage.svg";

const changePasswordImage = () => {
  return (
    <Grid className="newPasswordImage" xl={12}>
      <img
        src={ChangePasswordImage}
        alt="changePassword Image"
        className="newPasswordImage_img"
      />
    </Grid>
  );
};

export default changePasswordImage;
