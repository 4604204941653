import {
  Grid,
  Button,
  ThemeProvider,
  createTheme,
  IconButton,
  Skeleton,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from "react";
import { ModalCreateBusinessGroup } from "../../../components/common/Modals/modalCreateBusinessGroup";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { ModalEditBusinessGroup } from "../../../components/common/Modals/modalEditBusinessGroup";
import { CODES } from "../../../consts/codes";
import {
  GetBusinessGroup,
  ManageBusinessGroups,
} from "../../../services/user/businessGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCountriesAndCompanies,
  getCountriesAndCompaniesStatus,
} from "../../../parts/businessGroup/businessGroupSlice";

import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import { FilterAdminBusinessGroup } from "../../../components/common/Filters/filterAdminBusinessGroup";
import FileState from "../../../components/common/fileState";

const ManageBusinessGroup = () => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Table
   */

  const columns = [
    {
      name: "country",
      label: t("App.manageBusinessGroup.table.country"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "creationDate",
      label: t("App.manageBusinessGroup.table.creationDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => {
          const modificationDate = (
            <div>
              <h4>
                {t("App.listDynamicPeriodicReport.table.columns.column1")}
              </h4>
              <h5 className="subheading-datatable">
                {t("App.homeDashboard.client.table.columns.formatDate")}
              </h5>
            </div>
          );

          return modificationDate;
        },
      },
    },
    {
      name: "creationUser",
      label: t("App.manageBusinessGroup.table.creationUser"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "modificationDate",
      label: t("App.manageBusinessGroup.table.modificationDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        hint: t("App.manageBusinessGroup.tooltips.tooltip1"),
        customHeadLabelRender: () => {
          const modificationDate = (
            <div>
              <h4>
                {t("App.listDynamicPeriodicReport.table.columns.column3")}
              </h4>
              <h5 className="subheading-datatable">
                {t("App.homeDashboard.client.table.columns.formatDate")}
              </h5>
            </div>
          );

          return modificationDate;
        },
      },
    },
    {
      name: "modificationUser",
      label: t("App.manageBusinessGroup.table.modificationUser"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        hint: t("App.manageBusinessGroup.tooltips.tooltip2"),
      },
    },
    {
      name: "businessGroupName",
      label: t("App.manageBusinessGroup.table.businessGroupName"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "eanProvider",
      label: t("App.manageBusinessGroup.table.eanMainCompany"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "provider",
      label: t("App.manageBusinessGroup.table.provider"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "state",
      label: t("App.manageBusinessGroup.table.state"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => value,
      },
    },
    {
      name: "edit",
      label: t("App.adminCompanies.table.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => {
          return (
            <IconButton
              onClick={() => {
                getBusinessGroupInfo(value.idGroup);
              }}
            >
              <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
            </IconButton>
          );
        },
      },
    },
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: "none",
              border: "1px solid gray",
              borderRadius: "5px",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            footer: {
              border: "none",
            },
            root: {
              textAlign: "center",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              justifyContent: "center",
            },
            toolButton: {
              // * Secreto para que los headers del datatable queden centrados con el contenido
              margin: "0",
              padding: "0",
            },
            root: {
              minWidth: "100px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              textAlign: "center",
            },
          },
        },
      },
    });

  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    jumpToPage: true,
    searchPlaceholder: t("App.manageBusinessGroup.table.searchPlaceholder"),
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.manageBusinessGroup.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    jumpToPage: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   */

  //Countries and companies selectors
  const countriesAndCompaniesStatus = useSelector(
    getCountriesAndCompaniesStatus
  );

  //data
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  //Create
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);

  //Edit
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [groupInfo, setGroupInfo] = useState(null);

  //Response modal
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);
  const [modalResponseTitle, setModalResponseTitle] = useState("");
  const [modalResponseContent, setModalResponseContent] = useState({});

  //No Selection and No Filter Found
  const [noContent, setNoContent] = useState(true);
  const [isNoFilterFound, setIsNoFilterFound] = useState(false);

  //Filters to trigger

  const [filters, setFilters] = useState({
    lstContries: [],
    lstEansProviders: [],
    lstProviders: [],
    lstIdsGroups: [],
  });

  /**
   * Use Effect
   */

  /**
   * Loads countries and companies info for create/edit modals
   */
  useEffect(() => {
    try {
      if (countriesAndCompaniesStatus === "fetch") {
        dispatch(fetchCountriesAndCompanies());
      }
    } catch (error) {
      console.log(
        "==============Error Get CountriesAndCompanies======================"
      );
      console.log(error);
      console.log("====================================");
    }
  }, [dispatch, countriesAndCompaniesStatus]);

  /**
   * Handles
   */

  /**
   * Get business group information to open edit modal
   * @param {string} id id of businessGroup
   */
  const getBusinessGroupInfo = async (id) => {
    const requestGetBusinessGroup = await GetBusinessGroup({
      idGroup: id,
    });

    const responseCode = requestGetBusinessGroup.data.responseCode;
    if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
      setGroupInfo(requestGetBusinessGroup.data.responseMessage);
      setIsOpenEditModal(true);
    } else {
      setModalResponseTitle("Error");
      setModalResponseContent(requestGetBusinessGroup);
      setIsOpenModalResponse(true);
    }
  };

  /**
   * Sets structure of table to data
   * @param {array} data data to set structure
   * @returns formatted array with data
   */
  const setDataStructureArray = (data) => {
    const finalArray = data.map((companyOfGroup) => {
      return {
        ...companyOfGroup,
        state: <FileState value={companyOfGroup.state} />,
        edit: { idGroup: companyOfGroup.idGroup },
      };
    });
    return finalArray;
  };

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      justifyContent="center"
      alignItems="flex-start"
    >
      <ModalCreateBusinessGroup
        open={isOpenCreateModal}
        setOpen={setIsOpenCreateModal}
        setIsOpenModalResponseCreate={setIsOpenModalResponse}
        setModalResponseCreateInfo={setModalResponseContent}
        setModalResponseTitle={setModalResponseTitle}
        setData={setData}
        data={data}
        filters={filters}
        setIsLoadingData={setIsLoadingData}
        setNoContent={setNoContent}
        setIsNoFilterFound={setIsNoFilterFound}
      />

      <ModalEditBusinessGroup
        open={isOpenEditModal}
        setOpen={setIsOpenEditModal}
        groupInfo={groupInfo}
        setIsOpenModalResponseCreate={setIsOpenModalResponse}
        setModalResponseCreateInfo={setModalResponseContent}
        setModalResponseTitle={setModalResponseTitle}
        filters={filters}
        setIsNoFilterFound={setIsNoFilterFound}
        setData={setData}
        setIsLoadingData={setIsLoadingData}
        setDataStructureArray={setDataStructureArray}
      />

      <Grid item style={{ marginTop: "2%", paddingBottom: "1rem"}}>
        <p className="display-large__primary-three">
          {t("App.manageBusinessGroup.title")}
        </p>
      </Grid>

      <Grid item className="full-width">
        <Grid container alignItems="center" justifyContent="flex-end !important">
          <Grid item xs={12} sm={3.4} lg={2} xl={1.3}
          sx={{paddingBottom: "27px !important"}}
          >
            <Button sx={{width:"100%"}}
              className="btn__filled__blue"
              onClick={() => {
                setIsOpenCreateModal(true);
              }}
              startIcon={<AddIcon sx={{ fontSize: 22 }} />}
            >
              {t("App.manageBusinessGroup.buttonText")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* -------------------  */}
      <Grid item className="companies_info_container full-width">
        <Grid container direction="column" gap={4}>
          <Grid item>
            <FilterAdminBusinessGroup
              setData={setData}
              setIsNoFilterFound={setIsNoFilterFound}
              setNoContent={setNoContent}
              setIsLoadingData={setIsLoadingData}
              setFilters={setFilters}
              setDataStructureArray={setDataStructureArray}
            />
          </Grid>

          <Grid item className="full-width" style={{ marginBottom: "10px"}}>
            <ThemeProvider theme={getMuiTheme()}>
              <div>
                {!noContent ? (
                  isLoadingData ? (
                    <MUIDataTable
                      className="dataTable__base full-width"
                      data={[]}
                      columns={columns}
                      options={skeletonOptions}
                    />
                  ) : isNoFilterFound ? (
                    <NoMatchImage />
                  ) : (
                    <MUIDataTable
                      className="dataTable__base full-width"
                      data={data}
                      columns={columns}
                      options={options}
                    />
                  )
                ) : (
                  <NoSelectionComponent type="business-group" />
                )}
              </div>
            </ThemeProvider>
          </Grid>
        </Grid>
      </Grid>
      {/* ---------------------  */}
      <ModalInfo
        title={modalResponseTitle}
        responseData={modalResponseContent}
        open={isOpenModalResponse}
        onClose={() => {
          setIsOpenModalResponse(false);
          setModalResponseContent({});
          setModalResponseTitle("");
        }}
      />
    </Grid>
  );
};

export default ManageBusinessGroup;
