import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chip, Grid, Skeleton } from "@mui/material";
import MUIDataTable from "mui-datatables";
import FilterAdminSendAllies from "../../components/common/Filters/filterAdminSendAllies";
import EditSendAlly from "../../components/common/SendAlly/editSendAlly";
import CreateSendAlly from "../../components/common/SendAlly/createSendAlly";
import NoMatchImage from "../../components/common/NoMatchImage/noMatchImage";
import { useTranslation } from "react-i18next";
import { NoSelectionComponent } from "../../components/common/noImages/noSelectionComponent";
import FileState from "../../components/common/fileState";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getUser } from "../userSlice";
import {
  fetchAllGeographies,
  getGeographies,
  getStatusGeographies,
} from "../../parts/geographies/geographiesSlice";
import moment from "moment";
import {
  GetAlliesList,
  GetConfigurationsList,
} from "../../services/admin/sendAlliesService";
import { CODES } from "../../consts/codes";
import Loader from "../../components/common/Loader";
import dayjs from "dayjs";

const AdminSendAllies = () => {
  /**
   * t de useTranslation para la traducción de los textos
   */
  const { t } = useTranslation();

  /**
   * Configuraciones para las columnas de la tabla
   */
  const columns = [
    {
      name: "eanCompany",
      label: t("App.adminSendAllies.table.eanCompany"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "company",
      label: t("App.adminSendAllies.table.company"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "eanCommerce",
      label: t("App.adminSendAllies.table.eanPartner"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "commerce",
      label: t("App.adminSendAllies.table.partner"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "configurationDate",
      label: t("App.adminSendAllies.table.configurationDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.adminSendAllies.table.configurationDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "modificationDate",
      label: t("App.adminSendAllies.table.modificationDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        hint: t("App.adminSendAllies.tooltips.tooltip1"),
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.adminSendAllies.table.modificationDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "user",
      label: t("App.adminSendAllies.table.user"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        hint: t("App.adminSendAllies.tooltips.tooltip2"),
      },
    },
    {
      name: "state",
      label: t("App.adminSendAllies.table.state"),
      options: {
        filter: false,
        sort: false,
        searchable: true,
        customBodyRender: (value) => value,
      },
    },
    {
      name: "edit",
      label: t("App.adminCompanies.table.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => {
          return (
            <EditSendAlly
              ally={value}
              handleInfoText={handleInfoText}
              fullNameUser={fullNameUser}
              handleFilters={handleFilters}
              handleConfigurationsArray={handleConfigurationsArray}
            />
          );
        },
      },
    },
  ];

  /**
   * Configuraciones para la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.adminSendAllies.searchable"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Opciones de skeleton para dar efecto de cargar la tabla cuando se hace una busquedad por los filtros
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.adminSendAllies.searchable"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Selector
   */
  const countries = useSelector(getGeographies);
  const countriesStatus = useSelector(getStatusGeographies);

  const userInfo = useSelector(getUser);
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;

  //data
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [filterApply, setFilterApply] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [allyList, setAllyList] = useState([]);

  //No Selection and No Filter Found
  const [noContent, setNoContent] = useState(true);
  const [isNoFilterFound, setIsNoFilterFound] = useState(true);
  // Mostrar loader
  const [showLoader, setShowLoader] = useState(false);

  //Filters to trigger
  const [filters, setFilters] = useState({
    lstContries: [],
    lstEansProviders: [],
    lstProviders: [],
    lstIdsGroups: [],
  });

  /**
   * Consulta los países registrados en la plataforma si estos no se encuentran en Redux
   */
  useEffect(() => {
    if (!Array.isArray(countries)) return;

    const getGeographies = async () => {
      try {
        if (countriesStatus === "fetch") {
          dispatch(fetchAllGeographies());
        }
      } catch (error) {
        console.log(
          "============== ERROR adminSendAllies.jsx useEffect getGeographies ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
    setCountryList(countries);
  }, [dispatch, countriesStatus]);

  /**
   * useEffect para retornar la lista de los aliados disponibles
   */
  useEffect(() => {
    const fetchAllies = async () => {
      try {
        const alliesListService = await GetAlliesList();

        const array = alliesListService.data.responseMessage;

        if (!Array.isArray(array)) return;

        const list = array.map((ally) => {
          return {
            allyName: ally.alliedName,
            idAlly: ally.idAllied,
          };
        });

        setAllyList(list);
      } catch (error) {
        console.log(
          "============== ERROR adminSendAllies.jsx useEffect fetchAllies ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    fetchAllies();
  }, []);

  /**
   *
   * Función para estructurar la data de manera adecuada para la tabla
   *
   * @param {*} data data a estructurar
   * @returns arreglo con la data formateada
   */
  const setDataStructureArray = (data) => {
    const finalArray = data.map((ally) => {
      return {
        eanCompany: ally.eanProvider,
        company: ally.provider,
        eanCommerce: ally.eanTrader,
        commerce: ally.trader,
        configurationDate: moment(ally.creationDate).format("YYYY-MM-DD"),
        modificationDate: ally.modificationDate
          ? moment(ally.modificationDate).format("YYYY-MM-DD")
          : "",
        user: ally.creationUser,
        state: <FileState value={ally?.state?.toLowerCase()} />,
        edit: ally,
      };
    });

    return finalArray;
  };

  /**
   *
   * FUnción para retornar una lista con los objetos que se generan para cada configuración
   *
   * @param {Array} configurations
   * @param {Array} ally
   * @returns arreglo con los objetos de las configuraciones
   */
  const handleConfigurationsArray = (configurations, ally) => {
    const arrayOfConfigurations = configurations.map((configuration, index) => {
      return {
        eanTrader: configuration.commerceList.eanCommerce
          ? configuration.commerceList.eanCommerce
          : ally.map((ally) => ally.eanTrader)[index],
        trader: configuration.commerceList.commerceName
          ? configuration.commerceList.commerceName
          : ally.map((ally) => ally.trader)[index],
        dailyPath: configuration.dailyPath.toLowerCase(),
        historicalPath: configuration.historicalPath
          ? configuration.historicalPath.toLowerCase()
          : "",
        informationReloadPath: configuration.reloadPath
          ? configuration.reloadPath.toLowerCase()
          : "",
        state: configuration.state,
        sendToDate: configuration.sendToDate === "" || configuration.sendToDate === null || configuration.sendToDate === undefined
        ? ""
        : dayjs(configuration.sendToDate).format("YYYY-MM-DD"),
      };
    });
    return arrayOfConfigurations;
  };

  /**
   *
   * Función actualizar la info de la tabla después de que se edita o crea una ruta
   *
   * @param {Object} ally objeto con la información para el filtro
   */
  const handleFilters = async (ally) => {
    try {
      const obj = {
        country: ally.country,
        idAllied: ally.idAllied,
        lstEansProviders: [],
        lstEansTraders: [],
      };

      const filterService = await GetConfigurationsList(obj);

      if (
        filterService.status === CODES.COD_RESPONSE_HTTP_OK &&
        filterService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const formattedData = setDataStructureArray(
          filterService.data.responseMessage
        );

        setData(formattedData);
        setNoContent(false);
        setIsNoFilterFound(false);
      }
    } catch (error) {
      console.log(
        "================= ERROR filterAdminSendAllies.jsx function handleFilters ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para renderizar el recuadro con información para el usuario
   */
  const handleInfoText = () => (
    <>
      <br />

      <Grid item style={{ width: "90%" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          // className="modalCreateUsers__form-paragraph"
          className="state-colors__secondary-color-two"
        >
          <Grid item style={{ padding: "0.5%" }} className="col-flex">
            <InfoOutlinedIcon fontSize="large" />
          </Grid>

          <Grid item className="col-flex label__secondary-two">
            <p>{t("App.adminSendAllies.modals.infoText")}</p>
          </Grid>
        </Grid>
      </Grid>

      <br />
    </>
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
    >
      <Grid item style={{ marginTop: "2%" }}>
        <p className="display-large__primary-one">
          {t("App.adminSendAllies.title")}
        </p>
      </Grid>

      <Grid item container justifyContent="flex-end">
        <CreateSendAlly
          handleInfoText={handleInfoText}
          fullNameUser={fullNameUser}
          countryList={countryList}
          setIsLoadingData={setIsLoadingData}
          setNoContent={setNoContent}
          setIsNoFilterFound={setIsNoFilterFound}
          allyList={allyList}
          setData={setData}
          data={data}
          handleFilters={handleFilters}
          handleConfigurationsArray={handleConfigurationsArray}
        />
      </Grid>

      {/* -------------------  */}

      <Grid
        item
        container
        className="companies_info_container full-width u-box-padding__sm"
      >
        <Grid container direction="column" gap={3}>
          <Grid item>
            <FilterAdminSendAllies
              setFilterApply={setFilterApply}
              setData={setData}
              setIsNoFilterFound={setIsNoFilterFound}
              setNoContent={setNoContent}
              setIsLoadingData={setIsLoadingData}
              setFilters={setFilters}
              setDataStructureArray={setDataStructureArray}
              countryList={countryList}
              allyList={allyList}
              setShowLoader={setShowLoader}
            />
          </Grid>

          <Grid item container gap={2}>
            {filterApply.country && (
              <Grid item>
                <Chip
                  className="chip__filter"
                  label={`${t("App.adminCompanies.filters.country").replace(
                    "*",
                    ""
                  )}: ${filterApply.country}`}
                  variant="outlined"
                />
              </Grid>
            )}

            {filterApply.ally && (
              <Grid item>
                <Chip
                  className="chip__filter"
                  label={`${t("App.adminSendAllies.ally").replace("*", "")}: ${
                    filterApply.ally
                  }`}
                  variant="outlined"
                />
              </Grid>
            )}
          </Grid>

          <Grid item className="full-width" style={{ marginBottom: "10px" }}>
            {!noContent ? (
              !isNoFilterFound ? (
                isLoadingData ? (
                  <MUIDataTable
                    className="dataTable__base"
                    data={[]}
                    columns={columns}
                    options={skeletonOptions}
                  />
                ) : (
                  <MUIDataTable
                    className="dataTable__base"
                    data={data}
                    columns={columns}
                    options={options}
                  />
                )
              ) : (
                <NoMatchImage />
              )
            ) : (
              <NoSelectionComponent type="sendAlly" />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Loader showLoader={showLoader} />
    </Grid>
  );
};

export default AdminSendAllies;
