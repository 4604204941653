import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { CODES } from "../../../consts/codes";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ModalInfo = ({ title, responseData, onClose, open, close = true, className="", classNameTitle=""}) => {
  const { data, status } = responseData;

  const handleClose = () => {
    onClose();
  };

  let titleColor = "";
  if (status === CODES.COD_RESPONSE_HTTP_OK) {
    if (
      data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
      data.responseCode === CODES.COD_RESPONSE_HTTP_OK ||
      data.responseCode === CODES.COD_RESPONSE_HTTP_CREATED
    ) {
      titleColor = "success";
    } else if (
      data.responseCode === CODES.COD_RESPONSE_ERROR_RESET_PASSWORD ||
      data.responseCode === CODES.COD_RESPONSE_ERROR_GENERATE_FILE
    ) {
      titleColor = "warningPassword";
    } else {
      titleColor = "error";
    }
  } else if (status === CODES.COD_RESPONSE_HTTP_ERROR) {
    titleColor = "error";
  } else if (
    status === CODES.COD_RESPONSE_ERROR_RESET_PASSWORD ||
    status === CODES.COD_RESPONSE_ERROR_GENERATE_FILE
  ) {
    titleColor = "warningPassword";
  } else {
    titleColor = "warning";
  }

  const { t } = useTranslation();

  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose && close ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ color: "#543ab4" }} />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <div>
      <Dialog
        className="modal__notification"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <div style={{ padding: "2rem" }}>
          <DialogTitleContainer onClose={handleClose}>
            {titleColor === "success" && (
              <TaskAltOutlinedIcon
                sx={{ color: "#60CC64", fontSize: "5.5rem" }}
              />
            )}
            {titleColor === "warning" && (
              <InfoOutlinedIcon sx={{ color: "#4dcafa", fontSize: "5.5rem" }} />
            )}
            {titleColor === "warningPassword" && (
              <WarningAmberOutlinedIcon
                sx={{ color: "#ffa836", fontSize: "5.5rem" }}
              />
            )}
            {titleColor === "error" && (
              <WarningAmberOutlinedIcon
                sx={{ color: "#4dcafa", fontSize: "5.5rem" }}
              />
            )}
          </DialogTitleContainer>

          <DialogContent className="modal-info-title">
            {titleColor === "success" && (
              <label className={classNameTitle}>
                {title ? title : t("App.validationMessages.success")}
              </label>
            )}
            {titleColor === "error" && (
              <label className={classNameTitle}>
                {title ? title : t("App.validationMessages.error")}</label>
            )}
            {titleColor === "warning" && <label className={classNameTitle}> 
              {" " + title}</label>}
            <br />
            <p className={className || "regular-text_black"}>
              {data?.responseMessage?.message || data?.responseMessage}
            </p>
          </DialogContent>

          <DialogActions>
            <div>
              <Button
                variant="contained"
                className="btn__filled__blue modal-decision-btn"
                onClick={handleClose}
              >
                {t("App.buttonLabels.accept")}
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default ModalInfo;
