import { Button, Grid } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const DownloadTemplate = ({ handleDownloadTemplate, helpText, buttonText }) => {
  return (
    <>
      <Grid
        xs={12}
        sm={6.3}
        md={5}
        lg={"auto"}
        item
        className="help-text-container"
      >
        <p className="heading__tertiary-one" 
        style={{ textAlign:"right"}}> 
        {helpText}</p>
      </Grid>

      <Grid xs={9} sm={"auto"} md={2.8} lg={"auto"} item>
        <Button
          onClick={handleDownloadTemplate}
          disableRipple
          startIcon={<FileDownloadOutlinedIcon />}
          className="btn__outlined__blue full-width"
        >
          {buttonText}
        </Button>
      </Grid>
    </>
  );
};

export default DownloadTemplate;
