import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import SelectMaster from "./selectMaster";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import UploadExternalFile from "./uploadExternalFile";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
const ExternalFiles = () => {
  const [currentType, setCurrentType] = useState("0");
  const [type, setType] = useState(null);
  const [sender, setSender] = useState(null);
  const [receiver, setReceiver] = useState(null);
  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);
  const [endDate, setEndDate] = useState("");

  /**
   * Use Translation
   */
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item>
        <h1 className="display-large-two__primary-two">
          {t("App.externalFiles.title")}
        </h1>
      </Grid>

      <Grid item className="grid__container__fullW">
        <SelectMaster
          setType={setType}
          type={type}
          currentType={currentType}
          setCurrentType={setCurrentType}
          setSender={setSender}
          sender={sender}
          receiver={receiver}
          setReceiver={setReceiver}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Grid>
      {type && sender && receiver && type !== "0" && (
        <Grid item className="grid__container__fullW u-marginB">
          <Accordion className="accordion__upload">
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Grid container direction="row">
                <Grid item>
                  <FileUploadOutlinedIcon className="accordion__upload__uploadIcon" />
                </Grid>
                <Grid item className="col-flex">
                  <p
                    className="display-small__primary-one"
                    style={{ paddingLeft: "1rem" }}
                  >
                    {t("App.externalFiles.subtitle")}
                  </p>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className="accordion__upload__detail">
              <UploadExternalFile
                userInfo={userInfo}
                currentType={currentType}
                sender={sender}
                receiver={receiver}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </Grid>
  );
};

export default ExternalFiles;
