import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  createFilterOptions,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ApplyFiltersProduct,
  CreateContractedProduct,
  GetAvailableProduct,
  GetMenuLevel1,
} from "../../services/admin/adminServices";
import ModalInfo from "./Modals/modalInfo";
import { CODES } from "../../consts/codes";
import { ModalEditProduct } from "./modalEditProduct";
import { resetProductsAndRolesStatus } from "../../parts/listUsers/listUsersSlice";
import { useDispatch } from "react-redux";
import {
  getCommerce,
  getCommerces,
  getElementName,
} from "../../helpers/helpers";
import { GetTradersService } from "../../services/user/deleteAndReload/deleteAndReload";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { GetSalesMan } from "../../services/user/dynamicReport/dynamicReportServices";

const ModalCreateProduct = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [createProduct, setCreateProduct] = useState({});
  const [availableProducts, setAvailableProducts] = useState([]);
  const [commerceList, setCommerceList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [productName, setProductName] = useState("");
  const [idOptionType, setIdOptionType] = useState(null);
  const [eanTraders, setEanTraders] = useState([]);
  const [tradersListAux, setTradersListAux] = useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Opciones para la configuración del listado del autocomplete de comercio
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * Función para cerrar y reiniciar los valores de los campos del formulario
   */
  const handleClose = () => {
    setOpen(false);
    reset();
    setIdOptionType(0);
  };

  /**
   * Función para abrir el modal y traer los listados de productos, comercios y vendedores
   */
  const handleOpen = () => {
    /**
     * Función para traer la data necesaria para el select de productos y el autocomplete de comercios
     */
    async function fetchData() {
      try {
        if (props.data.length > 0 || Object.keys(props.filters).length > 0) {
          const obj = {
            country: props.filters.country,
            eanCompany: props.filters.eanCompany,
          };

          getCommerceList({ country: obj.country });

          getAvailableProductList(obj);

          getSellerList(obj);
        }
      } catch (error) {
        console.log(
          "============== Error modalCreateProduct.jsx function fetchData ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();
    setOpen(true);
  };

  /**
   *
   * Función para obtener el listado de comercioss y colocarlo en el state de commerceList
   *
   * @param {Object} object
   */
  const getCommerceList = async (object) => {
    const commercePerCountry = await GetTradersService(object);

    if (
      commercePerCountry.status === CODES.COD_RESPONSE_HTTP_OK &&
      commercePerCountry.data.responseCode ===
        CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      const commercesList = commercePerCountry.data.responseMessage.map(
        (commerce) => {
          return {
            label: commerce.companyName,
            value: commerce.eanCompany,
          };
        }
      );

      const completeList =
        commercesList.length > 0
          ? [{ label: t("App.downloadSalesForce.selectAll") }, ...commercesList]
          : [];

      setCommerceList(completeList);
    }
  };

  /**
   *
   * Función para obtener el listado de productos y colocarlo en el state de availableProducts
   *
   * @param {Object} object
   */
  const getAvailableProductList = async (object) => {
    const availableProductsService = await GetAvailableProduct(object);

    if (
      availableProductsService.status === CODES.COD_RESPONSE_HTTP_OK &&
      availableProductsService.data.responseCode ===
        CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      const products = availableProductsService.data.responseMessage.map(
        (product) => {
          return {
            label: product.menuOptionName,
            value: product.idMenuOption,
            idOptionType: product.idOptionType,
            selected: false,
          };
        }
      );

      setAvailableProducts(products);
    }
  };

  /**
   *
   * Función para obtener el listado de vendedores y colocarlo en el state de sellerList
   *
   * @param {Object} object
   */
  const getSellerList = async (object) => {
    const { country, eanCompany } = object;

    const obj = {
      eanProvider: eanCompany,
      country,
    };

    const sellerListService = await GetSalesMan(obj);

    const sellersList = sellerListService.data.responseMessage.map(
      (commerce) => {
        return {
          label: commerce.salesMan,
          value: commerce.salesManCode,
        };
      }
    );

    const completeList =
      sellersList.length > 0
        ? [{ label: t("App.downloadSalesForce.selectAll") }, ...sellersList]
        : [];

    setSellerList(completeList);
  };

  /**
   *
   * Función para colocar las opciones de valores del autocomplete de comercioOVendedor según el idOptionType
   *
   * @returns el listado de comercio o vendedores para la opciones del autocomplete de comercioOVendedor
   */
  const handleChangeSellerAndCommerce = () => {
    if (idOptionType === 1) {
      return commerceList;
    } else if (idOptionType === 4) {
      return sellerList;
    } else {
      return [];
    }
  };

  /**
   * t para la traducción de los textos
   */
  const { t } = useTranslation();

  /**
   * Schema para el formulario de crear producto
   */
  const schema = yup.object().shape({
    // product: yup.string().required("*Este campo es requerido"),
    contractedUsers: yup
      .string()
      .required(t("App.validationMessages.required")),
    // status: yup.string().required("*Este campo es requerido"),
  });

  /**
   * useForm para resolver el schema de validación
   */
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  /**
   *
   * Función para colocar en el campo el valor(es) correspondiente(s) según lo que se seleccione en el autocomplete
   *
   * @param {Array} list
   * @param {String} reason
   * @param {Object} detail
   */
  const onCommerceChange = (list, reason, detail) => {
    const obj = {
      list,
      reason,
      detail,
      type: "commerceOrSeller",
      sellerOrCommerceList: commerceList,
    };

    handleSelectAllSelection(obj);
  };

  /**
   *
   * Función para colocar en el campo el valor(es) correspondiente(s) según lo que se seleccione en el autocomplete
   *
   * @param {Array} list
   * @param {String} reason
   * @param {Object} detail
   */
  const onSellerChange = (list, reason, detail) => {
    const obj = {
      list,
      reason,
      detail,
      type: "seller",
      sellerOrCommerceList: sellerList,
    };

    handleSelectAllSelection(obj);
  };

  /**
   *
   * Función para manejar los comportamientos relacionados con la opción 'Seleccionar todo'
   *
   * @param {Object} object
   */
  const handleSelectAllSelection = (object) => {
    let includeSelectAll = false;

    const { list, reason, detail, type, sellerOrCommerceList } = object;

    for (const commerceOrSeller of list) {
      if (commerceOrSeller.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    // Si se escoge 'Seleccionar todo' se colocan todos los valores en el autocomplete
    // de lo contrario se colocan los valores seleccionados
    if (includeSelectAll && reason === "selectOption") {
      setValue(
        "commerceOrSeller",
        type === "seller" ? sellerList : commerceList
      );
    } else {
      setValue("commerceOrSeller", list);
    }

    // Si se seleccionan todas las opciones disponibles, se selecciona automáticamente 'Seleccionar todo'
    if (!includeSelectAll && list.length === sellerOrCommerceList.length - 1) {
      setValue(
        "commerceOrSeller",
        type === "seller" ? sellerList : commerceList
      );
    }

    handleDeleteSelectAll(list, reason, includeSelectAll);

    if (detail) {
      const option = detail.option;

      // Si se deselecciona 'Seleccionar todo' se reinicia el valor del autocomplete
      if (
        reason === "removeOption" &&
        option.label === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("commerceOrSeller", []);
      }
    }
  };

  /**
   *
   * Función para eliminar la opción 'Seleccionar todos' (comercio o vendedor) cuando alguna opción es removida
   *
   * @param {Array} list
   * @param {String} reason
   * @param {boolean} includeSelectAll
   */
  const handleDeleteSelectAll = (list, reason, includeSelectAll) => {
    if (reason === "removeOption" && includeSelectAll) {
      const optionsWithoutSelectAll = list.filter(
        (option) => option.label !== t("App.downloadSalesForce.selectAll")
      );

      setValue("commerceOrSeller", optionsWithoutSelectAll);
    }
  };

  /**
   * useEffect para colocar los valores de los comercios en el autocomplete de comercioOVendedor
   * cuando el idOptionType del producto seleccionado es igual a 2 o 3
   */
  useEffect(() => {
    const result = getCommerces(commerceList, eanTraders);
    setValue("commerceOrSeller", result || "");
  }, [idOptionType]);

  /**
   *
   * Función para obtener el idOptionType del producto seleccionado
   *
   * @param {HTMLElement} element
   * @returns idOptionType del producto
   */
  const getIdOptionType = (element) => {
    if (element !== undefined) {
      const idOptionType = Number(element.getAttribute("id"));

      if (idOptionType === 2 || idOptionType === 3) {
        setEanTraders(getCommerce(props.data).eanTraders);
        setTradersListAux(getCommerce(props.data).eanTraders);
      }

      return idOptionType || null;
    }
  };

  /**
   *
   * Funcion para crear un producto
   *
   * @param {Object} data
   */
  const handleCreateProduct = async (data) => {
    try {
      const { eanTraders, sellers } = props.getSellersOrEanCommerces(
        data.commerceOrSeller,
        idOptionType
      );

      const obj = {
        contractedUsers: Number(data.contractedUsers),
        country: props.filters.country,
        eanCompany: props.filters.eanCompany,
        idMenuOption: data.product,
        idOptionType,
        eanTraders: eanTraders.length > 0 ? eanTraders : tradersListAux,
        lstSalesMan: sellers,
        menuOptionName: productName,
        nameCompany: props.filters.nameCompany.companyName,
        state: data.status,
        companyType: "F",
      };
      setIsLoadingData(true);

      const createProductService = await CreateContractedProduct(obj);

      setCreateProduct(createProductService);

      setIsLoadingData(false);

      setIdOptionType(0);

      if (
        createProductService.status === CODES.COD_RESPONSE_HTTP_OK &&
        createProductService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        setSuccess(true);

        const obj2 = {
          country: props.filters.country,
          nameCompany: props.filters.nameCompany.companyName,
          eanCompany: props.filters.eanCompany,
          idMenuOption: props.filters.idMenuOption,
        };

        const filterService = await ApplyFiltersProduct(obj2);

        if (
          filterService.status === CODES.COD_RESPONSE_HTTP_OK &&
          filterService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage = filterService.data.responseMessage;

          props.setDataStructure(responseMessage);

          dispatch(resetProductsAndRolesStatus());
        }
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(
        "============== Error modalCreateProduct.jsx function handleCreateProduct ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para colocar los valores correspondientes según el idOptionType
   * 1, 2 o 3 -> Comercios (se pueden seleccionar solo con la opción 1)
   * 4 -> Vendedores
   *
   * @param {Array} value
   * @returns el valor seleccionado en el autocomplete, pueden ser comercios o vendedores
   */
  const setValueCorrespondent = (value) => {
    if (
      idOptionType === 1 ||
      idOptionType === 2 ||
      idOptionType === 3 ||
      idOptionType === 4
    ) {
      return value;
    } else {
      return [];
    }
  };

  /**
   *
   * Función para devolver true o false de acuerdo a si los campos del formulario estén llenos
   *
   * @returns true o false
   */
  const handleDisabledOfButtonCreate = () => {
    if (
      watch("product") &&
      watch("contractedUsers") &&
      (watch("status") || watch("status") === 0)
    ) {
      if (idOptionType === 1 || idOptionType === 4) {
        return watch("commerceOrSeller").length > 0 ? true : false;
      }

      return true;
    } else {
      return false;
    }
  };

  /**
   *
   * Función para devolver el texto a mostrar en el tooltip de crear producto
   *
   * @returns el código HTML con la información a mostrar
   */
  const handleTooltipInfo = () => (
    <Grid container direction="column">
      <Grid item>
        <h4 className="text-left">{t("App.listUsers.tooltipCreate.text1")}</h4>
      </Grid>

      <Grid item>
        <p className="text-left">{t("App.listUsers.tooltipCreate.text2")}</p>
      </Grid>

      <Grid item>
        <p className="text-left">{t("App.listUsers.tooltipCreate.text3")}</p>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Button
        variant="contained btn__users"
        className="full-width"
        startIcon={<AddIcon />}
        onClick={handleOpen}
        sx={{marginTop:"10px !important",
             maxWidth:{ xs:"100% !important"}}}
      >
        {t("App.listUsers.addProduct")}
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box className="modal__responsive modal__box">
          <form onSubmit={handleSubmit(handleCreateProduct)}>
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item container justifyContent="flex-end">
                <IconButton onClick={handleClose} component="span">
                  <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
                </IconButton>
              </Grid>

              <Grid item className="modal__title">
                <p className="heading-secondary__md">
                  {t("App.listUsers.addProduct")}{" "}
                  {props.filters && props.filters.nameCompany.companyName}
                </p>
              </Grid>

              <Grid
                item
                container
                spacing={4}
                sx={{ alignItems: "center", paddingTop: "0px !important" }}
              >
                <Grid item xs={12} lg={6} className="full-width">
                  <FormControl className="full-width">
                    <InputLabel className="modalCreateOptions__form__inputLabel ">
                      {t("App.createUser.product")}
                    </InputLabel>

                    <Controller
                      control={control}
                      defaultValue=""
                      name="product"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          className={
                            errors.product
                              ? "modal__form-input--error"
                              : "modal__form-input"
                          }
                          onChange={onChange}
                          onClick={(e) => {
                            setProductName(getElementName(e.target));
                            setIdOptionType(getIdOptionType(e.target));
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>{t("App.listUsers.product")}</em>
                          </MenuItem>

                          {availableProducts.map((product) => (
                            <MenuItem
                              sx={{
                                display: product.selected ? "none" : "",
                              }}
                              key={product.value}
                              id={product.idOptionType}
                              value={product.value}
                              name={product.label}
                            >
                              {product.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />

                    <FormHelperText
                      sx={{
                        margin: "-10px 0 0 0",
                        color: "#e52900",
                      }}
                    >
                      {errors.product ? errors.product.message : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  lg={6}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={11}>
                    <FormControl className="full-width">
                      <Controller
                        control={control}
                        defaultValue=""
                        name="commerceOrSeller"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.commerceOrSeller
                                ? "modal__form-input--error"
                                : "modal__form-input"
                            }
                            disableCloseOnSelect
                            disabled={
                              idOptionType === 1 || idOptionType === 4
                                ? false
                                : true
                            }
                            id="checkboxes-tags-commerce"
                            options={handleChangeSellerAndCommerce()}
                            filterOptions={filterOptions}
                            multiple
                            limitTags={2}
                            isOptionEqualToValue={(option, value) =>
                              option.label === value.label
                            }
                            onChange={(e, values, reason, detail) => {
                              if (idOptionType === 1) {
                                onCommerceChange(values, reason, detail);
                              } else if (idOptionType === 4) {
                                onSellerChange(values, reason, detail);
                              }
                            }}
                            getOptionLabel={(option) => option.label}
                            value={setValueCorrespondent(value)}
                            renderOption={(props, option, { selected }) => (
                              <li
                                {...props}
                                key={
                                  idOptionType === 1
                                    ? option.value
                                    : option.label
                                }
                              >
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.label}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                label={
                                  idOptionType === 4
                                    ? t("App.listUsers.seller")
                                    : t("App.deleteAndReload.filters.commerce")
                                }
                                sx={{
                                  label: {
                                    fontFamily: "Roboto !important",
                                    fontSize: "13px !important",
                                    translate: "0px -5px",
                                    transformOrigin: "-45px -10px",
                                    "&.Mui-focused": {
                                      color: "#8C8D9D",
                                    },
                                  },
                                }}
                                {...params}
                              />
                            )}
                            renderTags={(value) => {
                              const numTags = value.length;
                              const limitTags = 1;

                              return (
                                <>
                                  {value
                                    .slice(0, limitTags)
                                    .map((option, index) => (
                                      <Chip
                                        className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                        sx={{
                                          width: "50%",
                                        }}
                                        key={index}
                                        size="small"
                                        label={option.label}
                                      />
                                    ))}

                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                          />
                        )}
                      />

                      <FormHelperText
                        sx={{
                          margin: "-10px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {errors.commerceOrSeller
                          ? errors.commerceOrSeller.message
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={handleTooltipInfo()}>
                      <InfoIcon sx={{ fontSize: "22px", color: "#543AB4" }} />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container spacing={4}>
                <Grid item xs={11.8} lg={6}>
                  <Controller
                    defaultValue=""
                    control={control}
                    name="contractedUsers"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        className={
                          errors.contractedUsers
                            ? "modal__form-input--error"
                            : "modal__form-input"
                        }
                        helperText={
                          errors.contractedUsers
                            ? errors.contractedUsers.message
                            : ""
                        }
                        InputLabelProps={{
                          style: {
                            margin: "-5px 0 0 -10px",
                            padding: "0 0 0 5px",
                            color: "#8c8d9d",
                            fontSize: 13,
                          },
                        }}
                        FormHelperTextProps={{
                          style: {
                            margin: "-5px 0 0 0px",
                            color: "#e52900",
                          },
                        }}
                        label={t("App.listUsers.contractedUsers")}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={11.8} lg={5.6} >
                  <FormControl className="full-width">
                    <InputLabel className="modalCreateOptions__form__inputLabel ">
                      {t("App.listUsers.state")}
                    </InputLabel>

                    <Controller
                      control={control}
                      defaultValue=""
                      name="status"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          labelId="labelStatus"
                          label={t("App.listUsers.state")}
                          className={
                            errors.status
                              ? "modal__form-input--error"
                              : "modal__form-input"
                          }
                          onChange={onChange}
                        >
                          <MenuItem disabled>
                            <em>{t("App.listUsers.state")}</em>
                          </MenuItem>
                          <MenuItem value={1}>
                            {t("App.createRole.active")}
                          </MenuItem>
                          <MenuItem value={0}>
                            {t("App.createRole.inactive")}
                          </MenuItem>
                        </Select>
                      )}
                    />

                    <FormHelperText
                      sx={{
                        margin: "-10px 0 0 0",
                        color: "#e52900",
                      }}
                    >
                      {errors.status ? errors.status.message : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Button
                  sx={{ padding:{md:"12px 24px 12px 24px !important"},
                  width:"104px" }}
                    type="button"
                    disableRipple
                    className="btn__filled__gradient"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {t("App.buttonLabels.cancel")}
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                  sx={{ padding:{md:"14px 24px 14px 24px !important"},
                  width:"99px" }}
                    disableRipple
                    disabled={!handleDisabledOfButtonCreate()}
                    className={`${
                      handleDisabledOfButtonCreate()
                        ? "btn__applyFilter"
                        : "btn__applyFilter-disabled"
                    } dimentions-btn__applyFilter modal__btnSave`}
                    type="submit"
                  >
                    {!isLoadingData ? (
                      t("App.listUsers.saveProduct")
                    ) : (
                      <CircularProgress size={21} color="inherit" />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      {error && (
        <>
          <ModalInfo
            responseData={createProduct}
            open={open}
            onClose={() => {
              setError(false);
              setSuccess(false);
              setOpen(false);
              reset();
            }}
          />
        </>
      )}

      {success && (
        <>
          <ModalInfo
            title={t("App.listUsers.successTitle")}
            responseData={createProduct}
            open={open}
            onClose={() => {
              setError(false);
              setSuccess(false);
              setOpen(false);
              reset();
            }}
          />
        </>
      )}
    </>
  );
};

export default ModalCreateProduct;
