import {
  Autocomplete,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ModalChangeMaster } from "../../../components/common/Modals/modalChangeMaster";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import {
  GetListReceivers,
  GetListTrader,
} from "../../../services/user/externalFiles/externalFilesService";
import { CODES } from "../../../consts/codes";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs"; 
import {  Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const SelectMaster = ({
  currentType,
  setCurrentType,
  setType,
  type,
  setSender,
  sender,
  receiver,
  setReceiver,
  endDate,
  setEndDate,
}) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use State
   */
  const [openModalChangeType, setOpenModalChangeType] = useState(null);
  const [desiredType, setDesiredType] = useState(null);
  const [receivers, setReceivers] = useState([]);
  const [senders, setSenders] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1000);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 400);
  const isScreenLarge = useState("(min-width: 1025px)");

  /**-
   * yup
   */
  const schema = yup.object().shape({});
  /**
   *
   */
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);
  const isDateFieldEnabled = useMemo(() => {
    return (
      desiredType &&
      ((userInfo?.company?.typeCompany === "C" && receiver?.companyName) ||
        (userInfo?.company?.typeCompany !== "C" && sender?.companyName))
    );
  }, [desiredType, receiver, sender, userInfo]);
  
  /**
   * Use Effect
   */

  /**
   * Obtener la lista de receptores y emisores al cargar el componente
   */
  useEffect(() => {
    if (userInfo) {
      if (userInfo.company.typeCompany === "C") {
        getReceivers();

        setSender(
          {
            companyName: userInfo.company.companyName,
            eanCompany: userInfo.company.eanCompany,
          }
          //userInfo.company.eanCompany
        );
      } else {
        getSenders();
        setReceiver(
          {
            companyName: userInfo.company.companyName,
            eanCompany: userInfo.company.eanCompany,
          }
          //userInfo.company.eanCompany
        );
      }
      // userInfo.company.typeCompany === "C"
      //   ? setSender(userInfo.company.eanCompany)
      //   : setReceiver(userInfo.company.eanCompany);
    }
  }, [userInfo]);

  /**
   * Handles
   */
  /**
   * Cambia el tipo de documento seleccionado
   * @param {*} event
   */
  const handleTypeChange = (event) => {
    const id = event.target.value;
    setDesiredType(id);
    setCurrentType(id);
    setType(id === "1" ? "sales" : id === "2" ? "inventory" : "sales_stock"
    );
  };
  /**
   * Cambia el emisor seleccionado
   * @param {*} event
   */
  const handleSenderChange = (event) => {
    const id = event.target.value;
    setSender(id);
  };

  /**
   * Cambia la fecha a tipo string
   * @param {*} date 
   */
  const formatDateToString = (date) => {
    if (!date) return ""; // Maneja si la fecha es nula
    return dayjs(date).format("YYYY-MM-DD"); // Formato deseado (puedes cambiarlo si necesitas)
  };
  /**
   * Cambia el receptor seleccionado
   * @param {*} event
   */
  const handleReceiverChange = (event) => {
    const id = event.target.value;
    setReceiver(id);
  };

  /**
   * Obtiene la lista de receptores
   */
  const getReceivers = async () => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetListReceivers();
      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        let listCompanies = [];
        responseMessage
          .map((item) => item.companies)
          .map((item) => item.map((item) => listCompanies.push(item)));

        listCompanies = listCompanies.map((item) => {
          return {
            companyName: item.companyName,
            eanCompany: item.eanCompany,
          };
        });
        setReceivers(listCompanies);
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("-------------");
      console.log(error);
      console.log("-------------");
    }
  };

  /**
   * Obtiene la lista de emisores
   */
  const getSenders = async () => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetListTrader();
      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        let sendersAux = responseMessage.map((receiver) => {
          return {
            eanCompany: receiver.eanCompany,
            companyName: receiver.companyName,
          };
        });

        setSenders(sendersAux);
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("-------------");
      console.log(error);
      console.log("-------------");
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="css-wh8s2b-MuiGrid-root grid__container__filled__rounded"
      spacing={1.5}
      xs={12}
    >
      <Grid item xs={12} sm={12} md={3} lg={2.6} xl={2.5}>
        <p className="subheadingOne__subheading-one">
          {t("App.externalFiles.infoSelect")}
        </p>
      </Grid>
      <Grid item xs={11.2} sm={12} md={3.3} lg={3} xl={2.4} width={"100%"}>
         <label className="labelMaster__text-grey">
         {t("App.externalFiles.select.DocumentType")}
        </label>
        <Select
          className="select__border"
          onChange={handleTypeChange}
          value={currentType}
          id="type"
          name="type"
          labelId="type"
          width="100%"
          minWidth="100%"
          sx={{ width: "100%", minWidth: "100%" }}
        >
          <MenuItem disabled value="0">
            <em> {t("App.externalFiles.select.placeholder")}</em>
          </MenuItem>

          <MenuItem value="1">
            <em> {t("App.externalFiles.select.sales")}</em>
          </MenuItem>
          <MenuItem value="2">
            <em> {t("App.externalFiles.select.inventory")}</em>
          </MenuItem>
          <MenuItem value="3">
            <em> {t("App.externalFiles.select.salesStock")}</em>
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={11.2} sm={12} md={2.15} lg={3} xl={2.2} width={"100%"}>
      <label className="labelMaster__text-grey">
         {t("App.externalFiles.select.issuer")}
        </label>
        <Controller
          control={control}
          name="sender"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disableClearable
              disabled={
                currentType === "0" ||
                (userInfo && userInfo.company.typeCompany === "C")
              }
              options={senders}
              getOptionLabel={(option) => option.companyName}
              noOptionsText={t("App.listUsers.noOptions")}
              isOptionEqualToValue={(option, value) =>
                option.eanCompany === value.eanCompany
              }
              className={"select-contactOne__filters"}
               renderOption={(props, option, { selected }) => (
                <li {...props} key={option.eanCompany}>
                  {option.companyName}
                </li>
              )}
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  inputProps={{
                    ...inputProps,
                    readOnly: false,
                    endAdornment: "",
                  }}
                />
              )}
              onChange={(event, values, reason) => {
                onChange(values);
                setSender(
                  {
                    //companyType: values.company.typeCompany,
                    companyName: values.companyName,
                    eanCompany: values.eanCompany,
                  }
                  //values.eanCompany
                );
              }}
              value={
                userInfo && userInfo.company.typeCompany === "C"
                  ? {
                      companyName: userInfo.company.companyName,
                      eanCompany: userInfo.company.eanCompany,
                    }
                  : value || {
                      companyName: "",
                      eanCompany: "",
                    }
              }
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={11.2}
        sm={12}
        md={2.15}
        lg={3}
        xl={2.4}
        width={"100%"}
      >
        <label className="labelMaster__text-grey">
         {t("App.externalFiles.select.receiver")}
        </label>
        <Controller
          control={control}
          name="receiver"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disableClearable
              disabled={
                currentType === "0" ||
                (userInfo && userInfo.company.typeCompany === "F")
              }
              // Ordena alfabéticamente las opciones por companyName
              options={receivers.sort((a, b) =>
                a.companyName.localeCompare(b.companyName)
              )}
              getOptionLabel={(option) => option.companyName}
              noOptionsText={t("App.listUsers.noOptions")}
              isOptionEqualToValue={(option, value) =>
                option.eanCompany === value.eanCompany
              }
              className={"select-contactOne__filters"}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.eanCompany}>
                  {option.companyName}
                </li>
              )}
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  inputProps={{
                    ...inputProps,
                    readOnly: false,
                    endAdornment: "",
                  }}
                />
              )}
              onChange={(event, values, reason) => {
                onChange(values);
                setReceiver({
                  companyName: values.companyName,
                  eanCompany: values.eanCompany,
                });
              }}
              value={
                userInfo && userInfo.company.typeCompany === "F"
                  ? {
                      companyName: userInfo.company.companyName,
                      eanCompany: userInfo.company.eanCompany,
                    }
                  : value || {
                      companyName: "",
                      eanCompany: "",
                    }
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={10.5} sm={11.3} md={2.15} lg={3} xl={2.2} width={"150%"} className="labelMaster__text-grey-one">
        <Controller
          defaultValue=""
          control={control}
          name="endDate"
          render={({ field: { onChange, value } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={!isDateFieldEnabled}
                className={
                  errors.requestDate
                    ? "select-contact__filters__error"
                    : "select-contact__filters"
                }
                inputFormat="YYYY-MM-DD"
                label={t("App.traceabilityExternal.informationDate")}
                sx={{
                  translate: "0px -5px",
                }}
                value={value || null}
                onChange={(newDate) => {
                  const formattedDate = formatDateToString(newDate); // Convierte la fecha a string
                  onChange(formattedDate); // Actualiza el estado del formulario con la fecha formateada
                  setEndDate(formattedDate); // Si necesitas actualizar el estado local
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      label: {
                        fontFamily: "Roboto !important",
                        fontSize: "14px !important",
                        translate: "0px -4px",
                        transformOrigin: "-45px -10px",
                        color: "#8C8D9D !important",
                        "&.Mui-focused": {
                          color: "#8C8D9D",
                        },
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
            <Tooltip
            title={
              <span
                style={{
                  textAlign: "left",
                  fontSize: isMobile
                    ? "2px" // Font size para móviles
                    : isScreenSmall
                    ? "16px" // Font size para tablets
                    : "17px", // Font size para pantallas grandes
                }}
              >
                {isMobile ? (
                  <span>
                    <p>{t("App.traceabilityExternal.tooltipTwo")}</p>
                    <p>{t("App.traceabilityExternal.tooltipThree")}</p>
                    <p>{t("App.traceabilityExternal.tooltipFour")}</p>
                  </span>
                ) : isScreenSmall ? (
                  <span>
                    <p>{t("App.traceabilityExternal.tooltipFive")}</p>
                    <p>{t("App.traceabilityExternal.tooltipSix")}</p>
                  </span>
                ) : (
                  <span>
                    <p>{t("App.traceabilityExternal.tooltip")}</p>
                    <p>{t("App.traceabilityExternal.tooltipOne")}</p>
                  </span>
                )}
              </span>
            }
            placement={isMobile ? "bottom" : "bottom-start"}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [
                      isMobile ? 0 : isScreenSmall ? 0 : 0, // Ajuste horizontal
                      isMobile ? 200 : isScreenSmall ? 0 : 0, // Ajuste vertical
                    ],
                  },
                },
            {
              name: "preventOverflow",
              options: {
                padding: isMobile ? 4 : 8, // Asegura que no se salga de la pantalla
              },
            },
          ],
        }}
      >
        <InfoOutlinedIcon
          sx={{
            fontSize: "25px",
            color: "#543AB4",
            cursor: "pointer",
            position: "relative",
            top: isMobile ? "3px" : "10px", // Resta 3px en móviles
            left: isMobile ? "-6px" : "0px", // Resta 3px en móviles
          }}
        />
      </Tooltip>
      {/* Modal para confirmar el cambio de tipo de documento */}
      <ModalChangeMaster
        open={openModalChangeType || false}
        setOpen={setOpenModalChangeType}
        desiredType={desiredType}
        setCurrentMaster={setCurrentType}
        titleModal={t("App.externalFiles.modal.changeTitle")}
        textModal={t("App.externalFiles.modal.changeText")}
      />
      {/* Modal para fallo de servicios */}
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: t("App.validationMessages.systemError2"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
    </Grid>
  );
};

export default SelectMaster;
