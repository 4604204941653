import { React, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useMediaQuery } from "@mui/material";
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import * as yup from "yup";
import {
  ApplyFiltersProduct,
  UpdateCompany,
} from "../../services/admin/adminServices";
import { CODES } from "../../consts/codes";
import { useNavigate } from "react-router";
import ModalInfo from "./Modals/modalInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsAndRoles,
  resetProductsAndRolesStatus,
} from "../../parts/listUsers/listUsersSlice";
import { GetTradersService } from "../../services/user/deleteAndReload/deleteAndReload";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { getCommerces } from "../../helpers/helpers";
import { GetSalesMan } from "../../services/user/dynamicReport/dynamicReportServices";

export const ModalEditProduct = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [editProduct, setEditProduct] = useState({});
  const [commerceList, setCommerceList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [selectedCommerceList, setSelectedCommerceList] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const matches = useMediaQuery("(max-width:770px)");

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Opciones para la configuración del listado del autocomplete de comercio
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * Method to update the state of isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  /**
   * useEffect to update the state of isDesktop based on the size of the browser window
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Función para abrir el modal y traer los listados de comercios y vendedores
   */
  const handleOpen = () => {
    /**
     * Función para traer la data necesaria para el autocomplete de comercios
     */
    async function fetchData() {
      try {
        if (Object.keys(props.company).length > 0) {
          const obj = props.company;

          getCommerceList(obj);

          getSellerList(obj);
        }
      } catch (error) {
        console.log(
          "============== Error modalEditProduct.jsx function fetchData ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();
    setOpen(true);
  };

  /**
   *
   * Función para obtener el listado de comercioss y colocarlo en el state de commerceList
   *
   * @param {Object} object
   */
  const getCommerceList = async (object) => {
    const commercePerCountry = await GetTradersService(object);

    if (
      commercePerCountry.status === CODES.COD_RESPONSE_HTTP_OK &&
      commercePerCountry.data.responseCode ===
        CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      const commercesList = commercePerCountry.data.responseMessage.map(
        (commerce) => {
          return {
            label: commerce.companyName,
            value: commerce.eanCompany,
          };
        }
      );

      const completeList =
        commercesList.length > 0
          ? [{ label: t("App.downloadSalesForce.selectAll") }, ...commercesList]
          : [];

      setCommerceList(completeList);
    }
  };

  /**
   *
   * Función para obtener el listado de vendedores y colocarlo en el state de sellerList
   *
   * @param {Object} object
   */
  const getSellerList = async (object) => {
    const { country, eanCompany } = object;

    const obj = {
      eanProvider: eanCompany,
      country,
    };

    const sellerListService = await GetSalesMan(obj);

    const sellersList = sellerListService.data.responseMessage.map(
      (commerce) => {
        return {
          label: commerce.salesMan,
          value: commerce.salesManCode,
        };
      }
    );

    const completeList =
      sellersList.length > 0
        ? [{ label: t("App.downloadSalesForce.selectAll") }, ...sellersList]
        : [];

    setSellerList(completeList);
  };

  /**
   * Función para crear un array con los comercios del eanTraders del producto seleccionado
   */
  const getProductCommerces = (commerceList, eanTraders) => {
    //Método para hallar los comercios que coinciden con los eans en eanTraders
    const productSelectedCommerces = commerceList.filter((commerce) => {
      return eanTraders.includes(commerce.value);
    });
    setSelectedCommerceList(productSelectedCommerces);
    return productSelectedCommerces;
  };

  /**
   *
   * Función para colocar las opciones de valores del autocomplete de comercioOVendedor según el idOptionType
   *
   * @returns el listado de comercio o vendedores para la opciones del autocomplete de comercioOVendedor
   */
  const handleChangeSellerAndCommerce = () => {
    if (props.company.idOptionType === 1) {
      return commerceList;
    } else if (
      props.company.idOptionType === 2 ||
      props.company.idOptionType === 3
    ) {
      return selectedCommerceList;
    } else if (props.company.idOptionType === 4) {
      return sellerList;
    } else {
      return [];
    }
  };

  /**
   * Función para cerrar y reiniciar los valores de los state de isOpenModalInfo y error
   */
  const handleClose = () => {
    setIsOpenModalInfo(false);
    setOpen(false);
  };

  /**
   * t para la traducción de los textos
   */
  const { t } = useTranslation();

  /**
   * Schema para el formulario de editar producto
   */
  const schema = yup.object().shape({
    contractedUsers: yup
      .string()
      .required(t("App.validationMessages.required")),
  });

  /**
   * useForm para resolver el schema de validación
   */
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  /**
   *
   * Función para colocar en el campo el valor(es) correspondiente(s) según lo que se seleccione en el autocomplete
   *
   * @param {Array} list
   * @param {String} reason
   * @param {Object} detail
   */
  const onCommerceChange = (list, reason, detail) => {
    const obj = {
      list,
      reason,
      detail,
      type: "commerceOrSeller",
      sellerOrCommerceList: commerceList,
    };

    handleSelectAllSelection(obj);
  };

  /**
   *
   * Función para colocar en el campo el valor(es) correspondiente(s) según lo que se seleccione en el autocomplete
   *
   * @param {Array} list
   * @param {String} reason
   * @param {Object} detail
   */
  const onSellerChange = (list, reason, detail) => {
    const obj = {
      list,
      reason,
      detail,
      type: "seller",
      sellerOrCommerceList: sellerList,
    };

    handleSelectAllSelection(obj);
  };

  /**
   *
   * Función para manejar los comportamientos relacionados con la opción 'Seleccionar todo'
   *
   * @param {Object} object
   */
  const handleSelectAllSelection = (object) => {
    let includeSelectAll = false;

    const { list, reason, detail, type, sellerOrCommerceList } = object;

    for (const commerceOrSeller of list) {
      if (commerceOrSeller.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    // Si se escoge 'Seleccionar todo' se colocan todos los valores en el autocomplete
    // de lo contrario se colocan los valores seleccionados
    if (includeSelectAll && reason === "selectOption") {
      setValue(
        "commerceOrSeller",
        type === "seller" ? sellerList : commerceList
      );
    } else {
      setValue("commerceOrSeller", list);
    }

    // Si se seleccionan todas las opciones disponibles, se selecciona automáticamente 'Seleccionar todo'
    if (!includeSelectAll && list.length === sellerOrCommerceList.length - 1) {
      setValue(
        "commerceOrSeller",
        type === "seller" ? sellerList : commerceList
      );
    }

    handleDeleteSelectAll(list, reason, includeSelectAll);

    if (detail) {
      const option = detail.option;

      // Si se deselecciona 'Seleccionar todo' se reinicia el valor del autocomplete
      if (
        reason === "removeOption" &&
        option.label === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("commerceOrSeller", []);
      }
    }
  };

  /**
   *
   * Función para eliminar la opción 'Seleccionar todos' (comercio o vendedor) cuando alguna opción es removida
   *
   * @param {Array} list
   * @param {String} reason
   * @param {boolean} includeSelectAll
   */
  const handleDeleteSelectAll = (list, reason, includeSelectAll) => {
    if (reason === "removeOption" && includeSelectAll) {
      const optionsWithoutSelectAll = list.filter(
        (option) => option.label !== t("App.downloadSalesForce.selectAll")
      );

      setValue("commerceOrSeller", optionsWithoutSelectAll);
    }
  };

  /**
   * useEffect para colocar los valores correspondientes en los campos del formulario
   */
  useEffect(() => {
    const productInfo = props.company;
    const eanTraders = productInfo.eanTraders;

    const result = getCommerces(commerceList, eanTraders);
    getProductCommerces(commerceList, eanTraders);
    const sellers = productInfo?.lstSalesMan?.map((seller) => {
      return { label: seller.salesMan, value: seller.salesManCode };
    });

    const isArrayEanTraders =
      Array.isArray(eanTraders) && eanTraders.length > 0;

    setValue(
      "commerceOrSeller",
      isArrayEanTraders ? result || [] : sellers || []
    );
    setValue(
      "contractedUsers",
      productInfo.contractedUsers ? productInfo.contractedUsers : ""
    );
    setValue("status", productInfo.state !== "" ? productInfo.state : "");
  }, [props.company, commerceList, sellerList]);

  /**
   *
   *  Función para editar un producto
   *
   * @param {Object} data
   */
  const handleEditProduct = async (data) => {
    try {
      const { eanTraders, sellers } = props.getSellersOrEanCommerces(
        data.commerceOrSeller,
        props.company.idOptionType
      );

      const obj = {
        contractedUsers: Number(data.contractedUsers),
        country: props.company.country,
        eanCompany: props.company.eanCompany,
        eanTraders,
        lstSalesMan: sellers,
        idMenuOption: props.company.idMenuOption,
        idOptionType: props.company.idOptionType,
        menuOptionName: props.company.menuOptionName,
        nameCompany: props.company.nameCompany,
        state: data.status,
        companyType: "F",
      };

      if (obj.lstSalesMan.length === 0 && props.company.idOptionType === 4) {
        const objReject = {
          data: {
            responseMessage: t("App.listUsers.errorEditProductSellersEmpty"),
          },
          status: 500,
        };

        setIsOpenModalInfo(true);

        setEditProduct(objReject);

        return;
      }

      setIsLoadingData(true);

      const updateProductService = await UpdateCompany(obj);

      setIsLoadingData(false);

      dispatch(resetProductsAndRolesStatus());

      setEditProduct(updateProductService);

      //Actualizo la lista
      if (
        updateProductService.status === CODES.COD_RESPONSE_HTTP_OK &&
        updateProductService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const filterList = {
          country: props.company.country,
          eanCompany: props.company.eanCompany,
          nameCompany: props.company.nameCompany,
        };

        const filterService = await ApplyFiltersProduct(filterList);

        if (
          filterService.status === CODES.COD_RESPONSE_HTTP_OK &&
          filterService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage = filterService.data.responseMessage;

          props.setDataStructure(responseMessage);
        }
      }

      setIsOpenModalInfo(true);
    } catch (error) {
      console.log(
        "============== Error modalEditProduct.jsx function handleEditProduct ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para colocar los valores correspondientes según el idOptionType
   * 1, 2 o 3 -> Comercios (se pueden seleccionar solo con la opción 1)
   * 4 -> Vendedores
   *
   * @param {Array} value
   * @returns el valor seleccionado en el autocomplete, pueden ser comercios o vendedores
   */
  const setValueCorrespondent = (value) => {
    const idOptionType = props.company.idOptionType;

    if (
      idOptionType === 1 ||
      idOptionType === 2 ||
      idOptionType === 3 ||
      idOptionType === 4
    ) {
      return value;
    } else {
      return [];
    }
  };

  /**
   *
   * Función para devolver true o false de acuerdo a si los campos del formulario estén llenos
   *
   * @returns true o false
   */
  const handleDisabledOfButtonEdit = () => {
    if (
      watch("contractedUsers") &&
      (watch("status") || watch("status") === 0)
    ) {
      const idOptionType = props.company.idOptionType;

      if (idOptionType === 1) {
        return watch("commerceOrSeller").length > 0 ? true : false;
      }

      return true;
    } else {
      return false;
    }
  };

  /**
   * Función para mostrar el tooltip de acuerdo al idOptionType
   */

  const showTooltip = () => {
    const idOptionType = props.company.idOptionType;

    if (idOptionType >= 1 && idOptionType <= 4) {
      return true;
    } else {
      return false;
    }
  };

  /**
   *
   * Función para devolver el texto correspondiente para el tooltip de editar producto
   *
   * @returns código HTML con el respectivo texto según el idOptionType
   */
  const handleTooltipInfo = () => {
    const idOptionType = props.company.idOptionType;
    let infoText;

    switch (idOptionType) {
      case 1:
        return (
          <span>
            <p>{t("App.listUsers.tooltipEdit.text2One")}</p>
            <p>{t("App.listUsers.tooltipEdit.text2Two")}</p>
            <p>{t("App.listUsers.tooltipEdit.text2Three")}</p>
            <p>{t("App.listUsers.tooltipEdit.text2Four")}</p>
          </span>
        );

        break;
      case 2:
        return (
          <span>
            <p>{t("App.listUsers.tooltipEdit.text1One")}</p>
            <p>{t("App.listUsers.tooltipEdit.text1Two")}</p>
            <p>{t("App.listUsers.tooltipEdit.text1Three")}</p>
          </span>
        );

        break;
      case 3:
        return (
          <span>
            <p>{t("App.listUsers.tooltipEdit.text1One")}</p>
            <p>{t("App.listUsers.tooltipEdit.text1Two")}</p>
            <p>{t("App.listUsers.tooltipEdit.text1Three")}</p>
          </span>
        );

        break;
      case 4:
        return (
          <span>
            <p>{t("App.listUsers.tooltipEdit.text3One")}</p>
            <p>{t("App.listUsers.tooltipEdit.text3Two")}</p>
            <p>{t("App.listUsers.tooltipEdit.text3Three")}</p>
            <p>{t("App.listUsers.tooltipEdit.text3Four")}</p>
          </span>
        );

        break;
      default:
        return "";

        break;
    }
  };

  /**
   * Función para establecer donde aparecerá el tooltip
   *
   * @returns true si el tooltip aparece junto al campo de comercio o vendedor, false si aparecerá junto al nombre del producto a editar
   */

  const handleTooltipPosition = () => {
    const idOptionType = props.company.idOptionType;
    if (idOptionType === 4) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Función para habilitar el campo de comercio o vendedor según el idOptionType
   */

  const handleDisabledCommerce = () => {
    const idOptionType = props.company.idOptionType;

    if (idOptionType >= 1 && idOptionType <= 4) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <IconButton onClick={handleOpen} component="span">
        <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
      </IconButton>

      <Modal open={open} onClose={handleClose}>
        <Box className="modal__responsive modal__box" maxWidth={700}>
          <form onSubmit={handleSubmit(handleEditProduct)}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item container justifyContent="flex-end">
                <IconButton onClick={handleClose} component="span">
                  <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
                </IconButton>
              </Grid>

              <Grid item className="modal__title">
                <p className="heading-secondary__md">
                  {t("App.buttonLabels.edit")}{" "}
                  {props.company && props.company.nameCompany}
                </p>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item container alignItems="center" spacing={4}>
                    <Grid
                      item
                      container
                      xs={12}
                      lg={6}
                      alignItems="center"
                      spacing={4}
                    >
                      <Grid item>
                        <p className="label__text-default">
                          {t("App.listUsers.product")}:{" "}
                          <span className="state-colors__primary-color-one">
                            {props.company.menuOptionName}
                          </span>
                        </p>
                      </Grid>

                      {showTooltip() && !handleTooltipPosition() && (
                        <Grid item sx={{ paddingLeft: "1rem !important" }}>
                          <Tooltip
                            title={handleTooltipInfo()}
                            placement={matches ? "bottom" : "right"}
                          >
                            <InfoIcon
                              sx={{ fontSize: "22px", color: "#543AB4" }}
                            />
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                    {handleTooltipPosition() ? (
                      <Grid
                        item
                        container
                        xs={12}
                        lg={6}
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item xl={11} lg={11} md={11} sm={11} xs={props.company.idOptionType === 4 ? 11 : 12}>
                          <FormControl className="full-width">
                            <Controller
                              control={control}
                              name="commerceOrSeller"
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  noOptionsText={
                                    props.company.idOptionType === 4
                                      ? t("App.listUsers.sellersWithoutOptions")
                                      : t("App.listUsers.noOptions")
                                  }
                                  disableCloseOnSelect
                                  /* El listado de comercios de habilita para las opciones 1, 2, 3 y 4, pero para los casos 2 y 3
                                   * (Devoluciones y Archivos especiales) solo se pueden visualizar los comercios sin porder editarlos
                                   */
                                  disabled={handleDisabledCommerce()}
                                  options={handleChangeSellerAndCommerce()}
                                  filterOptions={filterOptions}
                                  multiple
                                  limitTags={2}
                                  className={
                                    errors.commerceOrSeller
                                      ? "modal__form-input--error"
                                      : "modal__form-input"
                                  }
                                  onChange={(e, values, reason, detail) => {
                                    if (props.company.idOptionType === 1) {
                                      onCommerceChange(values, reason, detail);
                                    } else if (
                                      props.company.idOptionType === 4
                                    ) {
                                      onSellerChange(values, reason, detail);
                                    }
                                  }}
                                  value={setValueCorrespondent(value)}
                                  isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                  }
                                  getOptionLabel={(option) => option.label}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li
                                      {...props}
                                      key={
                                        props.companyidOptionType === 1
                                          ? option.value
                                          : option.label
                                      }
                                    >
                                      <Checkbox
                                        icon={icon}
                                        className="autoComplete__checkbox"
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.label}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      label={
                                        props.company.idOptionType === 4
                                          ? t("App.listUsers.seller")
                                          : t("App.listUsers.trader")
                                      }
                                      sx={{
                                        label: {
                                          fontFamily: "Roboto !important",
                                          fontSize: "13px !important",
                                          translate: "0px -5px",
                                          transformOrigin: "-45px -10px",
                                          "&.Mui-focused": {
                                            color: "#8C8D9D",
                                          },
                                        },
                                      }}
                                      {...params}
                                    />
                                  )}
                                  renderTags={(value) => {
                                    const numTags = value.length;
                                    const limitTags = 2;

                                    return (
                                      <>
                                        {value
                                          .slice(0, limitTags)
                                          .map((option, index) => (
                                            <Chip
                                              className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                              sx={{
                                                width: "30%",
                                              }}
                                              key={index}
                                              size="small"
                                              label={option.label}
                                            />
                                          ))}

                                        {numTags > limitTags &&
                                          ` +${numTags - limitTags}`}
                                      </>
                                    );
                                  }}
                                />
                              )}
                            />

                            <FormHelperText
                              sx={{
                                margin: "-10px 0 0 0",
                                color: "#e52900",
                              }}
                            >
                              {errors.commerceOrSeller
                                ? errors.commerceOrSeller.message
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                          {showTooltip() && handleTooltipPosition() && (
                            <Grid item sx={{ paddingTop: "2rem !important" }}>
                              <Tooltip
                                title={handleTooltipInfo()}
                                placement={matches ? "bottom" : "right"}
                              >
                                <InfoIcon
                                  sx={{ fontSize: "22px", color: "#543AB4" }}
                                />
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        container
                        xs={12}
                        lg={6} 
                        sm={12}
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item width="100%">
                          <FormControl className="full-width">
                            <Controller
                              control={control}
                              name="commerceOrSeller"
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  noOptionsText={
                                    props.company.idOptionType === 4
                                      ? t("App.listUsers.sellersWithoutOptions")
                                      : t("App.listUsers.noOptions")
                                  }
                                  disableCloseOnSelect
                                  /* El listado de comercios de habilita para las opciones 1, 2, 3 y 4, pero para los casos 2 y 3
                                   * (Devoluciones y Archivos especiales) solo se pueden visualizar los comercios sin porder editarlos
                                   */
                                  disabled={handleDisabledCommerce()}
                                  options={handleChangeSellerAndCommerce()}
                                  filterOptions={filterOptions}
                                  multiple
                                  limitTags={2}
                                  className={
                                    errors.commerceOrSeller
                                      ? "modal__form-input--error"
                                      : "modal__form-input"
                                  }
                                  onChange={(e, values, reason, detail) => {
                                    if (props.company.idOptionType === 1) {
                                      onCommerceChange(values, reason, detail);
                                    } else if (
                                      props.company.idOptionType === 4
                                    ) {
                                      onSellerChange(values, reason, detail);
                                    }
                                  }}
                                  value={setValueCorrespondent(value)}
                                  isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                  }
                                  getOptionLabel={(option) => option.label}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li
                                      {...props}
                                      key={
                                        props.companyidOptionType === 1
                                          ? option.value
                                          : option.label
                                      }
                                    >
                                      <Checkbox
                                        icon={icon}
                                        className="autoComplete__checkbox"
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.label}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      label={
                                        props.company.idOptionType === 4
                                          ? t("App.listUsers.seller")
                                          : t("App.listUsers.trader")
                                      }
                                      sx={{
                                        label: {
                                          fontFamily: "Roboto !important",
                                          fontSize: "13px !important",
                                          translate: "0px -5px",
                                          transformOrigin: "-45px -10px",
                                          "&.Mui-focused": {
                                            color: "#8C8D9D",
                                          },
                                        },
                                      }}
                                      {...params}
                                    />
                                  )}
                                  renderTags={(value) => {
                                    const numTags = value.length;
                                    const limitTags = 2;

                                    return (
                                      <>
                                        {value
                                          .slice(0, limitTags)
                                          .map((option, index) => (
                                            <Chip
                                              className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                              sx={{
                                                width: "30%",
                                              }}
                                              key={index}
                                              size="small"
                                              label={option.label}
                                            />
                                          ))}

                                        {numTags > limitTags &&
                                          ` +${numTags - limitTags}`}
                                      </>
                                    );
                                  }}
                                />
                              )}
                            />

                            <FormHelperText
                              sx={{
                                margin: "-10px 0 0 0",
                                color: "#e52900",
                              }}
                            >
                              {errors.commerceOrSeller
                                ? errors.commerceOrSeller.message
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={4}
                    sx={{
                      marginTop: "0px",
                      paddingTop: "0px !important",
                    }}
                  >
                    <Grid item lg={6} sm={12} xs={12}>
                      <Controller
                        control={control}
                        name="contractedUsers"
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={
                              errors.contractedUsers
                                ? "modal__form-input--error"
                                : "modal__form-input"
                            }
                            helperText={
                              errors.contractedUsers
                                ? errors.contractedUsers.message
                                : ""
                            }
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 0 0px",
                                color: "#e52900",
                              },
                            }}
                            label={t("App.listUsers.contractedUsers")}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />

                      <Grid item>
                        {errors.contractedUsers && (
                          <p className="error-message__small">
                            {errors.contractedUsers.message}
                          </p>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item lg={6} sm={12} xs={12}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t("App.listUsers.state")}
                        </InputLabel>

                        <Controller
                          control={control}
                          name="status"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              value={value}
                              labelId="labelStatus"
                              label={t("App.listUsers.status")}
                              className={
                                errors.status
                                  ? "modal__form-input--error"
                                  : "modal__form-input"
                              }
                              onChange={onChange}
                            >
                              <MenuItem disabled>
                                <em>{t("App.listUsers.status")}</em>
                              </MenuItem>
                              <MenuItem value={1}>
                                {t("App.createRole.active")}
                              </MenuItem>
                              <MenuItem value={0}>
                                {t("App.createRole.inactive")}
                              </MenuItem>
                            </Select>
                          )}
                        />

                        <FormHelperText
                          sx={{
                            margin: "-10px 0 0 0",
                            color: "#e52900",
                          }}
                        >
                          {errors.status ? errors.status.message : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                gap={2.2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={3.3} sm={5.5} lg={2} className="full-width">
                  <Button
                  sx={{ padding:{md:"12px 24px 12px 24px !important"},
                        width:{md:"104px"} }}
                    type="button"
                    disableRipple
                    className="btn__filled__gradient dimentions-btn__applyFilter modal__btnEdit"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {t("App.buttonLabels.cancel")}
                  </Button>
                </Grid>

                <Grid item xs={8} sm={5.5} lg={4} className="full-width">
                  <Button
                  sx={{ padding:{md:"12px 24px 12px 24px !important"},
                  width:{md:"203px"} }}
                    disabled={!handleDisabledOfButtonEdit()}
                    className={`${
                      handleDisabledOfButtonEdit()
                        ? "btn__applyFilter"
                        : "btn__applyFilter-disabled"
                    } dimentions-btn__applyFilter modal__btnEdit`}
                    type="submit"
                    disableRipple
                    startIcon={!isLoadingData ? <CheckIcon /> : <></>}
                  >
                    {!isLoadingData ? (
                      t("App.buttonLabels.confirmChange")
                    ) : (
                      <CircularProgress size={21} color="inherit" />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>

          {isOpenModalInfo && (
            <ModalInfo
              responseData={editProduct}
              open={isOpenModalInfo}
              onClose={() => {
                setIsOpenModalInfo(false);
                setOpen(false);
              }}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};
