import { React, useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Input, TextField } from "@mui/material";
import { IconButton, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../../components/common/languageSwitcher";
import InfoOutlinedIcon from "@mui/icons-material/Error";
import logo from "../../assets/common/logo.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import CheckIcon from "@mui/icons-material/CheckCircle";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import ReCAPTCHA from "react-google-recaptcha";
import { recoveryPassword } from "../../services/auth/authServices";
import { REGEXP } from "../../consts/regexp";
import { useParams, useNavigate } from "react-router-dom";
import ModalInfo from "../../components/common/Modals/modalInfo";
import { ModalDecision } from "../../components/common/Modals/modalDecision";
import NewPasswordImage from "./newPasswordImage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBoxOutlined";
import { CODES } from "../../consts/codes";

export const ValidateCredentials = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const icon = (
    <CheckBoxOutlineBlankIcon sx={{ fontSize: "24px !important" }} />
  );
  const checkedIcon = <CheckBoxIcon sx={{ fontSize: "24px !important" }} />;

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t("App.validationMessages.required"))
      .min(8, t("App.validationMessages.invalidPassword"))
      .max(15, t("App.validationMessages.invalidPassword"))
      .test(
        "upperCase",
        t("App.validationMessages.invalidPassword"),
        function (value) {
          if (!!value) {
            const schema = yup.string().matches(/^(?=.*?[A-Z])/);
            return schema.isValidSync(value);
          }
          return true;
        }
      )
      .test(
        "lowerCase",
        t("App.validationMessages.invalidPassword"),
        function (value) {
          if (!!value) {
            const schema = yup.string().matches(/(?=.*?[a-z])/);
            return schema.isValidSync(value);
          }
          return true;
        }
      )
      .test(
        "number",
        t("App.validationMessages.invalidPassword"),
        function (value) {
          if (!!value) {
            const schema = yup.string().matches(/(?=.*?[0-9])/);
            return schema.isValidSync(value);
          }
          return true;
        }
      )
      .test(
        "specialChar",
        t("App.validationMessages.invalidPassword"),
        function (value) {
          if (!!value) {
            const schema = yup.string().matches(/(?=.*?[#?!@$%^&*-])/);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
    confirmPassword: yup
      .string()
      .required(t("App.validationMessages.required"))
      .oneOf(
        [yup.ref("password"), null],
        t("App.validationMessages.noMatchtwo")
      ),
    code: yup.string().required(t("App.validationMessages.required")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openModalDecision, setOpenModalDecision] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isBorderCodeFocus, setIsBorderCodeFocus] = useState(false);
  const [isBorderPasswordFocus, setIsBorderPasswordFocus] = useState(false);
  const [isBorderConPasswordFocus, setIsBorderConPasswordFocus] =
    useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const checklist = console.log(watch(["password", "confirmPassword"]));

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  /**
   * Función para validar que la nueva contraseña
   * no sea igual a la anterior
   * @param {*} data // Credeciales del usuario y contraseña nueva
   * @returns // true si la contraseña es diferente, false si es igual
   */
  const verifyPassword = (data) => {
    if (data.oldPassword === data.newPassword) {
      return false;
    }
    return true;
  };

  const handleValidateCredentials = async (data) => {
    console.log("Datos", params);
    if (captcha.current.getValue()) {
      setIsLoading(true);
      setValidCaptcha(true);
      const obj = {
        email: params.email,
        password: data.password,
        confirmationCode: data.code,
      };
      const service = await recoveryPassword(obj);
      if (service.data.responseCode !== CODES.COD_RESPONSE_SUCCESS_REQUEST) {
        setIsSuccessfuly(false);
        if (
          service.data.responseCode === CODES.COD_RESPONSE_ERROR_RESET_PASSWORD
        ) {
          setModalTitle(t("App.validateCredentials.rightPanel.modalTitle"));
        } else if (
          service.data.responseCode === CODES.COD_RESPONSE_ERROR_GENERATE_FILE
        ) {
          setModalTitle(t("App.validateCredentials.rightPanel.warningModal"));
        }
      } else {
        setIsSuccessfuly(true);
        setModalTitle(t("App.validateCredentials.rightPanel.successModal"));
      }

      setResponseData(service);
      setIsOpenModal(true);
      setIsLoading(false);
    } else {
      setValidCaptcha(false);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleButtonDisabled = () => {
    if (watch("password") && watch("confirmPassword") && acceptTerms) {
      return false;
    } else {
      return true;
    }
  };

  const [valuesConfirmPassword, setValuesConfirmPassword] = useState({
    confirmPassword: "",
    showConfirmPassword: false,
  });

  const handleClickShowConfirmPassword = () => {
    setValuesConfirmPassword({
      ...valuesConfirmPassword,
      showConfirmPassword: !valuesConfirmPassword.showConfirmPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const [acceptTerms, setacceptTerms] = useState(false);
  const [validCaptcha, setValidCaptcha] = useState(true);
  const [isSuccessfuly, setIsSuccessfuly] = useState(false);

  let captcha = useRef(null);

  const onChange = () => {
    if (captcha.current.getValue()) {
      setValidCaptcha(true);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
    if (responseData.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
      navigate("/");
    }
  };

  /**
   * Función para abrir el modal de decisio
   */
  const handleOpenDecision = () => {
    setOpenModalDecision(true);
  };

  /**
   * Función para retroceder a la página anterior
   */
  const handleEndProcess = () => {
    setOpenModalDecision(false);
    navigate("/");
  };

  /**
   * Función para cancelar volver al inicio
   */
  const handleCancel = () => {
    setOpenModalDecision(false);
  };

  /**
   * Responsive helpers
   */

  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  /**
   * Funciones para saber si el value de un Textfield esta vacio para cambiar el estilo del borde
   */
  const handleChangeCodeBorder = (values) => {
    if (values) {
      setIsBorderCodeFocus(true);
    } else {
      setIsBorderCodeFocus(false);
    }
  };

  const handleChangePassBorder = (values) => {
    if (values) {
      setIsBorderPasswordFocus(true);
    } else {
      setIsBorderPasswordFocus(false);
    }
  };

  const handleChangeConPassBorder = (values) => {
    if (values) {
      setIsBorderConPasswordFocus(true);
    } else {
      setIsBorderConPasswordFocus(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="validateCredentials__wrap">
      <Grid className="validateCredentials__leftSide">
        <Grid container direction="column" spacing={2} sx={{ margin: "0px" }}>
          <Grid className="newPasswordImage__img-container">
            <NewPasswordImage />
          </Grid>
          <Grid item className="changePasswordFT__leftSide-instructions">
            <Grid className="changePasswordFT__leftSide-instructions--instructionSet">
              <Grid style={{ alignText: "center" }}>
                <p className="instruction-text__title">
                  {t("App.validateCredentials.leftPanel.instructionText")}
                </p>
              </Grid>
              <Grid className="instructions-container" sx={{ display: "flex" }}>
                <Grid className="instructions-container__column">
                  <Grid
                    container
                    spacing={1}
                    className="instruction-text__container"
                  >
                    <Grid
                      xs={1.3}
                      sm={1.3}
                      lg={1.3}
                      xl={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      item
                    >
                      {watch("password")?.length >= 8 &&
                      watch("password")?.length <= 15 ? (
                        <CheckIcon
                          fontSize="large"
                          className="validateCredentials__leftSide-check"
                        />
                      ) : (
                        <InfoOutlinedIcon
                          fontSize="large"
                          className="validateCredentials__leftSide-required"
                        />
                      )}
                    </Grid>

                    <Grid item xs={10.7} sm={10.7} lg={10.7} xl={11}>
                      <label className="instruction-text">
                        {t("App.validateCredentials.leftPanel.instructionOne")}
                      </label>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    className="instruction-text__container"
                  >
                    <Grid
                      xs={1.3}
                      sm={1.3}
                      lg={1.3}
                      xl={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                      }}
                      item
                    >
                      {REGEXP.ONE_LOWER_LETTER.test(watch("password")) ? (
                        <CheckIcon
                          fontSize="large"
                          className="validateCredentials__leftSide-check"
                        />
                      ) : (
                        <InfoOutlinedIcon
                          fontSize="large"
                          className="validateCredentials__leftSide-required"
                        />
                      )}
                    </Grid>

                    <Grid item xs={10.7} sm={10.7} lg={10.7} xl={11}>
                      <label className="instruction-text">
                        {t("App.validateCredentials.leftPanel.instructionTwo")}
                      </label>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    className="instruction-text__container"
                  >
                    <Grid
                      xs={1.3}
                      sm={1.3}
                      lg={1.3}
                      xl={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                      }}
                      item
                    >
                      {REGEXP.ONE_UPPER_LETTER.test(watch("password")) ? (
                        <CheckIcon
                          fontSize="large"
                          className="validateCredentials__leftSide-check"
                        />
                      ) : (
                        <InfoOutlinedIcon
                          fontSize="large"
                          className="validateCredentials__leftSide-required"
                        />
                      )}
                    </Grid>

                    <Grid item xs={10.7} sm={10.7} lg={10.7} xl={11}>
                      <label className="instruction-text">
                        {t(
                          "App.validateCredentials.leftPanel.instructionThree"
                        )}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid className="instructions-container__column">
                  <Grid
                    container
                    spacing={1}
                    className="instruction-text__container"
                  >
                    <Grid
                      xs={1.3}
                      sm={1.3}
                      lg={1.3}
                      xl={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                      }}
                      item
                    >
                      {REGEXP.ONE_NUMBER.test(watch("password")) ? (
                        <CheckIcon
                          fontSize="large"
                          className="validateCredentials__leftSide-check"
                        />
                      ) : (
                        <InfoOutlinedIcon
                          fontSize="large"
                          className="validateCredentials__leftSide-required"
                        />
                      )}
                    </Grid>

                    <Grid item xs={10.7} sm={10.7} lg={10.7} xl={11}>
                      <label className="instruction-text">
                        {t("App.validateCredentials.leftPanel.instructionFour")}
                      </label>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    className="instruction-text__container"
                  >
                    <Grid
                      xs={1.3}
                      sm={1.3}
                      lg={1.3}
                      xl={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                      }}
                      item
                    >
                      {REGEXP.ONE_SPECIAL_CHAR.test(watch("password")) ? (
                        <CheckIcon
                          fontSize="large"
                          className="validateCredentials__leftSide-check"
                        />
                      ) : (
                        <InfoOutlinedIcon
                          fontSize="large"
                          className="validateCredentials__leftSide-required"
                        />
                      )}
                    </Grid>

                    <Grid item xs={10.7} sm={10.7} lg={10.7} xl={11}>
                      <label className="instruction-text">
                        {t("App.validateCredentials.leftPanel.instructionFive")}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="validateCredentials__rightSide">
        <Grid
          container
          item
          direction="column"
          spacing={2}
          className="inner-container"
          sx={{ margin: "0px !important" }}
        >
          <Grid className="validateCredentials__head-container">
            <Grid item xs={7} sm={6} md={4} lg={10} xl={12}>
              <div className="login__logo">
                <img
                  src={logo}
                  alt="Logo Prescriptiva"
                  className="logo-CENAnalíticos"
                />
              </div>
            </Grid>
            <Grid item xs={4.2} sm={2} md={3} lg={3} xl={2.8}>
              <LanguageSwitcher />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center !important",
            }}
          >
            <Grid item className="validateCredentials__form-container">
              <Grid item className="back-containerCredentials">
                <Grid container direction="row">
                  <Grid item sx={{ paddingRight: "2rem" }}>
                    <IconButton
                      className="btn__filled__blue"
                      disableRipple
                      onClick={handleOpenDecision}
                      sx={{ borderRadius: "8px" }}
                    >
                      <ArrowBackIcon fontSize="medium" />
                    </IconButton>
                  </Grid>
                  <Grid item className="col-start">
                    <p className="validateCredentials__form-backText">
                      {t("App.login.rightPanel.backButton")}
                    </p>
                  </Grid>
                </Grid>
              </Grid>

              <Grid sx={{ width: "100%" }}>
                <Grid item sx={{ width: "100% !important" }}>
                  <p className="validateCredentials__title">
                    {t("App.validateCredentials.rightPanel.mainTitle")}
                  </p>
                </Grid>

                <Grid item container spacing={0.5} direction="column">
                  <Grid item>
                    {isSuccessfuly && (
                      <p className="subheading__success-color">
                        {t("App.validateCredentials.rightPanel.successfulText")}
                      </p>
                    )}
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid className="validateCredentials__form ">
                    <Grid item>
                      <p className="validateCredentials__instructions">
                        {t(
                          "App.validateCredentials.rightPanel.instructionText"
                        )}
                      </p>
                    </Grid>
                    <form onSubmit={handleSubmit(handleValidateCredentials)}>
                      <Controller
                        defaultValue=""
                        name="code"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={
                              errors.code
                                ? "login__form-input--error"
                                : isBorderCodeFocus
                                ? "login__form-input--focus"
                                : "login__form-input"
                            }
                            disableUnderline
                            label={t("App.validateCredentials.rightPanel.code")}
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -3px",
                                alignItems: "center",
                                padding: "4px",
                                transformOrigin: "-45px -40px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D", // Mismo valor para mantener alineación
                                },
                              },
                            }}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              handleChangeCodeBorder(e.target.value);
                            }}
                            type="text"
                            InputProps={{
                              autoComplete: "off ",
                              sx: {
                                height: "100% !important",
                                width: "100% !important",
                                "&:-webkit-autofill": {
                                  backgroundColor: "white !important",
                                  // boxShadow: "0 0 0 100px white inset !important",
                                  borderRadius: "8px",
                                },
                              },
                            }}
                          />
                        )}
                      />

                      {errors.code && (
                        <p className="validateCredentials__form-errorText">
                          {errors.code.message}
                        </p>
                      )}

                      <Controller
                        defaultValue=""
                        name="password"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={
                              errors.password
                                ? "login__form-input--error"
                                : isBorderPasswordFocus
                                ? "login__form-input--focus"
                                : "login__form-input"
                            }
                            disableUnderline
                            label={t(
                              "App.validateCredentials.rightPanel.password"
                            )}
                            sx={{
                              "input::-ms-reveal, input::-ms-clear": {
                                display: "none",
                              },
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -3px",
                                alignItems: "center",
                                padding: "4px",
                                transformOrigin: "-45px -40px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D", // Mismo valor para mantener alineación
                                },
                              },
                            }}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              handleChangePassBorder(e.target.value);
                            }}
                            type={values.showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowPassword}
                                    sx={{
                                      padding: "0px !important",
                                      marginRight: "-10px",
                                    }}
                                  >
                                    {values.showPassword ? (
                                      <VisibilityOffIcon
                                        className={
                                          errors?.password
                                            ? "icon--error"
                                            : isBorderPasswordFocus
                                            ? "icon--focus"
                                            : "icon"
                                        }
                                      />
                                    ) : (
                                      <VisibilityIcon
                                        className={
                                          errors?.password
                                            ? "icon--error"
                                            : isBorderPasswordFocus
                                            ? "icon--focus"
                                            : "icon"
                                        }
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              autoComplete: "off ",
                              sx: {
                                height: "100% !important",
                                width: "100% !important",
                                "&:-webkit-autofill": {
                                  backgroundColor: "white !important",
                                  // boxShadow: "0 0 0 100px white inset !important",
                                  borderRadius: "8px",
                                },
                              },
                            }}
                          />
                        )}
                      />
                      <br />

                      {errors.password && (
                        <p className="validateCredentials__form-errorText">
                          {errors.password.message}
                        </p>
                      )}

                      <Controller
                        defaultValue=""
                        name="confirmPassword"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={
                              errors.confirmPassword
                                ? "login__form-input--error"
                                : isBorderConPasswordFocus
                                ? "login__form-input--focus"
                                : "login__form-input"
                            }
                            disableUnderline
                            id="confirmPassword"
                            name="confirmPassword"
                            label={t(
                              "App.validateCredentials.rightPanel.confirmPassword"
                            )}
                            sx={{
                              "input::-ms-reveal, input::-ms-clear": {
                                display: "none",
                              },
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -3px",
                                alignItems: "center",
                                padding: "4px",
                                transformOrigin: "-45px -40px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D", // Mismo valor para mantener alineación
                                },
                              },
                            }}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              handleChangeConPassBorder(e.target.value);
                            }}
                            type={
                              valuesConfirmPassword.showConfirmPassword
                                ? "text"
                                : "password"
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowConfirmPassword}
                                    sx={{
                                      padding: "0px !important",
                                      marginRight: "-10px",
                                    }}
                                  >
                                    {valuesConfirmPassword.showConfirmPassword ? (
                                      <VisibilityOffIcon
                                        className={
                                          errors?.confirmPassword
                                            ? "icon--error"
                                            : isBorderConPasswordFocus
                                            ? "icon--focus"
                                            : "icon"
                                        }
                                      />
                                    ) : (
                                      <VisibilityIcon
                                        className={
                                          errors?.confirmPassword
                                            ? "icon--error"
                                            : isBorderConPasswordFocus
                                            ? "icon--focus"
                                            : "icon"
                                        }
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              autoComplete: "off",
                              sx: {
                                height: "100% !important",
                                width: "100% !important",
                                "&:-webkit-autofill": {
                                  backgroundColor: "white !important",
                                  // boxShadow: "0 0 0 100px white inset !important",
                                  borderRadius: "8px",
                                },
                              },
                            }}
                          />
                        )}
                      />

                      {errors.confirmPassword && (
                        <p className="validateCredentials__form-errorText">
                          {errors.confirmPassword.message}
                        </p>
                      )}
                      <br />

                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: "22px" },
                              color: "#543AB4",
                              "&.Mui-checked": {
                                color: "#543AB4",
                              },
                              margin: "0px !important",
                            }}
                          />
                        }
                        onChange={() => setacceptTerms(!acceptTerms)}
                        label={
                          <a
                            href="https://www.carvajaltys.com/politica-de-tratamiento-de-datos/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-text-one__link heading__primary-one"
                          >
                            {t(
                              "App.validateCredentials.rightPanel.acceptPolicies"
                            )}
                          </a>
                        }
                      />
                      <br />
                      <Grid
                        direction="column"
                        style={{
                          width: "100%",
                          marginTop: "2%",
                          justifyItems: "center",
                        }}
                      >
                        <Grid item>
                          <Grid item className="recaptcha_container">
                            <ReCAPTCHA
                              ref={captcha}
                              className="captcha-container__captcha-only"
                              sitekey="6LfkwBwgAAAAAIzpuBBjEZL8Pid8GvnYNq5EYE40"
                              onChange={onChange}
                              hl={t("App.login.rightPanel.recaptcha")}
                              sx={{ justifySelf: "center !important" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item>
                          {validCaptcha ? (
                            <p className="label-invisible"></p>
                          ) : (
                            <p className="error-message">
                              {t("App.login.rightPanel.recaptchaMessage")}
                            </p>
                          )}
                        </Grid>
                        <Grid sx={{ width: "100%", textAlign: "center" }}>
                          <button
                            className={
                              handleButtonDisabled()
                                ? "validateCredentials__form-button--disabled"
                                : "validateCredentials__form-button"
                            }
                            type="submit"
                            disabled={handleButtonDisabled()}
                            style={{ marginTop: "0.7rem" }}
                          >
                            {!isLoading ? (
                              t("App.validateCredentials.rightPanel.button")
                            ) : (
                              <CircularProgress size={16} color="inherit" />
                            )}
                          </button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {checklist && (
        <div>
          <CheckIcon></CheckIcon>
        </div>
      )}
      <ModalDecision
        title={t("App.validateCredentials.rightPanel.modalTitle")}
        message={t("App.validateCredentials.rightPanel.modalMessage")}
        disagreeText={t("App.validateCredentials.rightPanel.modalGoBack")}
        agreeText={t("App.validateCredentials.rightPanel.modalContinue")}
        setIsOpen={setOpenModalDecision}
        isOpen={openModalDecision}
        handleAgree={handleCancel}
        handleDisagree={handleEndProcess}
        customClass="validateCredentials_modal"
      />
      <ModalInfo
        title={modalTitle}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />
    </div>
  );
};
