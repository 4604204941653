import { Button, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { CODES } from "../../consts/codes";
import moment from "moment";
import { useSelector } from "react-redux";
import { getUser } from "../../pages/userSlice";
import {
  ApplyFiltersSalesForce,
  GetFileStatusList,
} from "../../services/user/salesForceServices";
import { GetListUploadesExternalFiles } from "../../services/user/externalFiles/externalFilesService";
import ModalSeeCompanies from "../../components/common/AdminUsersClient/modalSeeCompanies";

export const FilterSalesForce = (props) => {
  /**
   * Skeleton
   */

  const skeletonLine = [
    {
      id: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      startDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      endDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      user: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      file: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      state: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      errors: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  const { t } = useTranslation();
  const [statusList, setStatusList] = useState([]);
  const userInfo = useSelector(getUser);
  const handleFilters = async (data, e) => {
    try {
      e.preventDefault();
      props.setFilterNotFound(false);
      props.setIsLoadingData(true);

      props.idFile === 4
        ? props.setData([])
        : props.setData(props.skeletonLine ? props.skeletonLine : skeletonLine);

      const obj =
        props.idFile === 27
          ? {
              eanCompany: userInfo.company.eanCompany,
              country: userInfo.company.country,
              emailLoad: userInfo.email,
              state: data.role ? data.role.label : "",
              documentType: props.documentType,
              typeCompany: userInfo.company.typeCompany,
              idFileType: props.idFile,
            }
          : {
              eanProvider: userInfo.company.eanCompany,
              country: userInfo.company.country,
              emailLoad: userInfo.email,
              state: data.role ? data.role.label : "",

              idFileType: props.idFile,
            };

      const salesForceService =
        props.idFile === 27
          ? await GetListUploadesExternalFiles(obj)
          : await ApplyFiltersSalesForce(obj);
      props.setIsLoadingData(false);
      if (salesForceService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          salesForceService.data.responseCode === 200 ||
          salesForceService.data.responseCode === 0
        ) {
          const responseMessage = salesForceService.data.responseMessage;

          const ProcessRows = responseMessage.map((item, idx) => {
            return {
              id: !item.idLoad ? " - " : item.idLoad,
              startDate:
                item.starDate === null
                  ? ""
                  : moment(item.starDate).format("YYYY-MM-DD hh:mm:ss"),
              endDate:
                item.endDate === null
                  ? ""
                  : moment(item.endDate).format("YYYY-MM-DD hh:mm:ss"),
              user: !item.userLoad ? " - " : item.userLoad,
              companies:
                item.lstCompanies?.length > 1 ? (
                  <ModalSeeCompanies
                    data={item.lstCompanies}
                    userName={item.userLoad}
                  />
                ) : (
                  item.lstCompanies !== null &&
                  item.lstCompanies.length > 0 &&
                  item.lstCompanies[0].renameCompany
                ),
              file: !item.fileName
                ? " - "
                : {
                    fileName: item.fileName,
                    pathFileName: item.pathFileName,
                  },
              state: !item.state ? " - " : item.state,
              errors: item.awsUploadLog
                ? {
                    awsUploadLog: item.awsUploadLog,
                  }
                : {
                    logFileName: item.logFileName,
                    pathLogFileName: item.pathLogFileName,
                    awsUploadLog: item.awsUploadLog,
                  },
            };
          });
          if (ProcessRows.length === 0) {
            props.setFilterNotFound(true);
          }
          props.setFilterApply(data.role);
          props.setData(ProcessRows);
        } else {
          props.setFilterNotFound(true);
          props.setFilterApply(data.role);
          props.setData([]);
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const schema = yup.object().shape({
    ean: yup.string().min(2),
    country: yup.string().min(2),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        if (mounted) {
          const statusService = await GetFileStatusList();
          if (statusService.status === CODES.COD_RESPONSE_HTTP_OK) {
            if (
              statusService.data.responseCode ===
              CODES.COD_RESPONSE_SUCCESS_REQUEST
            ) {
              const responseMessage = statusService.data.responseMessage;
              const roleList = responseMessage.map((item) => ({
                label: item.name,
                value: item.pk,
              }));
              setStatusList(roleList);
            }
          }
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }
    fetchData();

    return () => {
      mounted = false;
    };
  }, [props.userInfo]);

  /**
   * Función para deshabilitar el botón del filtro
   */

  const disableButton = () => {
    if (watch("role")) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid
        xs={12}
        sm={5.9}
        md={8}
        lg={8}
        xl={9.5}
        item
        className="side-line__bottomAlign"
      >
        <form id="filter-sales-form" onSubmit={handleSubmit(handleFilters)}>
          <Grid
            container
            direction="row"
            className="formfilt-container"
            spacing={4}
          >
            <Grid sm={6} xl={7} item>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <Controller
                    name="role"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className={
                          errors.role
                            ? "select__filters__errors"
                            : "select__filters__medium"
                        }
                        options={statusList}
                        renderInput={(params) => {
                          return (
                            <TextField
                              label={
                                props.state
                                  ? t("App.traders.filter.state")
                                  : t("App.salesForce.status")
                              }
                              sx={{
                               label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -5px",
                              transformOrigin: "-45px -10px",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                              {...params}
                              onChange={onChange}
                            />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>     
      <Grid xs={12} sm={6} md={8} lg={3.8} xl={2.5} item>
      <Grid
          container
          direction="row"
          className=""
          gap={1}
      >
          <Grid xs={12} sm={6.1} md={6} lg={6} xl={6.5} item>
            <Button  
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                    transform:"rotate(90deg)"
                  }}
                />
              }
              disabled={disableButton()}
              disableRipple
              className={`${
                disableButton()
                  ? "btn__applyFilter-disabled"
                  : "btn__applyFilter"
              } dimentions-btn__applyFilter`}
              type="submit"
              form="filter-sales-form"
              fullWidth
            >
              {t("App.userDetail.applyFilters")}
            </Button>
          </Grid>
          <Grid xs={12} sm={4.7} md={8} lg={4.8} xl={4.7} item>
            <Button
              onClick={() => {
                setValue("status", 0);
                setValue("role", null);
                props.setData(props.unfilteredData);
                props.setFilterApply(null);
                props.setFilterNotFound(false);
              }}
              disableRipple
              disabled={disableButton()}
              className="btn__deleteFilter dimentions-btn__cleanFilter"
              fullWidth
            >
              {t("App.userDetail.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
