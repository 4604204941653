import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { CompaniesSelection } from "../BusinessGroup/companiesSelection";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountriesAndCompanies,
  getCountriesAndCompaniesStatus,
  resetCountriesAndCompaniesState,
} from "../../../parts/businessGroup/businessGroupSlice";
import { useReducer } from "react";
import { TooltipMainCompany } from "../BusinessGroup/tooltipMainCompany";
import { getUser } from "../../../pages/userSlice";
import { CreateBusinessGroup } from "../../../services/user/businessGroup";
import { CODES } from "../../../consts/codes";
import { ModalDecision } from "./modalDecision";
import moment from "moment";
import { REGEXP } from "../../../consts/regexp";

export const ModalCreateBusinessGroup = ({
  open,
  setOpen,
  setModalResponseCreateInfo,
  setIsOpenModalResponseCreate,
  setModalResponseTitle,
  setData,
  data,
  filters,
  setIsLoadingData,
  setNoContent,
  setIsNoFilterFound,
}) => {
  /**
   * Title Container component
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use Form
   */

  const schema = yup.object().shape({
    groupName: yup
      .string()
      .required(t("App.validationMessages.required"))
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .matches(
        REGEXP.LETTERS_AND_NUMBERS,
        t("App.validationMessages.onlyAlphanumeric")
      ),
    country: yup
      .object()
      .shape({
        country: yup.string().required(t("App.validationMessages.required")),
        countryName: yup
          .string()
          .required(t("App.validationMessages.required")),
      })
      .typeError(t("App.validationMessages.required")),
    companies: yup.array().of(
      yup.object().shape({
        country: yup
          .object()
          .shape({
            country: yup.string(),
            countryName: yup.string(),
          })
          .typeError(t("App.validationMessages.required")),
        company: yup
          .object()
          .shape({
            eanCompany: yup.string(),
            companyName: yup.string(),
          })
          .typeError(t("App.validationMessages.required")),
        state: yup
          .number()
          .moreThan(-1, t("App.validationMessages.required"))
          .required(t("App.validationMessages.required")),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companies: [],
      groupName: "",
      country: "",
      mainCompany: null,
    },
  });

  /**
   * Use array field
   */
  const {
    fields: companiesFields,
    append: companiesAppend,
    remove: companiesRemove,
    update: companiesUpdate,
  } = useFieldArray({
    control,
    name: "companies",
  });

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   */

  //User selectors
  const userInfo = useSelector(getUser);
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;

  //Countries and companies selectors
  const countriesAndCompanies = useSelector(getCountriesAndCompanies);
  const countriesAndCompaniesStatus = useSelector(
    getCountriesAndCompaniesStatus
  );

  /**
   * Use Reducer
   */

  const initialState = () => [];

  const reducer = (state = initialState(), action = {}) => {
    const nonRepeatedValues = new Set(state);
    let copyOfState = [...nonRepeatedValues];

    switch (action.type) {
      case "select":
        copyOfState.push(action.company);
        return copyOfState;
      case "remove":
        let arrayWithRemovedCompany = copyOfState.filter(
          (companyOnList) =>
            companyOnList.eanCompany !== action.company.eanCompany
        );
        return arrayWithRemovedCompany;

      case "reset":
        return initialState();
      default:
        return state;
    }
  };

  //Main companies
  const [mainCompanyList, dispatchMainCompanies] = useReducer(
    reducer,
    reducer()
  );

  /**
   * Use State
   */

  //Loading

  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  //Saving data
  const [businessGroupData, setBusinessGroupData] = useState({});

  //Flow modals
  const [isOpenModalDecisionCreate, setIsOpenModalDecisionCreate] =
    useState(false);

  /**
   * Use Effect
   */

  //When opening the modal
  useEffect(() => {
    reset();
    const emptyCompany = {
      country: null,
      company: null,
      state: -1,
      key: uuidv4(),
    };
    companiesAppend(emptyCompany);
  }, []);

  /**
   * Handles
   */

  /**
   * Closes the create modal and resets the form into initial state
   */
  const onClose = () => {
    reset();
    const emptyCompany = {
      country: null,
      company: null,
      state: -1,
      key: uuidv4(),
    };
    companiesAppend(emptyCompany);
    dispatchMainCompanies({ type: "reset" });
    setOpen(false);
  };

  /**
   * Disables create business group button
   */
  const disableCreateBusinessGroup = () => {
    return false;
  };

  /**
   * Sets title for response modal when create request has been sent
   * @param {} responseCode response code of create request
   * @returns Title based on responseCode
   */
  const setTitle = (responseCode, groupName) => {
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        return t("App.manageBusinessGroup.create.success");
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        return t("App.manageBusinessGroup.create.error", { name: groupName });

      default:
        return t("App.validationMessages.error");
    }
  };

  /**
   * Builds companies object required for create object
   * @param {*} fields form fields that contains companies
   * @returns structured object
   */
  const buildCompaniesObject = (fields) => {
    const buildObject = fields.map((field) => {
      return {
        eanCompany: field.company.eanCompany,
        nameCompany: field.company.companyName,
        country: field.country.country,
        state: field.state === 1 ? "ACTIVO" : "INACTIVO",
      };
    });

    return buildObject;
  };

  /**
   *Stores the form data and open decision modal of creation flow
   * when create button is pressed
   * @param {*} data form data
   */
  const handleConfirmCreateBusinessGroup = (data) => {
    setBusinessGroupData(data);
    setIsOpenModalDecisionCreate(true);
  };

  /**
   * Function when decision is agree
   */
  const handleAgree = () => {
    setIsLoadingCreate(true);
    handleCreateBusinessGroup(businessGroupData);
    setIsOpenModalDecisionCreate(false);
  };

  /**
   * Function when decision is disagree
   */
  const handleDisagree = () => {
    setIsOpenModalDecisionCreate(false);
  };

  /**
   * Creates a new Business Group
   * @param {object} data business group data
   */
  const handleCreateBusinessGroup = async (data) => {
    console.log(data);
    const lstCompanies = buildCompaniesObject(data.companies);

    let isMainCompanyActive = false;

    for (const company of lstCompanies) {
      if (
        company.eanCompany === data.mainCompany?.eanCompany &&
        company.state === "ACTIVO"
      ) {
        isMainCompanyActive = true;
      }
    }

    const obj = {
      country: data.country.country,
      businessGroupName: data.groupName,
      eanMainCompany: isMainCompanyActive ? data.mainCompany?.eanCompany : null,
      creationUser: fullNameUser,
      lstCompanyBusinessGroupDTO: lstCompanies,
    };

    const requestCreateBusinessGroup = await CreateBusinessGroup(obj);

    const responseCode = requestCreateBusinessGroup.data.responseCode;

    setModalResponseTitle(setTitle(responseCode, obj.businessGroupName));

    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        dispatch(resetCountriesAndCompaniesState());
        handleAddCompaniesVisually(
          requestCreateBusinessGroup.data.responseMessage,
          obj.businessGroupName,
          lstCompanies
        );
        break;

      default:
        setModalResponseCreateInfo(requestCreateBusinessGroup);
        break;
    }

    setIsLoadingCreate(false);
    onClose();
    setIsOpenModalResponseCreate(true);
  };

  /**
   * Adds the created group to the business group table
   * @param {string} idGroup id of the created group
   * @param {string} businessGroupName name of created business group
   * @param {array} lstCompanies list of companies that belong to business group
   */
  const handleAddCompaniesVisually = (
    idGroup,
    businessGroupName,
    lstCompanies
  ) => {
    let newArray = data;
    setIsLoadingData(true);
    setData([]);

    const formattedCompanies = lstCompanies.map((company) => {
      return {
        country: company.country,
        creationDate: moment(new Date()).format("YYYY-MM-DD HH:MM:SS"),
        creationUser: fullNameUser,
        modificationDate: "",
        modificationUser: "",
        businessGroupName: businessGroupName,
        eanProvider: company.eanCompany,
        provider: company.nameCompany,
        state: company.state,
        edit: { idGroup: idGroup },
      };
    });

    const finalArray = [...formattedCompanies, ...newArray];
    setData(finalArray);
    setIsLoadingData(false);
    setNoContent(false);
    setIsNoFilterFound(false);
  };

  /**
   *
   * Función para deshabilitar o habilitar el botón de crear en base a si los campos están llenados
   *
   * @returns true or false
   */
  const handleDisabledButtonCreate = () => {
    if (watch("groupName") && watch("country")) {
      return !companiesFields.every(
        (item) => item.company && item.state !== -1 && item.country
      );
    }

    return true;
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="customized-dialog-title"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            height: "100%",
            maxWidth: "900px",
            maxHeight: "550px",
            boxShadow: "none",
            borderRadius: "22px",
            padding: "2rem",
          },
        },
      }}
    >
      <DialogTitleContainer onClose={onClose}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item className="display-large__primary-one">
            {t("App.manageBusinessGroup.create.title")}
          </Grid>
        </Grid>
      </DialogTitleContainer>

      <DialogContent className="scroll-table">
        <Grid container direction="column" sx={{ paddingTop: "1%" }}>
          <form
            id="hook-business-group"
            onSubmit={handleSubmit(handleConfirmCreateBusinessGroup)}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                columnSpacing={4}
                rowSpacing={2}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Controller
                        control={control}
                        name={"groupName"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            className={
                              errors.groupName
                                ? "select__filters__errors__sm"
                                : "select__filters__sm"
                            }
                            label={t(
                              "App.manageBusinessGroup.create.name"
                            )}
                            InputLabelProps={{
                              className: 'input-component__label'
                            }}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>

                    {errors && errors.groupName && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {errors.groupName.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.manageBusinessGroup.create.country")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        name={"country"}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            options={countriesAndCompanies}
                            getOptionLabel={(option) => option.countryName}
                            isOptionEqualToValue={(option, value) => {
                              return option.country == value.country;
                            }}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.country
                                ? "select__filters__errors__sm"
                                : "select__filters__sm"
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.manageBusinessGroup.create.country"
                                  )}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values, reason) => {
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                    {errors && errors.country && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {console.log(errors.country)}
                        {errors.country.message
                          ? errors.country.message
                          : errors.country.country.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <CompaniesSelection
                companiesFields={companiesFields}
                companiesAppend={companiesAppend}
                companiesRemove={companiesRemove}
                companiesUpdate={companiesUpdate}
                control={control}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                countriesAndCompaniesList={countriesAndCompanies}
                countriesAndCompaniesStatus={countriesAndCompaniesStatus}
                mainCompanyList={mainCompanyList}
                dispatchMainCompanies={dispatchMainCompanies}
              />
            </Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                columnSpacing={4}
                rowSpacing={2}
              >
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.manageBusinessGroup.create.mainCompany")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        defaultValue={null}
                        control={control}
                        name="mainCompany"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Autocomplete
                            disabled={
                              !companiesFields.some((item) => item.company)
                            }
                            options={mainCompanyList}
                            getOptionLabel={(option) => option.companyName}
                            isOptionEqualToValue={(option, value) => {
                              return option.eanCompany == value.eanCompany;
                            }}
                            value={value}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.mainCompany
                                ? "select__filters__errors__sm"
                                : "select__filters__sm"
                            }
                            onChange={(e, values) => onChange(values)}
                            renderInput={(params) => (
                              <TextField
                                placeholder={t(
                                  "App.manageBusinessGroup.create.mainCompany"
                                )}
                                {...params}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  className="col-start"
                  style={{
                    marginTop: "1.5%",
                    marginLeft: "-3%",
                  }}
                >
                  <Grid container direction="column">
                    <Grid item className="col-flex">
                      <TooltipMainCompany />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </DialogContent>

      <DialogActions className="col-end">
        <Grid container columnGap={4} sx={{ padding: "2.5%" }}>
          <Grid item>
            <Button
              onClick={() => {
                onClose();
              }}
              className="btn__filled__gradient"
            >
              {t("App.createUser.cancelButton")}
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="submit"
              startIcon={
                !isLoadingCreate ? <AddIcon sx={{ fontSize: 16 }} /> : <></>
              }
              form="hook-business-group"
              disabled={handleDisabledButtonCreate()}
              className={
                handleDisabledButtonCreate()
                  ? "btn__filled__disabled"
                  : "btn__filled__blue"
              }
            >
              {!isLoadingCreate ? (
                t("App.manageBusinessGroup.create.create")
              ) : (
                <CircularProgress size={21} color="inherit" />
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>

      <ModalDecision
        title={t("App.manageBusinessGroup.create.decisionTitle")}
        message={t("App.manageBusinessGroup.create.decisionMessage")}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecisionCreate}
        isOpen={isOpenModalDecisionCreate}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />
    </Dialog>
  );
};
