import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import FilterUserBG from "../../../pages/user/contacts/filterUserBG";
import FilterUser from "../../../pages/user/contacts/filterUser";
import { useState } from "react";
import { CODES } from "../../../consts/codes";
import { FilterContacts } from "../../../services/user/contactsService";
import ModalFailService from "../modalFailService";

export const FilterAdminContacts = ({
  setData,
  setFilterApply,
  unfilteredData,
  setDataStructureArray,
  userInfo,
  skeletonLine,
  setHasResults,
  isBusinessGroup,
  isLoading,
  companyList,
  setOpenModalResponse,
  setResponseContent,
  fetchData,
}) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**-
   * yup
   */
  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-zA-Z\u00C0-\u00FF\s]*$/, "Este campo solo admite letras"),
    lastName: yup
      .string()
      .matches(/^[a-zA-Z\u00C0-\u00FF\s]*$/, "Este campo solo admite letras"),
  });

  const empty = {
    lastName: "",
    name: "",
  };

  // const [companiesList, setCompaniesList] = useState(traderList);
  const [companySelected, setCompanySelected] = useState([]);
  // modal error
  const [openErrorModal, setOpenErrorModal] = useState(false);
  //constantes de bordes de campos
  const [isBorderNameFocus, setIsBorderNameFocus] = useState(false);
  const [isBorderLastnameFocus, setIsBorderLastnameFocus] = useState(false);
  const [isBorderCompanyFocus, setIsBorderCompanyFocus] = useState(false);

  /**
   * Use Form
   */

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: empty,
  });

  /**
   * Handles
   */

  const handleFilterButton = () => {
    if (watch("name") || watch("lastName") || companySelected.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleFilters = async (data) => {
    try {
      setData([]);
      setData(skeletonLine);
      let company = companyList
        .filter(
          (company) =>
            company.nameCompany !== t("App.downloadSalesForce.selectAll")
        )
        .map((commerce) => commerce.eanCompany);
      const obj = isBusinessGroup
        ? {
            name: data.name ? data.name : null,
            lastName: data.lastName ? data.lastName : null,
            country: userInfo.company.country,
            eanCompany: userInfo.company.eanCompany,
            lstEanCompanies: companySelected.length > 0 ? company : [],
            email: userInfo.email,
          }
        : {
            name: data.name ? data.name : null,
            lastName: data.lastName ? data.lastName : null,
            eanCompany: userInfo.company.eanCompany,
            email: userInfo.email,
            country: userInfo.company.country,
          };

      const filterContactsService = await FilterContacts(obj);

      if (filterContactsService.status === CODES.COD_RESPONSE_HTTP_OK) {
        const codeResponse = filterContactsService.data.responseCode;

        switch (codeResponse) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            const proccessedRows = setDataStructureArray(
              filterContactsService.data.responseMessage
            );

            setData(proccessedRows);
            setHasResults(proccessedRows.length > 0 ? false : true);

            setFilterApply({
              contactName: data.name,
              contactSurname: data.lastName,
            });
            break;
          default:
            setData([]);
            break;
        }
      } else {
        setOpenModalResponse(true);
        setHasResults(true);
        setResponseContent(filterContactsService);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log(
        "============== Error filterAdminContact.jsx function handleFilters ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *  Limpiar filtros
   */
  const handleCleanFilters = () => {
    fetchData();
    reset();
    setFilterApply(empty);
    setData(unfilteredData);
    setHasResults(false);
    setCompanySelected([]);
    setIsBorderNameFocus(false);
    setIsBorderLastnameFocus(false);
    setIsBorderCompanyFocus(false);
  };

  return (
    <Accordion
      sx={{ boxShadow: "none !important" }}
      defaultExpanded={isLoading ? false : true}
      expanded={isLoading ? false : true}
    >
      <Grid>
        <AccordionSummary className="accordion__filter">
          <TuneOutlinedIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
              transform: "rotate(90deg)",
              marginRight: "5px",
            }}
          />
          <p className="navbar__text">{t("App.listUsers.filter")}</p>
        </AccordionSummary>
      </Grid>

      <AccordionDetails sx={{ paddingRight: { lg: "1rem !important" } }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          rowSpacing={1}
        >
          <Grid
            xl={9}
            lg={8.5}
            md={8.5}
            sm={8.5}
            xs={12}
            item
            paddingLeft={{
              lg: "1rem !important",
              sm: 0,
              xs: "2rem !important",
            }}
            className="side-line-contact__bottomAlign"
          >
            <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                width={"100%"}
              >
                {isBusinessGroup ? (
                  <>
                    <FilterUserBG
                      setCompanySelected={setCompanySelected}
                      isBorderCompanyFocus={isBorderCompanyFocus}
                      setIsBorderCompanyFocus={setIsBorderCompanyFocus}
                      isBorderNameFocus={isBorderNameFocus}
                      setIsBorderNameFocus={setIsBorderNameFocus}
                      isBorderLastnameFocus={isBorderLastnameFocus}
                      setIsBorderLastnameFocus={setIsBorderLastnameFocus}
                      companiesList={companyList}
                      companySelected={companySelected}
                      control={control}
                      errors={errors}
                      t={t}
                    />
                  </>
                ) : (
                  <>
                    <FilterUser
                      isBorderNameFocus={isBorderNameFocus}
                      setIsBorderNameFocus={setIsBorderNameFocus}
                      isBorderLastnameFocus={isBorderLastnameFocus}
                      setIsBorderLastnameFocus={setIsBorderLastnameFocus}
                      control={control}
                      errors={errors}
                      t={t}
                    />
                  </>
                )}
              </Grid>
            </form>
          </Grid>

          <Grid
            item
            xl={3}
            lg={3.5}
            md={3.5}
            sm={3.5}
            xs={12}
            sx={{ paddingTop: "0px !important" }}
          >
            <Grid
              container
              direction="row"
              rowSpacing={1}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xl={6.5} lg={7} md={12} sm={12} xs={12} width={"100%"}>
                {" "}
                <Button
                  startIcon={
                    <TuneOutlinedIcon
                      className={
                        handleFilterButton()
                          ? "btn__applyFilter-reload__icon"
                          : "btn__applyFilter-reload__icon-disabled"
                      }
                    />
                  }
                  type="submit"
                  form="hook-form"
                  disabled={!handleFilterButton()}
                  className={`${
                    handleFilterButton()
                      ? "btn__applyFilter-reload"
                      : "btn__applyFilter-reload-disabled"
                  } dimentions-btn__applyFilter`}
                >
                  {t("App.listUsers.applyFilters")}
                </Button>
              </Grid>

              <Grid item xl={5} lg={5} md={12} sm={12} xs={12} width="100%">
                <Button
                  disableRipple
                  onClick={handleCleanFilters}
                  disabled={
                    !(
                      watch("name") ||
                      watch("lastName") ||
                      companySelected.length > 0
                    )
                  }
                  className={`${
                    watch("name") || watch("lastName")
                      ? "btn__deleteFilter-reload"
                      : "btn__deleteFilter-reload__disabled"
                  } dimentions-btn__cleanFilter`}
                >
                  {t("App.listUsers.cleanFilters")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
      {/* Modal para fallo de servicios */}
      <ModalFailService open={openErrorModal} onClose={setOpenErrorModal} />
    </Accordion>
  );
};
