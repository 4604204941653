import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get, useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import WhenGenReport from "./whenGenReport";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PeriodReport from "./periodReport";
import ReportConfig from "./reportConfig";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import ScopeReport from "./scopeReport";
import { ModalClearInfo } from "../../../components/common/DynamicReport/modalClearInfo";
import ViewFields from "./viewFields";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import { GenerateDynamicReportCEN } from "../../../services/user/dynamicReportCENFiscal/dynamicReportCENFiscal";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { CODES } from "../../../consts/codes";
import SelectCompaniesAndType from "./selectCompaniesAndType";

const CreatePeriodicReportCEN = () => {
  const { t } = useTranslation();
  const userInfo = useSelector(getUser);

  const { type } = useParams();

  /**
   * Use Selector
   */

  /**
   * Schema para la validación de los campos
   */
  const schema = yup.object().shape({
    status: yup.string().required(t("App.validationMessages.required")),
    generationDay: yup
      .string()
      .test(
        "not-negative-one",
        t("App.validationMessages.required"),
        (value) => value !== "-1"
      )
      .required(t("App.validationMessages.required"))
      .default("0"),
    numberOfPeriods: yup
      .number()
      .positive(t("App.validationMessages.greaterZero"))
      .required(t("App.validationMessages.required"))
      .typeError(t("App.validationMessages.required"))
      .lessThan(366, t("App.validationMessages.lessThan")),
    fileName: yup
      .string()
      .max(50, t("App.validationMessages.maxCharacters", { number: 50 }))
      .required(t("App.validationMessages.required")),

    accounts: yup.string().required(t("App.validationMessages.required")),
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      lstCompanies: [],
      frequency: "",
      fileName: "",
      accounts: "",
    },
  });

  /**
   * Use Navigate
   */
  const navigate = useNavigate();

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  //Estadoss

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [isBusinessGroup, setIsBusinessGroup] = useState(false);

  const [selectOption, setSelectOption] = useState(false);

  const [startForm, setStartForm] = useState(false);

  const [isBusinessGroupReport, setIsBusinessGroupReport] = useState(false);

  const [limitReport, setLimitReport] = useState(false);

  const [responseOK, setResponseOK] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isIndividualReport, setIsIndividualReport] = useState(true);

  const [zeroOptionSelect, setZeroOptionSelect] = useState(false);

  const [isConsolidatedReport, setIsConsolidatedReport] = useState(false);

  const [responseModalInfo, setResponseModalInfo] = useState({});
  const [openModalInfo, setModalInfo] = useState(false);

  const [periodNumber, setPeriodNumber] = useState(1);
  const [dayWeek, setDayWeek] = useState("-1");
  const [generationdayDayList, setGenerationDayList] = useState({
    label: t("App.dynamicPeriodicReport.when.noDay"),
    value: "",
  });

  const [frequency, setFrequency] = useState("0");

  const [grouping, setGrouping] = useState({
    label: t("App.dynamicPeriodicReport.when.daily"),
    value: "0",
  });

  // Mapas para almacenar la posición de la opción seleccionada
  //  en los select de alcance del reporte
  const [mapScope, setMapScope] = useState({
    carvajal: 0,
    docType: 0,
    tax: 0,
    reference: 0,
    commercial: 0,
    currency: 0,
    dateDoc: 0,
    dateEvent: 0,
  });

  // Mapas para almacenar el valor de la opción seleccionada
  //  en los select de alcance del reporte
  const [mapScopeValue, setMapScopeValue] = useState({
    carvajal: "TODOS",
    docType: "TODOS",
    tax: "TODOS",
    reference: "TODOS",
    commercial: "TODOS",
    currency: "TODOS",
    dateDoc: "TODOS",
    dateEvent: "TODOS",
  });

  // Mapas para almacenar si se chequea o no una opción
  // de visualización de campos en el reporte para emisión
  // 0: No seleccionado
  // 1: Seleccionado
  const [mapIssuerView, setMapIssuerView] = useState({
    idIssuer: 0,
    issuer: 0,
    idReceiver: 0,
    receiver: 0,
    issuerReceiver: 0,
    stateDIAN: 0,
    taxType: 0,
    taxValue: 0,
    typeWithholding: 0,
    nameReceiverDpto: 0,
    fileName: 0,
    docDate: 0,
    mailReceiver: 0,
    legalNameDoc: 0,
    docPrefix: 0,
    ndoc: 0,
    cude: 0,
    totalValue: 0,
    cufe: 0,
    deliveryCond: 0,
    valueBTax: 0,
    discountVal: 0,
    chargesVal: 0,
    advanceVal: 0,
    methodPay: 0,
    currency: 0,
    trm: 0,
    foreignCU: 0,
    ubl: 0,
    rateTax: 0,
    userGInvoice: 0,
    refNumber: 0,
    ncdNumber: 0,
    cufeInvoice: 0,
    refInvoiceNumber: 0,
    issuerB: 0,
    receiverCost: 0,
    docPack: 0,
    processDate: 0,
    processStep: 0,
    processState: 0,
    commercialState: 0,
    commercialStateDate: 0,
    descCommercialState: 0,
    causeClaim: 0,
    carvajal: 0,
    refType: 0,
    channel: 0,
  });

  // Mapas para almacenar si se chequea o no una opción
  // de visualización de campos en el reporte para recepción
  // 0: No seleccionado
  // 1: Seleccionado
  const [mapReceiverView, setMapReceiverView] = useState({
    idIssuer: 0,
    issuer: 0,
    idReceiver: 0,
    receiver: 0,
    issuerReceiver: 0,
    receiverDpto: 0,
    codeMun: 0,
    nameCity: 0,
    deliveryCond: 0,
    docType: 0,
    taxType: 0,
    docPrefix: 0,
    ndoc: 0,
    discountVal: 0,
    chargesVal: 0,
    advanceVal: 0,
    valueBTax: 0,
    rateTax: 0,
    totalValue: 0,
    methodPay: 0,
    currency: 0,
    trm: 0,
    foreignCU: 0,
    refType: 0,
    internalRef: 0,
    refNCND: 0,
    invoiceRef: 0,
    issuerB: 0,
    receiverCost: 0,
    docDate: 0,
    ubl: 0,
    cufe: 0,
    legalNameDoc: 0,
    issuingMail: 0,
    issuingMailXML: 0,
    subjectMail: 0,
    receptChannel: 0,
    state: 0,
    errorDetail: 0,
    dateErrorNoti: 0,
    notiMail: 0,
    fileName: 0,
    eventsChannel: 0,
    eventDate: 0,
    commercialState: 0,
    descCommercialState: 0,
    commercialStateDate: 0,
    causeClaim: 0,
    rejectDIAN: 0,
    userEvent: 0,
    carvajal: 0,
    stateDIAN: 0,
  });

  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");

  const [fileName, setFileName] = useState("");
  const [status, setStatus] = useState("0");

  /**
   * Función para verificar si por lo menos un campo de visualización
   * para el reporte fue seleccionado
   */
  const findOneCheck = () => {
    let find = false;
    if (type === "issuer") {
      Object.keys(mapIssuerView).forEach((key) => {
        if (mapIssuerView[key] === 1) {
          find = true;
        }
      });
    } else {
      Object.keys(mapReceiverView).forEach((key) => {
        if (mapReceiverView[key] === 1) {
          find = true;
        }
      });
    }
    return find;
  };

  /**
   * Función para crear el reporte periódico
   * @param {*} data Datos del formulario
   */
  const handleCreateReport = async (data) => {
    let findOptionSelect = false;
    findOptionSelect = findOneCheck();

    // Verificar si por lo menos un campo fue seleccionado
    if (findOptionSelect) {
      setZeroOptionSelect(false);
      setIsLoading(true);
      const emails = data.accounts.split(",");

      const cleanEmails = emails.map((email) => {
        return email.trim();
      });
      let obj = {
        typeReport: isIndividualReport ? "individual" : "consolidated",
        type: type,
        eanProvider: userInfo.company.eanCompany,
        provider: userInfo.company.companyName,
        country: userInfo.company.country,
        frequency: getGenerationFrecuency(data.frequency),
        state: data.status === "0" ? "ACTIVO" : "INACTIVO",
        generationDay:
          data.frequency === "1"
            ? data.generationDay
            : dayWeek === "-1"
            ? generationdayDayList.label
            : data.dayWeek,
        grouping: grouping.value,
        numberOfPeriods: data.numberOfPeriods,
        lstCompanies: data.lstCompanies,
        fileName: data.fileName,
        emails: cleanEmails.join(","),
        scope: mapScopeValue,
        viewFields: type === "issuer" ? mapIssuerView : mapReceiverView,
        idUser: `${userInfo.firstName.substring(
          0,
          3
        )}${userInfo.lastName.substring(0, 3)}`,
        creationUser: userInfo.firstName + " " + userInfo.lastName,
      };
      try {
        const dynamicReportService = await GenerateDynamicReportCEN(obj);

        if (dynamicReportService.status === CODES.COD_RESPONSE_SUCCESS) {
          if (
            dynamicReportService.data.responseCode ===
            CODES.COD_RESPONSE_HTTP_OK
          ) {
            setLimitReport(false);
            setResponseOK(true);
          }
          // Verificar si ya se cuenta con el límite de reportes ACTIVOS
          else if (
            dynamicReportService.data.responseCode ===
            CODES.COD_RESPONSE_ERROR_CREATE
          ) {
            setLimitReport(true);
          }
          setResponseModalInfo(dynamicReportService);
          setModalInfo(true);
        } else {
          setOpenErrorModal(true);
        }
      } catch (error) {
        setOpenErrorModal(true);
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    } else {
      setZeroOptionSelect(true);
    }
    setIsLoading(false);
  };

  /**
   *
   * Función para obtener el string correspondiente del tipo de frecuencia de generación
   *
   * @param {*} data
   * @returns string corrspondiente al tipo de frecuencia de generación
   */
  const getGenerationFrecuency = (data) => {
    switch (Number(data)) {
      default:
        return "Diario";
      case 1:
        return "Semanal";
      case 2:
        return "Mensual";
      case 3:
        return "Anual";
    }
  };

  /**
   * Función para limpiar los campos del formulario
   */
  const handleClear = () => {
    reset();
    setMapScope({
      carvajal: 0,
      docType: 0,
      tax: 0,
      reference: 0,
      commercial: 0,
      currency: 0,
      dateDoc: 0,
      dateEvent: 0,
    });
    setMapScopeValue({
      carvajal: "TODOS",
      docType: "TODOS",
      tax: "TODOS",
      reference: "TODOS",
      commercial: "TODOS",
      currency: "TODOS",
      dateDoc: "TODOS",
      dateEvent: "TODOS",
    });
    setMapIssuerView({
      idIssuer: 0,
      issuer: 0,
      idReceiver: 0,
      receiver: 0,
      issuerReceiver: 0,
      stateDIAN: 0,
      taxType: 0,
      taxValue: 0,
      typeWithholding: 0,
      nameReceiverDpto: 0,
      fileName: 0,
      docDate: 0,
      mailReceiver: 0,
      legalNameDoc: 0,
      docPrefix: 0,
      ndoc: 0,
      cude: 0,
      totalValue: 0,
      cufe: 0,
      deliveryCond: 0,
      valueBTax: 0,
      discountVal: 0,
      chargesVal: 0,
      advanceVal: 0,
      methodPay: 0,
      currency: 0,
      trm: 0,
      foreignCU: 0,
      ubl: 0,
      rateTax: 0,
      userGInvoice: 0,
      refNumber: 0,
      ncdNumber: 0,
      cufeInvoice: 0,
      refInvoiceNumber: 0,
      issuerB: 0,
      receiverCost: 0,
      docPack: 0,
      processDate: 0,
      processStep: 0,
      processState: 0,
      commercialState: 0,
      commercialStateDate: 0,
      descCommercialState: 0,
      causeClaim: 0,
      carvajal: 0,
      refType: 0,
      channel: 0,
    });
    setMapReceiverView({
      idIssuer: 0,
      issuer: 0,
      idReceiver: 0,
      receiver: 0,
      issuerReceiver: 0,
      receiverDpto: 0,
      codeMun: 0,
      nameCity: 0,
      deliveryCond: 0,
      docType: 0,
      taxType: 0,
      docPrefix: 0,
      ndoc: 0,
      discountVal: 0,
      chargesVal: 0,
      advanceVal: 0,
      valueBTax: 0,
      rateTax: 0,
      totalValue: 0,
      methodPay: 0,
      currency: 0,
      trm: 0,
      foreignCU: 0,
      refType: 0,
      internalRef: 0,
      refNCND: 0,
      invoiceRef: 0,
      issuerB: 0,
      receiverCost: 0,
      docDate: 0,
      ubl: 0,
      cufe: 0,
      legalNameDoc: 0,
      issuingMail: 0,
      issuingMailXML: 0,
      subjectMail: 0,
      receptChannel: 0,
      state: 0,
      errorDetail: 0,
      dateErrorNoti: 0,
      notiMail: 0,
      fileName: 0,
      eventsChannel: 0,
      eventDate: 0,
      commercialState: 0,
      descCommercialState: 0,
      commercialStateDate: 0,
      causeClaim: 0,
      rejectDIAN: 0,
      userEvent: 0,
      carvajal: 0,
      stateDIAN: 0,
    });
    setFrequency("0");
    setDayWeek("-1");
    setPeriodNumber("1");
    setFileName("");
  };

  /**
   * Función para regresar a la lista de reportes
   */
  const handleBackReports = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleSubmit(handleCreateReport)}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item>
          <p className="display-large__primary-one">
            {t("App.dynamicPeriodicReport.title")}
          </p>
        </Grid>

        <Grid item className="full-width">
          <Grid container direction="row" columnSpacing={2}>
            <Grid item xl={0.3} lg={0.5} sm={0.8} xs={1.8}>
              <IconButton
                className="btn__filled__blue"
                disableRipple
                onClick={handleBackReports}
                sx={{ borderRadius: "4px" }}
              >
                <ArrowBackIcon fontSize="medium" />
              </IconButton>
            </Grid>
            <Grid item className="col-start" xs={10}>
              <p className="display-small__blue-500">
                {t("App.dynamicPeriodicReport.goBack")}
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item width="100%">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item className="grid__container__fullW">
              <SelectCompaniesAndType
                control={control}
                errors={errors}
                setValue={setValue}
                setStartForm={setStartForm}
                setIsBusinessGroupReport={setIsBusinessGroupReport}
                handleCleanForm={handleClear}
                getValues={getValues}
                t={t}
                isIndividualReport={isIndividualReport}
                setIsIndividualReport={setIsIndividualReport}
                isConsolidatedReport={isConsolidatedReport}
                setIsConsolidatedReport={setIsConsolidatedReport}
                isBusinessGroup={isBusinessGroup}
                setIsBusinessGroup={setIsBusinessGroup}
                setSelectOption={setSelectOption}
              />
            </Grid>
            {selectOption &&
              (watch("lstCompanies")?.length > 0 ||
                get("lstCompanies")?.length > 0) && (
                <Grid item width="100%">
                  <Grid
                    container
                    item
                    className="companies_info_container full-width"
                    direction="column"
                    gap={2}
                  >
                    {/* Cuando generar el reporte */}
                    <Grid item container xs={12}>
                      <Accordion
                        className="accordion__filter full-width"
                        expanded
                      >
                        <AccordionSummary
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <ReceiptLongIcon
                            sx={{
                              fontSize: 20,
                              color: "#543ab4",
                            }}
                          />

                          <p
                            className="navbar__text "
                            style={{ paddingLeft: "1rem" }}
                          >
                            {t("App.dynamicPeriodicReport.when.subtitle")}
                          </p>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid item>
                            <WhenGenReport
                              setStatus={setStatus}
                              status={status}
                              t={t}
                              control={control}
                              errors={errors}
                              register={register}
                              getValues={getValues}
                              watch={watch}
                              setValue={setValue}
                              setFrequency={setFrequency}
                              frequency={frequency}
                              dayWeek={dayWeek}
                              setDayWeek={setDayWeek}
                              generationdayDayList={generationdayDayList}
                              setGenerationDayList={setGenerationDayList}
                            />
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    {/* Período de tiempo comprende el reporte */}
                    <Grid item container xs={12}>
                      <Accordion
                        className="accordion__filter full-width"
                        expanded
                      >
                        <AccordionSummary
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <QueryBuilderOutlinedIcon
                            sx={{
                              fontSize: 20,
                              color: "#543ab4",
                            }}
                          />

                          <p
                            className="navbar__text "
                            style={{ paddingLeft: "1rem" }}
                          >
                            {t("App.dynamicPeriodicReport.period.subtitle")}
                          </p>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid item>
                            <PeriodReport
                              t={t}
                              grouping={grouping}
                              control={control}
                              errors={errors}
                              register={register}
                              getValues={getValues}
                              watch={watch}
                              setValue={setValue}
                              setPeriodNumber={setPeriodNumber}
                              setStartDate={setStartDate}
                              startDate={startDate}
                              setEndDate={setEndDate}
                              endDate={endDate}
                              periodNumber={periodNumber}
                            />
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    {/* Configurar reporte */}
                    <Grid item container xs={12}>
                      <Accordion
                        className="accordion__filter full-width"
                        expanded
                      >
                        <AccordionSummary
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <SettingsOutlinedIcon
                            sx={{
                              fontSize: 20,
                              color: "#543ab4",
                            }}
                          />

                          <p
                            className="navbar__text "
                            style={{ paddingLeft: "1rem" }}
                          >
                            {t("App.dynamicPeriodicReport.config.subtitle")}
                          </p>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid item>
                            <ReportConfig
                              t={t}
                              control={control}
                              errors={errors}
                              register={register}
                              setFileName={setFileName}
                              fileName={fileName}
                              setValue={setValue}
                              watch={watch}
                            />
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    {/* Alcance del reporte */}
                    <Grid item container xs={12}>
                      <Accordion
                        className="accordion__filter full-width"
                        expanded
                      >
                        <AccordionSummary
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <TrendingUpOutlinedIcon
                            sx={{
                              fontSize: 20,
                              color: "#543ab4",
                            }}
                          />

                          <p
                            className="navbar__text "
                            style={{ paddingLeft: "1rem" }}
                          >
                            {t("App.dynamicPeriodicReport.scope.subtitle")}
                          </p>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid item>
                            <ScopeReport
                              mapScopeValue={mapScopeValue}
                              t={t}
                              control={control}
                              errors={errors}
                              register={register}
                              getValues={getValues}
                              setValue={setValue}
                              mapScope={mapScope}
                              setMapScope={setMapScope}
                              type={type}
                              setMapScopeValue={setMapScopeValue}
                            />
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    {/* Campos a visualizar */}
                    <Grid item container xs={12}>
                      <Accordion
                        className="accordion__filter full-width"
                        expanded
                      >
                        <AccordionSummary
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <RemoveRedEyeOutlinedIcon
                            sx={{
                              fontSize: 20,
                              color: "#543ab4",
                            }}
                          />

                          <p
                            className="navbar__text "
                            style={{ paddingLeft: "1rem" }}
                          >
                            {t(
                              "App.listDynamicPeriodicReport.cen.create.view.title"
                            )}
                          </p>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid item>
                            <ViewFields
                              t={t}
                              zeroOptionSelect={zeroOptionSelect}
                              control={control}
                              mapView={
                                type === "issuer"
                                  ? mapIssuerView
                                  : mapReceiverView
                              }
                              setMapView={
                                type === "issuer"
                                  ? setMapIssuerView
                                  : setMapReceiverView
                              }
                              type={type}
                            />
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Grid
                              item
                              className="u-marginT grid__container__fullW"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item>
                                  <ModalClearInfo handleClear={handleClear} />
                                </Grid>

                                <Grid item>
                                  <Button
                                    className="btn__filled__blue"
                                    type="submit"
                                  >
                                    {!isLoading ? (
                                      t("App.buttonLabels.save")
                                    ) : (
                                      <CircularProgress
                                        size={16}
                                        color="inherit"
                                      />
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
      {/* Modal para mostrar respuesta del servicio */}
      <ModalInfo
        title={
          limitReport
            ? t("App.dynamicPeriodicReport.limit")
            : responseOK
            ? t("App.dynamicPeriodicReport.success")
            : t("App.dynamicPeriodicReport.title")
        }
        responseData={responseModalInfo}
        open={openModalInfo}
        onClose={() => {
          setModalInfo(false);
          navigate(-1);
        }}
      />
      {/* Modal para mostrar cuando falla el servicio */}
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: t("App.validationMessages.systemError2"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
    </form>
  );
};

export default CreatePeriodicReportCEN;
