import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  Dialog,
} from "@mui/material";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { CODES } from "../../../consts/codes";
import ConfigurationByCommerce from "./configurationByCommerce";
import {
  EditAllyRoute,
  GetCommerceByCompany,
  GetConfigurationsListToEdit,
} from "../../../services/admin/sendAlliesService";
import ModalInfo from "../Modals/modalInfo";

const EditSendAlly = ({
  handleInfoText,
  ally,
  fullNameUser,
  handleFilters,
  handleConfigurationsArray,
}) => {
  const [open, setOpen] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [title, setTitle] = useState("");
  const [editResponse, setEditResponse] = useState({});
  const [commerceList, setCommerceList] = useState([]);
  const [sizeCommerceList, setSizeCommerceList] = useState(0);
  const [allies, setAllies] = useState([]);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [isEdit, setIsEdit] = useState(false);
  const [sizePreviousCommerceList, setSizePreviousCommerceList] = useState(0);

  /**
   * t de useTranslation para la traducción de los textos
   */
  const { t } = useTranslation();

  /**
   * Schema para el formulario de editar configuración
   */
  const schema = yup.object().shape({
    configurations: yup.array().of(
      yup.object().shape({
        commerceList: yup
          .object()
          .shape({
            eanTrader: yup.string().nullable(),
            trader: yup.string().nullable(),
          })
          .nullable()
          .required(t("App.validationMessages.required")),
        dailyPath: yup.string().required(t("App.validationMessages.required")),
        state: yup.string().required(t("App.validationMessages.required")),
      })
    ),
  });

  /**
   * useForm para resolver la validación del schema
   */
  const {
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * useFieldArray para el manejo dinámico de registros
   */
  const {
    fields: configurationsFields,
    append: configurationsAppend,
    remove: configurationsRemove,
    update: configurationsUpdate,
  } = useFieldArray({
    control,
    name: "configurations",
  });

  /**
   * Función para agregar un nuevo registro
   */
  const handleAddNewConfiguration = () => {
    setSizeCommerceList(sizeCommerceList - 1);
    const configuration = {
      commerceSelection: false,
      key: generateId(),
    };

    configurationsAppend(configuration);
  };

  /**
   *
   * Función para eliminar un registro y actualizar el selected del comercio a false
   *
   * @param {Number} index
   */
  const handleRemoveConfiguration = (index) => {
    setSizeCommerceList(sizeCommerceList + 1);

    const previousValue = getValues(`configurations.${index}.commerceList`);

    if (previousValue) {
      resetCommerceState(previousValue);
    }

    configurationsRemove(index);
  };

  /**
   *
   * Función para reiniciar el valor de los campos de las configuraciones por comercio
   *
   * @param {Number} index
   */
  const resetValuesOfFields = (index) => {
    //Reseteo los campos de ruta diaria, ruta historica, ruta recarga y estado
    setValue(`configurations.${index}.dailyPath`, "");
    setValue(`configurations.${index}.historicalPath`, "");
    setValue(`configurations.${index}.reloadPath`, "");
    setValue(`configurations.${index}.state`, "");
    setValue(`configurations.${index}.sendToDate`, "");
  };

  /**
   *
   * Función para actualizar el valor del selected del comercio a false
   *
   * @param {Object} value
   */
  const resetCommerceState = (value) => {
    const updatedCommerceList = commerceList.map((item) => {
      const eanCommerce = item.eanCommerce;

      if (eanCommerce === value.eanCommerce) {
        return { ...item, selected: false };
      }

      return item;
    });

    setCommerceList(updatedCommerceList);
  };

  /**
   *
   * Función para actualizar el selected a false de un comercio cuando es eliminado del campo
   * también se reinicia los valores de los campos de configuraciones por comercio
   *
   * @param {Object} value
   * @param {Number} index
   */
  const resetCommerce = (value, index) => {
    resetValuesOfFields(index);

    resetCommerceState(value);
  };

  /**
   *
   * Función para manejar los comercios para que no aparezcan después de ser seleccionados
   *
   * @param {Object} value
   * @param {Number} index
   */
  const handleCommerceChange = (value, index) => {
    resetValuesOfFields(index);

    // Esto permite que se actualice el array y se habiliten los campos
    // de ruta diaria y estado
    const configuration = getValues("configurations.0");
    configurationsUpdate(0, configuration);

    const updatedCommerceList = commerceList.map((item) => {
      const eanCommerce = item.eanCommerce;

      if (eanCommerce === value.eanCommerce) {
        return { ...item, selected: true };
      }

      return item;
    });

    setCommerceList(updatedCommerceList);
  };

  /**
   * Función para traer la información de la configuración a editar
   */
  const fetchConfigurationsList = async () => {
    try {
      const { country, idAllied, eanProvider } = ally;

      const obj = {
        country,
        idAllied,
        eanProvider,
      };

      const listService = await GetConfigurationsListToEdit(obj);

      if (
        listService.status === CODES.COD_RESPONSE_HTTP_OK &&
        listService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        handleSetValuesFields(listService.data.responseMessage);
      }
    } catch (error) {
      console.log(
        "============== ERROR editSendAlly.jsx function fetchConfigurationsList ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para traer los comercios en base al aliado seleccionado
   */
  const fetchCommerceByCompany = async () => {
    try {
      const { country, idAllied, eanProvider, eanTrader } = ally;

      const obj = {
        country,
        idAllied,
        eanProvider,
        lstEansTraders: [eanTrader],
      };

      const commercesService = await GetCommerceByCompany(obj);

      if (
        commercesService.status === CODES.COD_RESPONSE_HTTP_OK &&
        commercesService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = commercesService.data.responseMessage;

        let commercesList = responseMessage.map((item) => {
          return {
            commerceName: item.companyName,
            eanCommerce: item.eanCompany,
          };
        });

        setCommerceList(commercesList);
        setSizeCommerceList(commercesList.length);
      }
    } catch (error) {
      console.log(
        "============== ERROR editSendAlly.jsx function fetchCommerceByCompany ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para generar un id genérico para los registros de configuraciones por comercio
   *
   * @returns id genérico
   */
  const generateId = () => {
    const id = Date.now().toString(32) + Math.random().toString(32);

    return id;
  };

  /**
   * Función para abrir el modal y agregar el primer registro
   */
  const handleOpen = () => {
    // reset();
    fetchConfigurationsList();
    fetchCommerceByCompany();
    // handleAddNewConfiguration();
    setIsEdit(true);
    setOpen(true);
  };

  /**
   * Función para colocar los valores correspondientes de los campos
   */
  const handleSetValuesFields = (ally) => {
    reset();
    const allies = ally.lstTraders;

    setAllies(allies);
    setSizePreviousCommerceList(allies.length);
    if (ally.lstTraders.length > 0) {
      const allyListObj = allies.map((ally) => {
        return {
          commerceList: {
            eanTrader: ally.eanTrader,
            trader: ally.trader,
          },
          dailyPath: ally.dailyPath,
          historicalPath: ally.historicalPath ? ally.historicalPath : "",
          reloadPath: ally.informationReloadPath
            ? ally.informationReloadPath
            : "",
          state: ally.state,
          selected: true,
          sendToDate: ally.sendToDate
        };
      });

      allyListObj.map((item) => configurationsAppend(item));
    }
  };

  /**
   * Función para reiniciar los valores del state de commerceList
   */
  const resetProductList = () => {
    const resetList = commerceList.map((item) => {
      return { ...item, selected: false };
    });

    setCommerceList(resetList);
    setSizeCommerceList(resetList.length);
  };

  /**
   * Función para cerrar el modal y reiniciar sus campos
   */
  const handleClose = async () => {
    await resetProductList();
    setIsEdit(false);
    setOpen(false);
    reset();
  };

  /**
   * Función para actualizar el state de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  /**
   * useEffect para actualizar el state de isDesktop en base al tamaño de la ventana del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Función para renderizar el componente del título del contenedor
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   *
   * Función para editar una configuración
   *
   * @param {Object} data
   */
  const handleEditAlly = async (data) => {
    try {
      const obj = {
        country: ally.country,
        eanProvider: ally.eanProvider,
        provider: ally.provider,
        idAllied: ally.idAllied,
        allied: ally.alliedName,
        lstTraders: handleConfigurationsArray(data.configurations, allies),
        user: fullNameUser,
      };
      setLoadingEdit(true);

      const editAllyService = await EditAllyRoute(obj);

      if (
        editAllyService.status === CODES.COD_RESPONSE_HTTP_OK &&
        editAllyService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        handleFilters(obj);
        setTitle(t("App.validationMessages.correct"));
      } else {
        setTitle(t("App.validationMessages.error"));
      }
      setEditResponse(editAllyService);
      setOpenModalInfo(true);
      setLoadingEdit(false);
    } catch (error) {
      console.log(
        "============== ERROR editSendAlly.jsx function handleEditSendAlly ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
      </IconButton>
      <Grid item>
        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "85%",
                height: "auto",
                maxWidth: "1284px",
                boxShadow: "none",
                borderRadius: "22px",
                padding: "2rem",
              },
            },
          }}
        >
          <>
            <DialogTitleContainer onClose={handleClose}>
              <Grid container direction="column" rowSpacing={1}>
                <Grid item className="display-large__primary-one">
                  {t("App.adminSendAllies.modals.titleEdit")} {ally.provider}{" "}
                  {"-"}
                  {ally.alliedName}
                </Grid>
              </Grid>
            </DialogTitleContainer>

            <DialogContent className="only-scroll-y">
              <form
                id="form-configurations"
                onSubmit={handleSubmit(handleEditAlly)}
              >
                <Grid container item direction="column" spacing={1} gap={2}>
                  {!isDesktop && handleInfoText()}

                  <Grid item alignItems="center">
                    <h3 className="heading__text-grey">
                      {t("App.adminSendAllies.modals.configPerCommerce")}
                    </h3>
                  </Grid>

                  <Grid
                    item
                    container
                    className="permissionBox"
                    width={"100%"}
                    sx={{ overflowX: "hidden" }}
                    mt={2}
                  >
                    <ConfigurationByCommerce
                      configurationsFields={configurationsFields}
                      sizePreviousCommerceList={sizePreviousCommerceList}
                      handleAddNewConfiguration={handleAddNewConfiguration}
                      handleRemoveConfiguration={handleRemoveConfiguration}
                      resetCommerce={resetCommerce}
                      handleCommerceChange={handleCommerceChange}
                      setDisabledButton={setDisabledButton}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      commerceList={commerceList}
                      sizeCommerceList={sizeCommerceList}
                      setSizeCommerceList={setSizeCommerceList}
                      errors={errors}
                      isEdit={isEdit}
                      watch={watch}
                    />
                  </Grid>
                </Grid>
              </form>
            </DialogContent>

            <DialogActions>
              <Grid
                container
                direction={isDesktop ? "row" : "column-reverse"}
                // className="col-end"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                marginTop={2}
              >
                <Grid
                  item
                  xs={isDesktop ? 0 : 12}
                  className={isDesktop ? "" : "full-width"}
                >
                  <Button
                    className="btn__filled__gradient full-width"
                    onClick={handleClose}
                  >
                    {t("App.buttonLabels.cancel")}
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={isDesktop ? 0 : 12}
                  className={isDesktop ? "" : "full-width"}
                >
                  <Button
                    className={
                      disabledButton
                        ? "btn__applyFilter"
                        : "btn__applyFilter-disabled"
                    }
                    type="submit"
                    form="form-configurations"
                    startIcon={!loadingEdit ? <CheckIcon /> : <></>}
                    disabled={!disabledButton}
                  >
                    {!loadingEdit ? (
                      t("App.editUser.confirmButton")
                    ) : (
                      <CircularProgress size={21} color="inherit" />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>

            {isDesktop && handleInfoText()}
          </>
        </Dialog>
      </Grid>
      <ModalInfo
        open={openModalInfo}
        title={title}
        responseData={
          editResponse?.data?.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST || editResponse === undefined
            ? ""
            : editResponse
        }
        onClose={() => {
          setOpen(false);
          setOpenModalInfo(false);
        }}
      />
    </>
  );
};

export default EditSendAlly;
