import { React, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, IconButton, TextField } from "@mui/material";
import { LanguageSwitcher } from "../../components/common/languageSwitcher";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import logo from "../../assets/common/logo.svg";
import { useTranslation } from "react-i18next";
import { RecoverPasswordService } from "../../services/auth/authServices";
import { CODES } from "../../consts/codes";
import ModalInfo from "../../components/common/Modals/modalInfo";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import RecoverPasswordImage from "./recoverPasswordImage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const RecoverPassword = () => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isBorderFocus, setIsBorderFocus] = useState(false);
  const [emailRoute, setEmailRoute] = useState("");

  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t("App.validationMessages.email"))
      .required(t("App.validationMessages.required")),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Función para habilitar el botón de Enviar Correo
   */

  const handleButtonDisabled = () => {
    if (watch("email")) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Funcion para saber si el value de un Textfield esta vacio para cambiar el estilo del borde
   */
  const handleChangeBorder = (values) => {
    if (values) {
      setIsBorderFocus(true);
    } else {
      setIsBorderFocus(false);
    }
  };

  /**
   * Función para retroceder a la página anterior
   */
  const handleBackLogin = () => {
    navigate(-1);
  };

  const handleRecoverPassword = async (data) => {
    try {
      setIsLoading(true);
      const email = data.email;
      setEmailRoute(email);
      const obj = {
        email: email,
      };
      const service = await RecoverPasswordService(obj);

      setResponseData(service);
      setIsOpenModal(true);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
    if (responseData.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
      navigate(`/validateCredentials/email/${emailRoute}`);
      window.location.reload();
    }
  };

  return (
    <div className="recoverPassword__wrap">
      <div className="recoverPassword__leftSide">
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems="center"
          justify="center"
        >
          <RecoverPasswordImage />
        </Grid>
      </div>
      <Grid className="recoverPassword__rightSide" xl={10}>
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          justify="center"
          sx={{ margin: "0px !important" }}
        >
          <Grid className="recoverPassword__head-container">
            <Grid item xs={7} sm={6} md={4} lg={10} xl={12}>
              <div className="login__logo">
                <img
                  src={logo}
                  alt="Logo Prescriptiva"
                  className="logo-CENAnalíticos"
                />
              </div>
            </Grid>
            <Grid item xs={3.8} sm={3} md={3} lg={4} xl={2.8}>
              <LanguageSwitcher />
            </Grid>
          </Grid>
          <Grid item sx={{ paddingTop: "0px !important" }}>
            <Grid
              item
              className="back-containerPassword"
              sx={{ paddingBottom: "3rem !important" }}
            >
              <Grid container direction="row">
                <Grid item sx={{ paddingRight: "2rem" }}>
                  <IconButton
                    className="btn__filled__blue"
                    disableRipple
                    onClick={handleBackLogin}
                    sx={{ borderRadius: "8px" }}
                  >
                    <ArrowBackIcon fontSize="medium" />
                  </IconButton>
                </Grid>
                <Grid item className="col-start">
                  <p className="recoverPassword__form-backText">
                    {t("App.login.rightPanel.backButton")}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ width: "100% !important" }}>
              <p className="recoverPassword__title">
                {t("App.recoverPassword.rightPanel.mainTitle")}
              </p>
            </Grid>
            <Grid item>
              <p className="recoverPassword__subtitle">
                {t("App.recoverPassword.rightPanel.subtitle")}
              </p>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center" }}>
              <Grid className="recoverPassword__form ">
                <Grid item>
                  <p className="recoverPassword__instructions">
                    {t("App.recoverPassword.rightPanel.instructionText")}
                  </p>
                </Grid>
                <form onSubmit={handleSubmit(handleRecoverPassword)}>
                  <Grid sx={{ paddingBottom: "4rem !important" }}>
                    <Controller
                      defaultValue=""
                      name="email"
                      control={control}
                      sx={{ padding: "0px !important" }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          className={
                            errors.email
                              ? "recoverPassword__form-input--error"
                              : isBorderFocus
                              ? "recoverPassword__form-input--focus"
                              : "recoverPassword__form-input"
                          }
                          disableUnderline
                          type="email"
                          name="email"
                          id="email"
                          label={t("App.login.rightPanel.email")}
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -6px",
                              alignItems: "center",
                              alignContent: "center",
                              padding: "4px",
                              transformOrigin: "-45px -40px",
                              "&.Mui-focused": {
                                color: "#8C8D9D", // Mismo valor para mantener alineación
                              },
                            },
                          }}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleChangeBorder(e.target.value);
                          }}
                          InputProps={{
                            autoComplete: "off !important",
                            sx: {
                              height: "100% !important",
                              width: "100% !important",
                              "&:-webkit-autofill": {
                                backgroundColor: "white !important",
                                boxShadow: "0 0 0 100px white inset !important",
                                borderRadius: "8px",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {errors.email && (
                      <p className="recoverPassword__form-errorText">
                        {errors.email.message}
                      </p>
                    )}
                  </Grid>
                  <Grid>
                    <p>
                      <button
                        disabled={handleButtonDisabled()}
                        className={
                          handleButtonDisabled()
                            ? "recoverPassword__form-button--disabled"
                            : "recoverPassword__form-button"
                        }
                      >
                        {!isLoading ? (
                          t("App.recoverPassword.rightPanel.button")
                        ) : (
                          <CircularProgress size={16} color="inherit" />
                        )}
                      </button>
                    </p>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalInfo
        title={t("App.recoverPassword.title")}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />
    </div>
  );
};
