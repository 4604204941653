import { Grid, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Controller } from "react-hook-form";

const FilterUser = ({
  isBorderNameFocus,
  setIsBorderNameFocus,
  isBorderLastnameFocus,
  setIsBorderLastnameFocus,
  control,
  errors,
  t,
}) => {
  /**
   * Funcion para cambiar el borde del campo "Nombre"
   */
  const handleChangeNameBorder = (values) => {
    if (values) {
      setIsBorderNameFocus(true);
    } else {
      setIsBorderNameFocus(false);
    }
  };
  /**
   * Funcion para cambiar el borde del campo "Apellido"
   */
  const handleChangeLastnameBorder = (values) => {
    if (values) {
      setIsBorderLastnameFocus(true);
    } else {
      setIsBorderLastnameFocus(false);
    }
  };
  return (
    <>
      <Grid item lg={6} md={6} sm={12} xs={12} width={"100%"}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          width={"100%"}
        >
          <Grid item width={"100%"}>
            <Controller
              control={control}
              name={"name"}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  inputProps={{
                    style: {
                      margin: "-5px 0 0 0",
                      fontSize: 12,
                    },
                  }}
                  sx={{
                    label: {
                      height: "100% !important",
                      width: "100% !important",
                      fontFamily: "Roboto !important",
                      fontSize: "14px !important",
                      translate: "-5px -7px",
                      alignItems: "center !important",
                    },
                  }}
                  className={
                    errors.name
                      ? "select-contact__filters__errors__xs"
                      : isBorderNameFocus
                      ? "select-contact__filters"
                      : "select-contact__filters__grey"
                  }
                  label={t("App.userDetail.name")}
                  onChange={(e) => {
                    onChange(e);
                    handleChangeNameBorder(e.target.value);
                  }}
                />
              )}
            />
          </Grid>

          {errors && errors.name && (
            <Grid
              item
              sx={{
                margin: "1px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.name.message}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} width={"100%"}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          width={"100%"}
        >
          <Grid item width={"100%"}>
            <Controller
              control={control}
              name={"lastName"}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label={t("App.userDetail.lastName")}
                  defaultValue={null}
                  inputProps={{
                    style: {
                      margin: "-5px 0 0 0",
                      fontSize: 12,
                    },
                  }}
                  sx={{
                    label: {
                      height: "100% !important",
                      width: "100% !important",
                      fontFamily: "Roboto !important",
                      fontSize: "14px !important",
                      translate: "-5px -7px",
                      alignItems: "center !important",
                    },
                  }}
                  className={
                    errors.name
                      ? "select-contact__filters__errors__xs"
                      : isBorderLastnameFocus
                      ? "select-contact__filters"
                      : "select-contact__filters__grey"
                  }
                  onChange={(e) => {
                    onChange(e);
                    handleChangeLastnameBorder(e.target.value);
                  }}
                  value={value}
                />
              )}
            />
          </Grid>

          {errors && errors.lastName && (
            <Grid
              item
              sx={{
                margin: "1px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.lastName.message}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FilterUser;
