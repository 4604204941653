import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import LeftArrow from "@mui/icons-material/KeyboardArrowLeft";
import RightArrow from "@mui/icons-material/KeyboardArrowRight";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const PermissionsRegisters = ({
  permissionsFields,
  handleAddNewPermission,
  handleRemovePermission,
  handleProductChange,
  control,
  getValues,
  productsList,
  errors,
  loadingProductsAndRoles,
  isBusinessGroup,
  rolesMap,
  companiesMap,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  /**
   * Handle para actualizar el tamaño de la pantalla
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  // Efecto para actualizar isDesktop cuando la ventana cambia de tamaño
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Handle para actualizar la pagina cuando se navega a la siguiente
   */
  const nextPage = () => {
    if (currentPage < permissionsFields.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  /**
   * Handle para actualizar la pagina cuando se navega a la anterior
   */
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  /**
   * Handle para el disabled del boton de agregar producto
   */
  const handleAddFieldMobile = () => {
    return permissionsFields.length >= productsList.length;
  };

  /**
   * Handle para el disabled del boton de eliminar producto
   */
  const handleRemoveFieldMobile = (index) => {
    if(permissionsFields[index]){
      return index < 0 || permissionsFields.length === 1 || permissionsFields[index].editDisabled;
    }else{
      return index < 0 || permissionsFields.length === 1;
    }
  };

  /**
   * Handle para navegar cuando se eliminan productos
   */
  const handleRemovePermissionAndNavigate = (index) => {
    handleRemovePermission(index);
    if (currentPage === permissionsFields.length - 1 && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  /**
   * Handle para navegar cuando se agregan productos
   */
  const handleAddNewPermissionAndNavigate = () => {
    handleAddNewPermission();
    setCurrentPage(permissionsFields.length);
  };

  const getRolesList = (index) => {
    const productListValue = getValues(`permissions.${index}.productList`);
    const idMenuOption = productListValue ? JSON.parse(productListValue).idMenuOption : "";
    const result = rolesMap.get(idMenuOption);
    const rolesList = result || [];
    rolesList.sort((a, b) => a.label.localeCompare(b.label));
    return rolesList;
  };

  const getCompaniesList = (index) => {
    const productListValue = getValues(`permissions.${index}.productList`);
    const idMenuOption = productListValue ? JSON.parse(productListValue).idMenuOption : "";
    const result = companiesMap.get(idMenuOption);
    const companiesList = result || [];
    return companiesList;
  };

  return (
    <>
      {isDesktop ? (
        <Grid container direction="row" spacing={2} className="scroll-y">
          {permissionsFields.map((permission, index) => {
            let productListValue = getValues(`permissions.${index}.productList`);
            const isFirstProduct = index === 0;
            return (
              <Grid item container alignItems="center" spacing={2} key={permission.key}>
                <Grid item xs={11.5} lg={2.7}>
                  <FormControl className="full-width">
                    <InputLabel className="modalCreateOptions__form__inputLabel">
                    {isFirstProduct
                      ? t("App.createUser.product") // Placeholder para el primer producto
                      : t("App.createUser.availableProducts")} {/* Placeholder para productos agregados */}
                    </InputLabel>

                    <Controller
                      defaultValue=""
                      name={`permissions.${index}.productList`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          onChange={(e) => {
                            onChange(e);
                            handleProductChange(e, value, index);
                          }}
                          value={value}
                          disabled={permission.productSelection || loadingProductsAndRoles || permission.editDisabled}
                          className={`${
                            errors?.permissions?.[index]?.productList
                              ? "modalEditRoles__form-input--error"
                              : "modalCreateRoles__form-input"
                          } full-width`}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              sx={{
                                "&.MuiInputAdornment-positionEnd": {
                                  marginRight: "5%",
                                },
                              }}
                            >
                              {loadingProductsAndRoles && (
                                <CircularProgress sx={{ color: "#4DCAFA" }} size={15} />
                              )}
                            </InputAdornment>
                          }
                        >
                          <MenuItem disabled value="">
                            {t("App.createUser.selectOption")}
                          </MenuItem>
                          {productsList.map((product) => (
                            <MenuItem
                              key={product.value}
                              name={product.label}
                              value={product.value}
                              disabled={!product.isActive}
                              sx={{
                                display: product.selected ? "none" : "",
                              }}
                            >
                              {product.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />

                    <FormHelperText
                      sx={{
                        margin: "-8px 0 1px 0px",
                        color: "#e52900",
                        fontSize: 10,
                      }}
                    >
                      {errors?.permissions?.[index]?.productList?.message || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={11.5} lg={3.1}>
                  <FormControl className="full-width">
                    <Controller
                      control={control}
                      defaultValue={[]}
                      name={`permissions.${index}.companyList`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Autocomplete
                          noOptionsText={t("App.listUsers.noOptions")}
                          className={`${
                            errors?.permissions?.[index]?.companyList
                              ? "modalEditRoles__form-input--error"
                              : "modalCreateRoles__form-input"
                          } full-width`}
                          disableCloseOnSelect
                          disabled={
                            permission.editDisabled 
                              ? true // Si editDisabled es true, el campo está deshabilitado
                              : getValues(`permissions.${index}.productList`) 
                                ? isBusinessGroup 
                                  ? false 
                                  : true 
                                : true
                          }
                          id="checkboxes-tags-commerce"
                          options={productListValue ? getCompaniesList(index) : []}
                          multiple
                          limitTags={1}
                          isOptionEqualToValue={(option, value) => option.eanCompany === value.eanCompany}
                          getOptionLabel={(option) => option.renameCompany}
                          value={value}
                          renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.eanCompany}>
                              <Checkbox
                                icon={icon}
                                className="autoComplete__checkbox"
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.renameCompany}
                            </li>
                          )}
                          onChange={(e, values) => onChange(values)}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              maxRows={1}
                              label={t("App.createUser.company")}
                              sx={{
                                label: {
                                  fontFamily: "Roboto !important",
                                  fontSize: "13px !important",
                                  translate: "0px -5px",
                                  transformOrigin: "-45px -10px",
                                  "&.Mui-focused": {
                                    color: "#8C8D9D",
                                  },
                                },
                              }}
                              {...params}
                            />
                          )}
                          renderTags={(value, getTagProps) => {
                            const numTags = value.length;
                            const limitTags = 1;
                            return (
                              <>
                                {value.slice(0, limitTags).map((option, index) => (
                                  <Chip
                                    className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                    sx={{ width: "40%" }}
                                    key={index}
                                    size="small"
                                    label={`${option.country} - ${option.nameCompany}`}
                                  />
                                ))}
                                {numTags > limitTags && ` +${numTags - limitTags}`}
                              </>
                            );
                          }}
                        />
                      )}
                    />

                    <FormHelperText
                      sx={{
                        margin: "-8px 0 1px 0px",
                        color: "#e52900",
                        fontSize: 10,
                      }}
                    >
                      {errors?.permissions?.[index]?.companyList?.message || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={11.5} lg={2.7}>
                  <FormControl className="full-width">
                    <InputLabel className="modalCreateOptions__form__inputLabel">
                      {t("App.createUser.role")}
                    </InputLabel>

                    <Controller
                      defaultValue=""
                      name={`permissions.${index}.roleList`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          onChange={onChange}
                          value={value}
                          className={`${
                            errors?.permissions?.[index]?.roleList
                              ? "modalEditRoles__form-input--error"
                              : "modalCreateRoles__form-input"
                          } full-width`}
                          disabled={
                            permission.editDisabled 
                              ? true // Si editDisabled es true, el campo está deshabilitado
                              : getValues(`permissions.${index}.productList`) 
                                ? false 
                                : true
                          }
                        >
                          <MenuItem disabled value="">
                            {t("App.createUser.selectOption")}
                          </MenuItem>
                          {productListValue &&
                            getRolesList(index).map((role) => (
                              <MenuItem key={role.value} name={role.label} value={role.value}>
                                {role.label}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />

                    <FormHelperText
                      sx={{
                        margin: "-8px 0 1px 0px",
                        color: "#e52900",
                        fontSize: 10,
                      }}
                    >
                      {errors?.permissions?.[index]?.roleList?.message || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={11.5} lg={2.5}>
                  <FormControl className="full-width">
                    <InputLabel className="modalCreateOptions__form__inputLabel">
                      {t("App.createUser.status")}
                    </InputLabel>

                    <Controller
                      defaultValue=""
                      name={`permissions.${index}.status`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          onChange={onChange}
                          value={value}
                          className={`${
                            errors?.permissions?.[index]?.status
                              ? "modalEditRoles__form-input--error"
                              : "modalCreateRoles__form-input"
                          } full-width`}
                          disabled={
                            permission.editDisabled 
                              ? true // Si editDisabled es true, el campo está deshabilitado
                              : getValues(`permissions.${index}.productList`) 
                                ? false 
                                : true
                          }
                        >
                          <MenuItem disabled value="">
                            {t("App.createUser.selectOption")}
                          </MenuItem>
                          <MenuItem value={1}>{t("App.createRole.active")}</MenuItem>
                          <MenuItem value={0}>{t("App.createRole.inactive")}</MenuItem>
                        </Select>
                      )}
                    />

                    <FormHelperText
                      sx={{
                        margin: "-8px 0 1px 0px",
                        color: "#e52900",
                        fontSize: 10,
                      }}
                    >
                      {errors?.permissions?.[index]?.status?.message || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={1} sx={{ marginTop: "0.6%" }}>
                  {index === 0 ? (
                    <IconButton
                      className="addPermission__button"
                      onClick={handleAddNewPermission}
                      disabled={permissionsFields.length >= productsList.length}
                    >
                      <AddIcon sx={{ fontSize: 22 }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="addPermission__delete"
                      onClick={() => handleRemovePermissionAndNavigate(index)}
                      disabled={permission.editDisabled}
                    >
                      <ClearIcon sx={{ fontSize: 22 }} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid container direction="column" paddingRight={2} spacing={2}>

          <Grid item container justifyContent="space-between" alignItems="center" mt={1} mb={1}>
            <Grid item sm={6} sx={{ width: "100%" }}>
              <IconButton
                sx={{ width: "100% !important" }}
                className={
                  handleAddFieldMobile()
                    ? "mapping-page__addField__disabled"
                    : "mapping-page__addField"
                }
                onClick={() => handleAddNewPermissionAndNavigate()}
                disabled={handleAddFieldMobile()}
              >
                <AddIcon sx={{ fontSize: 27 }} />
                {t("App.createUser.addProduct")}
              </IconButton>
            </Grid>
            <Grid item sm={6} sx={{ width: "100%" }}>
              <IconButton
              sx={{ width: "100% !important" }}
                className={
                  handleRemoveFieldMobile(currentPage)
                    ? "mapping-page__removeField__disabled"
                    : "mapping-page__removeField"
                }
                onClick={() => handleRemovePermissionAndNavigate(currentPage)}
                disabled={handleRemoveFieldMobile(currentPage)}
              >
                <ClearIcon sx={{ fontSize: 27 }} />
                {t("App.createUser.deleteProduct")}
              </IconButton>
            </Grid>
          </Grid>
          
          {permissionsFields.map((permission, index) => {
            let productListValue = getValues(`permissions.${index}.productList`);
            return (
              index === currentPage && (
                <Grid item container alignItems="center" spacing={4} key={permission.key}>
                  <Grid item xs={12} lg={2.7}>
                    <FormControl fullWidth>
                      <InputLabel className="modalCreateOptions__form__inputLabel">
                        {t("App.createUser.product")}
                      </InputLabel>

                      <Controller
                        defaultValue=""
                        name={`permissions.${index}.productList`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={(e) => {
                              onChange(e);
                              handleProductChange(e, value, index);
                            }}
                            value={value}
                            disabled={permission.productSelection || loadingProductsAndRoles || permission.editDisabled}
                            className={`${
                              errors?.permissions?.[index]?.productList
                                ? "modalEditRoles__form-input--error"
                                : "select__filters__full"
                            } full-width`}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{
                                  "&.MuiInputAdornment-positionEnd": {
                                    marginRight: "5%",
                                  },
                                }}
                              >
                                {loadingProductsAndRoles && (
                                  <CircularProgress sx={{ color: "#4DCAFA" }} size={15} />
                                )}
                              </InputAdornment>
                            }
                          >
                            <MenuItem disabled value="">
                              {t("App.createUser.selectOption")}
                            </MenuItem>
                            {productsList.map((product) => (
                              <MenuItem
                                key={product.value}
                                name={product.label}
                                value={product.value}
                                disabled={!product.isActive}
                                sx={{
                                  display: product.selected ? "none" : "",
                                }}
                              >
                                {product.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />

                      <FormHelperText
                        sx={{
                          margin: "-8px 0 1px 0px",
                          color: "#e52900",
                          fontSize: 10,
                        }}
                      >
                        {errors?.permissions?.[index]?.productList?.message || ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={3.1}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        defaultValue={[]}
                        name={`permissions.${index}.companyList`}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors?.permissions?.[index]?.companyList
                                ? "modalEditRoles__form-input--error"
                                : "select__filters__full"
                            } full-width`}
                            disableCloseOnSelect
                            disabled={
                              permission.editDisabled 
                                ? true // Si editDisabled es true, el campo está deshabilitado
                                : getValues(`permissions.${index}.productList`) 
                                  ? isBusinessGroup 
                                    ? false 
                                    : true 
                                  : true
                            }
                            id="checkboxes-tags-commerce"
                            options={productListValue ? getCompaniesList(index) : []}
                            multiple
                            limitTags={1}
                            isOptionEqualToValue={(option, value) => option.eanCompany === value.eanCompany}
                            getOptionLabel={(option) => option.renameCompany}
                            value={value}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.eanCompany}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.renameCompany}
                              </li>
                            )}
                            onChange={(e, values) => onChange(values)}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                maxRows={1}
                                label={t("App.createUser.company")}
                                sx={{
                                  label: {
                                    fontFamily: "Roboto !important",
                                    fontSize: "13px !important",
                                    translate: "0px -5px",
                                    transformOrigin: "-45px -10px",
                                    "&.Mui-focused": {
                                      color: "#8C8D9D",
                                    },
                                  },
                                }}
                                {...params}
                              />
                            )}
                            renderTags={(value, getTagProps) => {
                              const numTags = value.length;
                              const limitTags = 1;
                              return (
                                <>
                                  {value.slice(0, limitTags).map((option, index) => (
                                    <Chip
                                      className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                      sx={{ width: "50%" }}
                                      key={index}
                                      size="small"
                                      label={`${option.country} - ${option.nameCompany}`}
                                    />
                                  ))}
                                  {numTags > limitTags && ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                          />
                        )}
                      />

                      <FormHelperText
                        sx={{
                          margin: "-8px 0 1px 0px",
                          color: "#e52900",
                          fontSize: 10,
                        }}
                      >
                        {errors?.permissions?.[index]?.companyList?.message || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={2.7}>
                  <FormControl fullWidth>
                    <InputLabel className="modalCreateOptions__form__inputLabel">
                      {t("App.createUser.role")}
                    </InputLabel>

                    <Controller
                      defaultValue=""
                      name={`permissions.${index}.roleList`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          onChange={onChange}
                          value={value}
                          className={`${
                            errors?.permissions?.[index]?.roleList
                              ? "modalEditRoles__form-input--error"
                              : "select__filters__full"
                          } full-width`}
                          disabled={
                            permission.editDisabled 
                              ? true // Si editDisabled es true, el campo está deshabilitado
                              : getValues(`permissions.${index}.productList`) 
                                ? false 
                                : true
                          }
                        >
                          <MenuItem disabled value="">
                            {t("App.createUser.selectOption")}
                          </MenuItem>
                          {productListValue &&
                            getRolesList(index).map((role) => (
                              <MenuItem key={role.value} name={role.label} value={role.value}>
                                {role.label}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />

                    <FormHelperText
                      sx={{
                        margin: "-8px 0 1px 0px",
                        color: "#e52900",
                        fontSize: 10,
                      }}
                    >
                      {errors?.permissions?.[index]?.roleList?.message || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={2.5}>
                  <FormControl fullWidth>
                    <InputLabel className="input-component__label">
                      {t("App.createUser.status")}
                    </InputLabel>

                    <Controller
                      defaultValue=""
                      name={`permissions.${index}.status`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          onChange={onChange}
                          value={value}
                          className={`${
                            errors?.permissions?.[index]?.status
                              ? "modalEditRoles__form-input--error"
                              : "select__filters__full"
                          } `}
                          disabled={
                            permission.editDisabled 
                              ? true // Si editDisabled es true, el campo está deshabilitado
                              : getValues(`permissions.${index}.productList`) 
                                ? false 
                                : true
                          }
                        >
                          <MenuItem disabled value="">
                            {t("App.createUser.selectOption")}
                          </MenuItem>
                          <MenuItem value={1}>{t("App.createRole.active")}</MenuItem>
                          <MenuItem value={0}>{t("App.createRole.inactive")}</MenuItem>
                        </Select>
                      )}
                    />

                    <FormHelperText
                      sx={{
                        margin: "-8px 0 1px 0px",
                        color: "#e52900",
                        fontSize: 10,
                      }}
                    >
                      {errors?.permissions?.[index]?.status?.message || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            ));
          })}
          
          <Grid container justifyContent="space-between" alignItems="center" mt={2}>
            <Grid item style={{ visibility: currentPage === 0 ? 'hidden' : 'visible' }} pl={1}>
              <IconButton className="mapping-page__btn" onClick={previousPage} disabled={currentPage === 0} sx={{ fontSize: 27 }}>
                <LeftArrow />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                {t("App.createUser.productNo")} {currentPage + 1}
              </Typography>
            </Grid>
            <Grid item style={{ visibility: currentPage === permissionsFields.length - 1 ? 'hidden' : 'visible' }} pr={2.9}>
              <IconButton className="mapping-page__btn" onClick={nextPage} disabled={currentPage === permissionsFields.length - 1} sx={{ fontSize: 27 }}>
                <RightArrow />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PermissionsRegisters;
