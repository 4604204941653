import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";
/**
 * Función que permite obtener un usuario a partir del mail
 * @param data Email con el cual se buscara al usuario en el sistema
 */
export async function GetUserByEmail(data) {
  const token = await getToken();

  return axios({
    method: "GET",
    url: `users/mails/${data.email}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función que trae el token de login temacore
 * @param {} data
 * @returns token de teamcore
 */
export async function GetTeamcoreToken(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data,
    url: `auths/tokens`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: token,
    },
  });
}
