import { React, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { CODES } from "../../../consts/codes";
import { useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import LoopIcon from "@mui/icons-material/Loop";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import MUIDataTable from "mui-datatables";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import {
  GetSalesForcesDownloadedFiles,
  GetTradersList,
  DownloadSalesForcesDownloadedFiles,
  DownloadSalesForceService,
} from "../../../services/user/salesForceServices";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import ModalInfo from "../../../components/common/Modals/modalInfo";
import CircularProgress from "@mui/material/CircularProgress";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import FileState from "../../../components/common/fileState";
import FilterBusinessGroupWB from "../../../components/common/Filters/businessGroup/filterWithoutButtons";
import { getIsBusinessGroup } from "../../../parts/businessGroup/businessGroupSlice";
import { Chip } from "@mui/material";

/**
 * Const
 */
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const DownloadSalesForce = () => {
  /**
   * Use Translation
   *
   */
  const { t } = useTranslation();

  /**
   * Use Selector
   */

  //Usuario
  const userInfo = useSelector(getUser);

  //Grupo empresarial
  const isBusinessGroup = useSelector(getIsBusinessGroup);

  /**
   * Use State
   */

  //Manejo de tabla
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  //Respuesta a descarga
  const [responseLoadSalesForce, setResponseLoadSalesForce] = useState({});

  //Cargas visuales
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  //Comerciantes para filtro
  const [tradersList, setTradersList] = useState([]);
  const [traderSelected, setTraderSelected] = useState([]);

  //Carga de resultados de tabla
  const [foundResults, setFoundResults] = useState(false);
  const [errorDownload, setErrorDownload] = useState(false);

  //Modal de carga
  const [openSuccessLoadModal, setOpenSuccessLoadModal] = useState(false);

  //Grupo empresarial
  const [showDownload, setShowDownload] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "startDate",
      label: t("App.downloadSalesForce.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "endDate",
      label: t("App.downloadSalesForce.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.downloadSalesForce.user"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "company",
      label: t("App.catalogue.table.company"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "file",
      label: t("App.downloadCatalogue.downloadTable.downloadFile"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              <a
                className="default-text__linkBlue__sm"
                onClick={(event) =>
                  handleDownloadFile(value.fileName, value.pathFileName)
                }
              >
                {" "}
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.salesForce.status"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FileState
            value={value}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
  ];

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,

    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    selectableRows: "none",
    responsive: "simple",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,

    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   *
   * Función que se acciona cuando se cambia el valor del autocomplete de compañías para cambiar el valor del state de traderSelected que es lo que se muestra seleccionado en el autcomplete
   * También funciona para seleccionar todos los valores cuando se selecciona la opción de 'Seleccionar todos'
   *
   * @param {Event} event
   * @param {Values} values comercios
   * @param {String} reason tipo de selección; selectOption, removeOption
   * @param {Object} detail opción elegida
   */
  const onFileChange = (event, values, reason, detail) => {
    let includeSelectAll = false;

    for (const trader of values) {
      if (trader.trader === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    if (includeSelectAll && reason === "selectOption") {
      setTraderSelected(tradersList);
    } else {
      setTraderSelected(values);
    }

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.trader === t("App.downloadSalesForce.selectAll")
      ) {
        setTraderSelected([]);
      }
    }
  };

  /**
   *
   * Función para descargar el(los) archivo(s) encontrados en base a lo seleccionado en el autocomplete de compañías
   *
   * @param {String} fileName nombre del archivo
   * @param {String} pathFileName ubicación del archivo
   */
  const handleDownloadFile = async (fileName, pathFileName) => {
    const obj = {
      fileName: fileName,
      pathFileName: pathFileName,
    };

    try {
      const fileService = await DownloadSalesForcesDownloadedFiles(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para mostrar un mensaje de error o éxito de la descarga en un modal
   *
   * @param {Event} event
   */
  const handleDownload = async (event) => {
    event.preventDefault();

    try {
      const ean = traderSelected.filter((trader) => {
        if (trader.ean) {
          return trader.ean;
        }
      });

      const eanTraders = traderSelected.find(
        (trader) => trader.trader === t("App.downloadSalesForce.selectAll")
      )
        ? []
        : ean.map((item) => {
            return item.ean;
          });
      const cleanSelectAll = selectedCompanies.filter(
        (company) => company.label !== t("App.downloadSalesForce.selectAll")
      );

      const obj = {
        userName: userInfo.firstName + " " + userInfo.lastName,
        emailUser: userInfo.email,
        eanProvider: userInfo.company.eanCompany,
        country: userInfo.company.country,
        provider: userInfo.company.companyName,
        idUser: `${userInfo.firstName.substring(
          0,
          3
        )}${userInfo.lastName.substring(0, 3)}`,
        lstEanTrader: eanTraders,
        lstCompanies: cleanSelectAll,
      };

      setIsLoadingDownload(true);

      const downloadService = await DownloadSalesForceService(obj);

      if (downloadService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          downloadService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          setResponseLoadSalesForce(downloadService);
          setOpenSuccessLoadModal(true);

          setTraderSelected([]);
        } else {
          setErrorDownload(true);
          setResponseLoadSalesForce(downloadService);
          setOpenSuccessLoadModal(true);
        }
      }

      setRefresh(!refresh);
      setIsLoadingDownload(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * filterOptions para el autocomplete de compañías
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * Use Effect para mostrar por default el componente de carga si no es grupo
   */
  useEffect(() => {
    if (!isBusinessGroup) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [isBusinessGroup]);

  /**
   * useEffect para traer la información a mostrar en la tabla
   */
  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        setLoading(true);
        setIsLoadingRefresh(true);
        setData([]);

        if (mounted && userInfo) {
          const obj = {
            country: userInfo.company.country,
            eanProvider: userInfo.company.eanCompany,
            emailLoad: userInfo.email,
            idFileType: 8,
          };

          const filesService = await GetSalesForcesDownloadedFiles(obj);

          if (filesService.status === CODES.COD_RESPONSE_HTTP_OK) {
            setLoading(false);
            if (filesService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
              const responseMessage = filesService.data.data;

              if (responseMessage.length > 0) {
                setFoundResults(true);
                //Retornando el objeto creado para la tabla
                const ProcessRows = responseMessage.map((item, idx) => {
                  return {
                    id: !item.idDownload ? "  " : item.idDownload,
                    startDate: !item.starDate
                      ? "  "
                      : moment(item.starDate).format("YYYY-MM-DD hh:mm"),
                    endDate: !item.endDate
                      ? "  "
                      : moment(item.endDate).format("YYYY-MM-DD hh:mm"),
                    user: !item.userLoad ? "  " : item.userLoad,
                    company: !item.company ? "" : item.company,
                    file: !item.fileName
                      ? "  "
                      : {
                          fileName: item.fileName,
                          pathFileName: item.pathFileName,
                        },
                    state: !item.state ? " " : item.state,
                  };
                });
                setData(ProcessRows);
              } else {
                setFoundResults(false);
              }
            }
          }
          setIsLoadingRefresh(false);
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();

    return () => {
      mounted = false;
    };
  }, [refresh, userInfo]);

  /**
   * useEffect para traer la información a usarse en el autocomplete de compañías para la busqueda de los archivos a descargar
   */
  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        if (mounted && userInfo) {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
          };

          const tradersService = await GetTradersList(obj);

          if (tradersService.status === CODES.COD_RESPONSE_HTTP_OK) {
            if (
              tradersService.data.responseCode ===
              CODES.COD_RESPONSE_SUCCESS_REQUEST
            ) {
              const responseMessage = tradersService.data.data;
              const traderList = responseMessage.map((trader) => {
                return { trader: trader.nameCompany, ean: trader.eanCompany };
              });

              const completeList = [
                { trader: t("App.downloadSalesForce.selectAll") },
                ...traderList,
              ];

              setTradersList(completeList);
            }
          }
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();

    return () => {
      mounted = false;
    };
  }, [refresh, userInfo]);

  return (
    <>
      {isBusinessGroup && (
        <FilterBusinessGroupWB
          title={t("App.manageBusinessGroup.filter.title3")}
          setShowDownload={setShowDownload}
          setSelectedCompanies={setSelectedCompanies}
        />
      )}

      <br />
      <br />

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <ModalInfo
          title={t("App.downloadSalesForce.modalTitle")}
          responseData={responseLoadSalesForce}
          open={openSuccessLoadModal}
          onClose={() => {
            setOpenSuccessLoadModal(false);
          }}
        />

        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <p className="subheading__text-black">
                {t("App.salesForce.textInfo")}
              </p>
            </Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Autocomplete
                    disabled={tradersList.length === 0}
                    noOptionsText={t("App.validationMessages.noMatchFilter")}
                    className="autoComplete__downloadSalesForce"
                    isOptionEqualToValue={(option, value) =>
                      option.ean === value.ean
                    }
                    multiple
                    id="checkboxes-tags-demo"
                    value={traderSelected}
                    options={tradersList}
                    disableCloseOnSelect
                    onChange={onFileChange}
                    getOptionLabel={(option) => option.trader}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.ean}>
                        <Checkbox
                          icon={icon}
                          className="autoComplete__checkbox"
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.trader}
                      </li>
                    )}
                    renderTags={(value) => {
                      const numTags = value.length;
                      const limitTags = 2;

                      return (
                        <>
                          {value.slice(0, limitTags).map((option, index) => (
                            <Chip
                              className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                              sx={{
                                width: "30%",
                              }}
                              key={index}
                              size="small"
                              label={option.trader}
                            />
                          ))}

                          {numTags > limitTags && ` +${numTags - limitTags}`}
                        </>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        label={t(
                          "App.downloadSalesForce.autoCompletePlaceHolder"
                        )}
                        sx={{
                          label: {
                            fontFamily: "Roboto !important",
                            fontSize: "14px !important",
                            translate: "0px -5px",
                            transformOrigin: "-45px -10px",
                            "&.Mui-focused": {
                              color: "#8C8D9D",
                            },
                          },
                        }}
                        {...params}
                      />
                    )}
                  sx={{width:{sm:"47rem !important"} }}/>
                </Grid>

                <Grid item>
                  <Button
                  sx={{minWidth:{sm:"103px !important"},
                  margin:{sm:"15% !important"}}}
                    disabled={
                      traderSelected.length > 0 && showDownload ? false : true
                    }
                    disableRipple
                    className={
                      traderSelected.length > 0 && showDownload
                        ? "btn__filled__blue"
                        : "btn__filled__disabled"
                    }
                    onClick={handleDownload}
                  >
                    {!isLoadingDownload ? (
                      t("App.buttonLabels.download")
                    ) : (
                      <CircularProgress size={21} color="inherit" />
                    )}
                  </Button>
                </Grid>

                <Grid item>
                  {isLoadingDownload && (
                    <label className={"label-Primary__small"}>
                      {t("App.salesForce.paragraphUpload")}
                    </label>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          className="u-marginB u-marginT"
          spacing={2}
        >
          <Grid item>
            {foundResults && (
              <Button
              sx={{width:{sm:"134px"},
                   borderRadius:"6px"}}
                className="btn__outlined__blue"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                {!isLoadingRefresh ? (
                  t("App.buttonLabels.refresh")
                ) : (
                  <CircularProgress size={21} color="inherit" />
                )}
              </Button>
            )}
          </Grid>
        </Grid>

        {foundResults && (
          <>
            <Grid item className="u-marginB">
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
                </Grid>

                <Grid item>
                  <p className="display-small__primary-one">
                    {t("App.downloadSalesForce.subtitle")}
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="u-marginB" style={{ width: "100%" }}>
              {loading ? (
                <MUIDataTable
                  className="dataTable__salesForce"
                  data={data}
                  columns={columns}
                  options={skeletonOptions}
                />
              ) : (
                <MUIDataTable
                  className="dataTable__salesForce"
                  data={data}
                  columns={columns}
                  options={options}
                />
              )}
            </Grid>
          </>
        )}

        {!foundResults && <NoInfoComponent type="download" />}
      </Grid>
    </>
  );
};
