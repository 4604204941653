import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import NewPasswordLeftSide from "../../assets/common/newPasswordImage.svg";

const recoverPasswordImage = () => {
  //const { t } = useTranslation();

  return (
    <Grid className="newPasswordImage" xl={12}>
      <img
        src={NewPasswordLeftSide}
        alt="newPassword Image"
        className="newPasswordImage_img"
      />
    </Grid>
  );
};

export default recoverPasswordImage;
