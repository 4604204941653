import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormControl from "@mui/material/FormControl";
import { useMediaQuery } from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  ApplyFiltersProduct,
  GetActiveProduct,
} from "../../../services/admin/adminServices";
import { CODES } from "../../../consts/codes";
import { useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import {
  FilterUsersClient,
  GetRolesClientByProduct,
} from "../../../services/user/consultRolesService";

export const FilterAdminClientUsers = ({
  setDataStructureArray,
  setData,
  setIsLoadingData,
  setNoContent,
  unfilteredData,
  setFilters,
  data,
}) => {
  const [activeProducts, setActiveProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roleList, setRoleList] = useState([]);

  const matches = useMediaQuery("(min-width:1670px)");

  /**
   * Info of the user who logs into the website
   */
  const userInfo = useSelector(getUser);

  /**
   * t of useTranslation to translate
   */
  const { t } = useTranslation();

  /**
   * useForm to resolver the schema validation
   */
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({});

  /**
   * usEffect to fetch active products and role list for the states of activeProducts and roleList
   */
  useEffect(() => {
    /**
     * Function to get the list of active products and set the data in state of activeProducts
     */
    async function handleActiveProducts() {
      try {
        const obj = {
          eanCompany: userInfo?.company?.eanCompany,
          country: userInfo?.company?.country,
        };

        const productsListService = await ApplyFiltersProduct(obj);

        if (
          productsListService.status === CODES.COD_RESPONSE_HTTP_OK &&
          productsListService.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const products = productsListService.data.responseMessage.map(
            (product) => {
              return {
                label: product.menuOptionName,
                value: product.idMenuOption,
              };
            }
          );

          setActiveProducts(products);
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    /**
     * Function to get the list of roles and set the data in state of roleList
     */
    async function handleRoles() {
      try {
        const obj = {
          eanCompany: userInfo?.company?.eanCompany,
          country: userInfo?.company?.country,
        };

        const roleService = await GetRolesClientByProduct(obj);

        if (
          roleService.status === CODES.COD_RESPONSE_HTTP_OK &&
          roleService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage = roleService.data.responseMessage;

          const rolesList = responseMessage.map((role) => ({
            label: role.nameRole,
            value: role.idRole,
          }));

          setRoleList(rolesList);
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    handleActiveProducts();
    handleRoles();
  }, [userInfo]);

  /**
   * Function to clear the fields of the filter form
   */
  const handleCleanFilters = () => {
    setValue("product", null);
    setValue("role", null);
    setValue("status", "2");

    setNoContent(false);
    setData(unfilteredData);
  };

  /**
   *
   * Function to filter the data of the table by eanCompany, idMenuOption, idRole and/or state
   *
   * @param {Object} data
   */
  const handleFilters = async (data) => {
    try {
      const obj = {
        country: userInfo?.company?.country,
        eanCompany: userInfo?.company?.eanCompany,
        idMenuOption: data.product ? data.product.value : null,
        idRole: data.role ? data.role.value : null,
        state: data.status !== "2" ? data.status : null,
      };

      //setFilters(obj);
      setData([]);

      setIsLoadingData(true);

      const filterService = await FilterUsersClient(obj);

      if (
        filterService.status === CODES.COD_RESPONSE_HTTP_OK &&
        filterService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const response = filterService.data.responseMessage;

        const formatedData = setDataStructureArray(response);

        setNoContent(false);

        setData(formatedData);
      } else {
        setNoContent(true);
      }

      setIsLoadingData(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <Accordion defaultExpanded={true} expanded={true} disableGutters  sx={{ boxShadow: "none !important" }}>
      <Grid item className="">
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header" className="accordion__filter-padding"
>
          <TuneOutlinedIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
              transform: "rotate(90deg)",
              marginLeft: "8px"
            }}
          />
          <p className="navbar__text" style={{ paddingLeft: "1.5rem" }}>
            {t("App.catalogue.filterName")}
          </p>
        </AccordionSummary>
      </Grid>

      <AccordionDetails className="">
        <Grid container alignItems="center" gap={1}>
          <Grid
            xl={9.8}
            lg={8.7}
            md={9}
            sm={8.4}
            item
            className="side-line-contact__verticalPadding"
          >
            <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
              <Grid
              container
              rowSpacing={6}
              spacing={2}
              paddingRight={3}
              >
                {/* -----------  PRODUCTO ------------    */}
                <Grid item xs={12} xl={4} lg={4} md={3} sm={12}>
                  <Controller
                    name="product"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className={
                          matches
                            ? errors.product
                              ? "select__filters__errors__large"
                              : "select__filters__full"
                            : errors.product
                            ? "select__filters__errors"
                            : "select__filters__full"
                        }
                        options={activeProducts}
                        renderInput={(params) => {
                          return (
                            <TextField
                              label={t("App.listUsers.product")}
                              {...params}
                              InputLabelProps={{
                                className: 'input-component__label'
                              }}
                              onChange={onChange}
                            />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>

                {/* -----------  ESTADO ------------    */}

                <Grid item xs={12} xl={4} lg={4} md={3} sm={12}>
                  <Controller
                    className="select__filters"
                    control={control}
                    name="status"
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <InputLabel  className="input-component__label">      
                          {t("App.adminClientUsers.table.state")}
                        </InputLabel>
                        <Select
                          value={value}
                          labelId="labelState"
                          label={t("App.adminClientUsers.table.state")}
                          className={
                            matches
                              ? errors.status
                                ? "select__filters__errors__large"
                                : "select__filters__full"
                              : errors.status
                              ? "select__filters__errors"
                              : "select__filters__full"
                          }
                          onChange={onChange}
                        >
                          <MenuItem value={"1"}>
                            {t("App.adminCompanies.filters.active")}
                          </MenuItem>

                          <MenuItem value={"0"}>
                            {t("App.adminCompanies.filters.inactive")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>

                {/* -------- ROL -------  */}
                <Grid item xs={12} xl={4} lg={4} md={3} sm={12}>
                  <Controller
                    name="role"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className={
                          matches
                            ? errors.role
                              ? "select__filters__errors__large"
                              : "select__filters__full"
                            : errors.role
                            ? "select__filters__errors"
                            : "select__filters__full"
                        }
                        options={roleList}
                        renderInput={(params) => {
                          return (
                            <TextField
                              label={t("App.adminClientUsers.table.nameRole")}
                              {...params}
                              InputLabelProps={{
                                className: 'input-component__label'
                              }}
                              onChange={onChange}
                            />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid ml={2} xs={12} xl={2} lg={3} md={2} sm={3} item>
            <Grid container direction="row" gap={2}>
              <Grid item xs={12} xl={6} lg={6} md={12} sm={12}>
                <Button
                  fullWidth
                  startIcon={
                    <TuneOutlinedIcon
                      sx={{
                        fontSize: 20,
                        color: "white",
                        transform: "rotate(90deg)",
                      }}
                    />
                  }
                  disableRipple
                  // disabled={watch("product") || watch("role") ? false : true}
                  className={
                    "btn__applyFilter"
                    // watch("product") || watch("role")
                    //   ? "btn__applyFilter"
                    //   : "btn__applyFilter-disabled"
                  }
                  type="submit"
                  form="hook-form"
                >
                  {t("App.listUsers.applyFilters")}
                </Button>
              </Grid>

              <Grid item xs={12} xl={5} lg={5} md={12} sm={12}>
                <Button
                  fullWidth
                  disableRipple
                  className="btn__deleteFilter"
                  onClick={handleCleanFilters}
                >
                  {t("App.listUsers.cleanFilters")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
