import React from "react";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import LanguageIcon from "@mui/icons-material/Language";
import SvgIcon from "@mui/material/SvgIcon";
import ArrowDown from "@mui/icons-material/KeyboardArrowDown";

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const arrowBack = <ArrowDown fontSize="20px" />;

  return (
    <div sx={{ width: "100%" }}>
      <Select
        defaultValue="es"
        className="language_selector"
        IconComponent={ArrowDown}
        sx={{
          "&::before": {
            border: "1px solid white",
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid black",
            borderRadius: "5px 5px 0 0",
          },
        }}
        renderValue={(value) => {
          return (
            <Box sx={{ display: "flex", gap: 1 }}>
              <SvgIcon color="white" style={{ paddingTop: "2px" }}>
                <LanguageIcon sx={{ fontSize: "20px" }} />
              </SvgIcon>
              {value === "en" ? "English" : "Español"}
            </Box>
          );
        }}
        value={i18n.language}
        onChange={(e) => {
          i18n.changeLanguage(e.target.value);
          window.location.reload();
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              width: "2rem !important",
              height: "11rem !important",
              "& .MuiMenuItem-root": {
                padding: 1.5,
              },
            },
          },
        }}
      >
        <MenuItem value="es">Español</MenuItem>
        <MenuItem value="en">English</MenuItem>
      </Select>
    </div>
  );
};
