import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CODES } from "../../../consts/codes";
import { UpdateContact } from "../../../services/user/contactsService";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { REGEXP } from "../../../consts/regexp";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
const ModalEditContactBG = ({
  contact,
  setOpenModalEdit,
  open,
  currentIndex,
  setResponseContent,
  setResponseTitle,
  setOpenModalResponse,
  data,
  setData,
  setDataStructureArray,
  setTypeOfAction,
  setOpenModalDecision,
  setUnfilteredData,
  country,
  userName,
  companyList,
}) => {
  /**
   * Title Container component
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 34,
              top: 30,
              color: "#553AB6"
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * UseForm
   */

  const schema = yup.object().shape({
    contactName: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(REGEXP.ONLY_LETTERS, t("App.validationMessages.onlyString")),
    contactSurname: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(REGEXP.ONLY_LETTERS, t("App.validationMessages.onlyString")),
    contactPhone: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .matches(
        REGEXP.LETTERS_AND_NUMBERS,
        t("App.validationMessages.onlyAlphanumeric")
      )
      .nullable(),
    contactEmail: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .email(t("App.validationMessages.email"))
      .required(t("App.validationMessages.required")),
    contactPosition: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .matches(REGEXP.ONLY_LETTERS, t("App.validationMessages.onlyString"))
      .nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { company: { eanCompany: "", renameCompany: "" } },
  });

  /**
   * Use State
   */

  //Sets loading state when editing
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  /**
   * Use Effect
   */

  /**
   * Establece la información del contacto actual en el formulario
   */
  useEffect(() => {
    setValue("contactName", contact.name);
    setValue("contactSurname", contact.lastName);
    setValue("contactEmail", contact.email);
    setValue("contactPhone", contact.phone ? contact.phone : null);
    setValue("contactPosition", contact.position ? contact.position : null);
    setValue("company", {
      eanCompany: contact.eanCompany ? contact.eanCompany : null,
      renameCompany: contact.companyName
        ? contact.companyName
        : companyList[0].renameCompany,
    });
  }, [contact]);

  /**
   * Resetear el formulario cuando se cierre el modal
   */
  useEffect(() => {
    switch (open) {
      case false:
        reset();
        break;
    }
  }, [open]);

  /**
   * Handles
   */

  /**
   * Editar el contacto acutal
   * @param {*} data información actualizada del contacto
   */
  const handleEditContact = async (data) => {
    try {
      setIsLoadingEdit(true);
      setTypeOfAction("edit");

      const obj = {
        pk: contact.pk,
        name: data.contactName,
        lastName: data.contactSurname,
        lastEmail: contact.email,
        newEmail: data.contactEmail,
        phone: data.contactPhone ? data.contactPhone : "",
        position: data.contactPosition ? data.contactPosition : "",
        eanCompany: data.company.eanCompany,
        country,
        userName,
        companyName: data.company.renameCompany,
      };

      const editContactService = await UpdateContact(obj);

      setResponseTitle("");

      if (editContactService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          editContactService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          handleUpdateContactVisually(obj, contact.currentIndex);
        }

        //Correo ya diligenciado
        if (
          editContactService.data.responseCode ===
          CODES.COD_RESPONSE_ERROR_UPDATE
        ) {
          //Abro modal decision
          setResponseContent(editContactService.data.responseMessage);
          setOpenModalDecision(true);
        } else if (
          editContactService.data.responseCode ===
          CODES.COD_RESPONSE_ERROR_DELETE
        ) {
          setResponseTitle(t("App.adminContacts.modals.changeCompany"));
          setResponseContent(editContactService);
          setOpenModalResponse(true);
          handleClose();
        } else if (editContactService.data.responseMessage === "Cambios guardados exitosamente" ){
          editContactService.data.responseMessage = " ";
          //Abro success
          setResponseTitle(setTitle(editContactService));
          setResponseContent(editContactService);
          setOpenModalResponse(true);
          handleClose();
        }else{
          //Abro error
          setResponseTitle(setTitle(editContactService.data.responseCode));
          setResponseContent(editContactService);
          setOpenModalResponse(true);
          handleClose();
        }
      } else {
        setResponseContent(editContactService);
        setOpenModalResponse(true);
      }

      setIsLoadingEdit(false);
    } catch (error) {
      console.log(
        "============== Error modalEditContact.jsx function handleEditContact ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Cerrar el modal de editar contacto
   */
  const handleClose = () => {
    setOpenModalEdit(false);
  };

  /**
   * Desactiva el botón de confirmación de cambios según el estado de los campos obligatorios
   * @returns true = deshabilitado, false = habilitado
   */
  const disableEditContact = () => {
    if (
      watch("contactName") &&
      watch("contactSurname") &&
      watch("contactEmail") &&
      watch("company")
    ) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Establece el título para la información modal según el valor del código de respuesta
   * @param {*} responseCode código de respuesta de la petición
   * @returns título para la información modal
   */
  const setTitle = (responseCode) => {
    let title = "";
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        title = t("App.validationMessages.success");
        break;
      case CODES.COD_RESPONSE_ERROR_UPDATE:
        title = t("App.adminContacts.modals.case1001");
        break;
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        title = t("App.adminContacts.modals.case1005");
        break;
      default:
        break;
    }
    return title;
  };

  // ----------------- AUTOCOMPLETE ----------------
  /**
   * Opciones para el autocomplete
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * Función que resetea los valores de los campos
   */
  const resetValuesWhenClear = () => {
    setValue("company", null);
  };

  /**
   *
   * Función que maneja los cambios sobre campo company
   * ejecutando también cambios sobre campo ean según el caso
   *
   * @param {Object} values empresa seleccionada
   */
  const handleChangeCompany = (values) => {
    if (values) {
      if (!getValues("company")) {
        setValue("company", null);
      }
      const company = values;
      setValue("company", {
        eanCompany: company.ean,
        renameCompany: company.renameCompany,
      });
    }
  };

  // ----------------------------

  /**
   * Actualiza el contacto editado actual en la tabla de datos
   * @param {*} contactData información del contacto editado
   * @param {*} index índice del contacto editado
   */
  const handleUpdateContactVisually = (contactData, index) => {
    setData([]);
    const newRow = setDataStructureArray([
      { ...contactData, email: contactData.newEmail },
    ]);

    let newDataArray = data;
    newDataArray[index] = newRow[0];
    const finalArray = newDataArray.map((item) => item);
    setData(finalArray);
    setUnfilteredData(finalArray);
  };

  return (
    <Dialog
      maxWidth={"md"}
      onClose={handleClose}
      open={open}
      aria-labelledby="customized-dialog-title"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            boxShadow: "none",
            borderRadius: "22px",
            padding: "4rem 1rem 0rem",
          },
        },
      }}
    >
      <DialogTitleContainer onClose={handleClose} sx={{paddingBottom: "0px !important" }}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item className="display-large__primary-one">
            {t("App.adminContacts.modals.edit")}
          </Grid>
          <Grid item>
            <Button
              className="btn__outlined__blue"
              disabled
              sx={{ border: "none !important", padding: "0 !important" }}
              startIcon={<PersonOutlineOutlinedIcon />}
            >
              {t("App.adminContacts.modals.info")}
            </Button>
          </Grid>
        </Grid>
      </DialogTitleContainer>
      <DialogContent>
        <Grid container direction="column" sx={{ paddingTop: 1 }}>
          <form id="hook-contact" onSubmit={handleSubmit(handleEditContact)}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="row"
                rowSpacing={5}
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Grid item width={"100%"} sx={{paddingTop: "1.5rem"}}>
                       <Controller
                        name="company"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            filterOptions={filterOptions}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.company
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            } full-width`}
                            options={companyList}
                            getOptionLabel={(option) => option.renameCompany}
                            isOptionEqualToValue={(option, value) => {
                              return (
                                option.renameCompany === value.renameCompany
                              );
                            }}
                            renderOption={(props, option) => (
                              <li {...props} key={option.ean}>
                                {option.renameCompany}
                              </li>
                            )}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t("App.listUsers.enterprise")}
                                  sx={{
                                    label : {
                                      fontFamily: "Roboto !important",
                                      fontSize: "13px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D !important",
                                      },
                                    },
                                  }}
                                  {...params}
                                />
                              );
                            }}
                            onInputChange={(event, value, reason) => {
                              if (reason === "clear") {
                                resetValuesWhenClear();
                              }
                            }}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              handleChangeCompany(values, reason);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    container
                    direction="row"
                    rowSpacing={5}
                    width={"100%"}
                  >
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Controller
                        control={control}
                        name={"contactName"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactName
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            sx={{
                              input: {
                                margin: "2px 0 10px 0 !important",
                                padding: "8px 4px 7px 14px !important"
                              },
                              label : {
                                fontFamily: "Roboto !important",
                                fontSize: "13px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D !important",
                                },
                              },
                            }}
                            helperText={
                              errors.contactName
                                ? errors.contactName.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            label={t("App.createUser.name")}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        control={control}
                        name={"contactSurname"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactSurname
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            sx={{
                              input: {
                                margin: "2px 0 10px 0 !important",
                                padding: "8px 4px 7px 14px !important"
                              },
                              label : {
                                fontFamily: "Roboto !important",
                                fontSize: "13px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D !important",
                                },
                              },
                            }}
                            helperText={
                              errors.contactSurname
                                ? errors.contactSurname.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            label={t("App.createUser.lastname")}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                    
                <Grid item xs={12} sm={12} md={4} lg={4}>
                   <Controller
                        control={control}
                        name={"contactPhone"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactPhone
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            sx={{
                              input: {
                                margin: "2px 0 10px 0 !important",
                                padding: "8px 4px 7px 14px !important"
                              },
                              label : {
                                fontFamily: "Roboto !important",
                                fontSize: "13px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D !important",
                                },
                              },
                            }}
                            helperText={
                              errors.contactPhone
                                ? errors.contactPhone.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            label={t("App.createUser.cellPhone")}
                            // onChange={onChange}
                            onChange={(e) => {
                              onChange(e);
                              setValue(
                                "contactPhone",
                                watch("contactPhone")
                                  ? watch("contactPhone")
                                  : null
                              );
                            }}
                          />
                        )}
                      />
                </Grid>
            </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="row"
                spacing={2}
                rowSpacing={5}
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Controller
                        control={control}
                        name={"contactEmail"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactEmail
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            sx={{
                              input: {
                                margin: "2px 0 10px 0 !important",
                                padding: "8px 4px 7px 14px !important"
                              },
                              label : {
                                fontFamily: "Roboto !important",
                                fontSize: "13px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D !important",
                                },
                              },
                            }}
                            helperText={
                              errors.contactEmail
                                ? errors.contactEmail.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            label={t("App.createUser.email")}
                            onChange={onChange}
                          />
                        )}
                      />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Controller
                        control={control}
                        name={"contactPosition"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactPosition
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            sx={{
                              input: {
                                margin: "2px 0 10px 0 !important",
                                padding: "8px 4px 7px 14px !important"
                              },
                              label : {
                                fontFamily: "Roboto !important",
                                fontSize: "13px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D !important",
                                },
                              },
                            }}
                            helperText={
                              errors.contactPosition
                                ? errors.contactPosition.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            label={t("App.adminContacts.modals.position")}
                            // onChange={onChange}
                            onChange={(e) => {
                              onChange(e);
                              setValue(
                                "contactPosition",
                                watch("contactPosition")
                                  ? watch("contactPosition")
                                  : null
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          className="col-flex"
          columnGap={4}
          rowSpacing={2}
          sx={{ padding: "2.5%" }}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={1}
            md={1}
            lg={1}
            order={{ xs: 2, sm: 1, md: 1, lg: 1 }}
          >
            <Button
              className="btn__filled__gradient-contact"
              onClick={handleClose}
            >
              {t("App.createUser.cancelButton")}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={2}
            order={{ xs: 1, sm: 2, md: 2, lg: 2 }}
          >
            <Button
              type="submit"
              form="hook-contact"
              disabled={disableEditContact()}
              className={
                disableEditContact()
                  ? "btn__filled__disabled-contact"
                  : "btn__filled__blue-contact"
              }
            >
              {!isLoadingEdit ? (
                t("App.editUser.confirmButton")
              ) : (
                <CircularProgress size={21} color="inherit" />
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditContactBG;
