import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../../components/common/languageSwitcher";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBoxOutlined";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import CheckIcon from "@mui/icons-material/CheckCircle";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import ReCAPTCHA from "react-google-recaptcha";
import logo from "../../assets/common/logo.svg";
import InfoOutlinedIcon from "@mui/icons-material/Error";
import { REGEXP } from "../../consts/regexp";
import {
  changePasswordService,
  LoginService,
  LogoutService,
} from "../../services/auth/authServices";
import jwt_decode from "jwt-decode";
import { CODES } from "../../consts/codes";
import { useNavigate } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import ModalInfo from "../../components/common/Modals/modalInfo";
import { useDispatch } from "react-redux";
import { resetUserStatus } from "../userSlice";
import ChangePaswordImage from "./changePasswordImage";
import { Link } from "react-router-dom";

export const ChangePasswordFT = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isBorderPasswordFocus, setIsBorderPasswordFocus] = useState(false);
  const [isBorderConPasswordFocus, setIsBorderConPasswordFocus] =
    useState(false);
  const [validCaptcha, setValidCaptcha] = useState(true);

  const [showPasswordIcon, setShowPasswordIcon] = useState(false);
  const [showConfirmPasswordIcon, setShowConfirmPasswordIcon] = useState(false);

  const { t } = useTranslation();

  const icon = (
    <CheckBoxOutlineBlankIcon sx={{ fontSize: "24px !important" }} />
  );
  const checkedIcon = <CheckBoxIcon sx={{ fontSize: "24px !important" }} />;

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t("App.validationMessages.required"))
      .min(8, t("App.validationMessages.invalidPassword"))
      .max(15, t("App.validationMessages.invalidPassword"))
      .test(
        "upperCase",
        t("App.validationMessages.invalidPassword"),
        function (value) {
          if (!!value) {
            const schema = yup.string().matches(/^(?=.*?[A-Z])/);
            return schema.isValidSync(value);
          }
          return true;
        }
      )
      .test(
        "lowerCase",
        t("App.validationMessages.invalidPassword"),
        function (value) {
          if (!!value) {
            const schema = yup.string().matches(/(?=.*?[a-z])/);
            return schema.isValidSync(value);
          }
          return true;
        }
      )
      .test(
        "number",
        t("App.validationMessages.invalidPassword"),
        function (value) {
          if (!!value) {
            const schema = yup.string().matches(/(?=.*?[0-9])/);
            return schema.isValidSync(value);
          }
          return true;
        }
      )
      .test(
        "specialChar",
        t("App.validationMessages.invalidPassword"),
        function (value) {
          if (!!value) {
            const schema = yup.string().matches(/(?=.*?[#?!@$%^&*-])/);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
    confirmPassword: yup
      .string()
      .required(t("App.validationMessages.required"))
      .oneOf([yup.ref("password"), null], t("App.validatePassword.noMatch")),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  let checklist;

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const [valuesConfirmPassword, setValuesConfirmPassword] = useState({
    confirmPassword: "",
    showConfirmPassword: false,
  });

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  const [acceptTerms, setacceptTerms] = useState(true);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState("");

  const [respCode, setRespCode] = useState(null);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState({});

  let captcha = useRef(null);

  const onChange = () => {
    if (captcha.current.getValue()) {
      setValidCaptcha(true);
    }
  };

  /**
   * Función para validar que la nueva contraseña
   * no sea igual a la anterior
   * @param {*} data // Credeciales del usuario y contraseña nueva
   * @returns // true si la contraseña es diferente, false si es igual
   */
  const verifyPassword = (data) => {
    console.log("DataCambio", data);
    if (data.oldPassword === data.newPassword) {
      return false;
    }
    return true;
  };

  /**
   * Función para cerrar el modal de información
   */
  const handleAction = () => {
    setIsOpenModalInfo(false);
  };

  const handleChangePassBorder = (values) => {
    if (values) {
      setIsBorderPasswordFocus(true);
    } else {
      setIsBorderPasswordFocus(false);
    }
  };

  const handleChangeConPassBorder = (values) => {
    if (values) {
      setIsBorderConPasswordFocus(true);
    } else {
      setIsBorderConPasswordFocus(false);
    }
  };

  const handleShowPassword = (values) => {
    if (values) {
      setShowPasswordIcon(true);
    } else {
      setShowPasswordIcon(false);
    }
  };
  /**
   * Función para cambiar la contraseña del usuario
   * solo si la contraseña nueva es diferente a la anterior
   * sino se muestra un modal informandole al usuario
   * @param {*} data // Información del formulario
   */
  const handleChangePasswordFT = async (data) => {
    console.log("Data", localStorage);
    if (captcha.current.getValue()) {
      try {
        setIsLoading(true);
        const tmpPassword = localStorage.getItem("tmpPassword");
        const tmpEmail = localStorage.getItem("tmpEmail");

        const obj = {
          email: tmpEmail,
          oldPassword: tmpPassword,
          newPassword: data.password,
        };

        // Validar que la contraseña nueva sea diferente a la anterior
        if (verifyPassword(obj)) {
          const service = await changePasswordService(obj);

          if ([CODES.COD_RESPONSE_HTTP_OK].includes(service.status)) {
            const loginObj = {
              email: tmpEmail,
              password: data.password,
            };
            await handleLogOut();
            const service = await LoginService(loginObj);
            // Verificar que se haya iniciado sesión correctamente
            if (
              service.status === CODES.COD_RESPONSE_HTTP_OK &&
              service.data.responseCode === CODES.COD_RESPONSE_SUCCESS
            ) {
              const accessToken = service.data.responseMessage.accessToken;
              localStorage.setItem("accessToken", accessToken);

              const refreshToken = service.data.responseMessage.refreshToken;
              localStorage.setItem("refreshToken", refreshToken);

              const jwtTokenInfo = service.data.responseMessage.idToken;
              localStorage.setItem("infoToken", jwtTokenInfo);

              const payloadToken = service.data.responseMessage.idToken;
              localStorage.setItem(
                "payloadToken",
                JSON.stringify(payloadToken)
              );

              const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);
              //Mostrar indicador que la contraseña se cambió correctamente
              setRole(jwtDecodeTokenInfo["custom:Role"]);

              setTitle(t("App.validateCredentials.rightPanel.successModal"));
              setRespCode(CODES.COD_RESPONSE_HTTP_OK);
              setMessage(t("App.validatePassword.updatePass"));
              setOpen(true);
            }
          }
        } else {
          // Mostrar modal para indicar que la contraseña nueva es
          // igual a la anterior
          setTitle(t("App.validatePassword.warning"));
          setRespCode(CODES.COD_RESPONSE_ERROR_RESET_PASSWORD);
          setMessage(t("App.validatePassword.old"));
          setIsOpenModalInfo(true);
        }
      } catch (error) {
        console.log(
          "=============== ERROR CHANGEPASSWORDFT.JSX ====================="
        );
        console.log(error);
        console.log(
          "================================================================"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setValidCaptcha(false);
    }
  };

  /**
   * Función para mostrar u ocultar la contraseña
   */
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  /**
   * Función para cerrar la sesión actual del usuario
   */
  const handleLogOut = async () => {
    try {
      const service = await LogoutService();

      if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          service.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          service.data.responseCode === CODES.COD_RESPONSE_HTTP_BAD_REQUEST
        ) {
          localStorage.clear();
        }
      }
    } catch (error) {
      console.log("==============Error handleLogout======================");
      console.log(error);
      console.log("====================================");
    }
  };

  const handleButtonDisabled = () => {
    if (watch("password") && watch("confirmPassword") && !acceptTerms) {
      return false;
    } else {
      return true;
    }
  };

  const showApproved = () => {
    if (
      watch("password")?.length >= 8 &&
      watch("password")?.length <= 15 &&
      REGEXP.ONE_LOWER_LETTER.test(watch("password")) &&
      REGEXP.ONE_UPPER_LETTER.test(watch("password")) &&
      REGEXP.ONE_NUMBER.test(watch("password")) &&
      REGEXP.ONE_SPECIAL_CHAR.test(watch("password")) &&
      watch("password") == watch("confirmPassword")
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Evita que el campo de entrada pierda el foco cuando se hace clic en el botón
   * del ojo para mostrar u ocultar la contraseña
   * @param {*} event // Evento del mouse
   */
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Actualizar el valor del campo de confirmación de la contraseña
   * @param {*} prop // Nombre de la propiedad del campo
   */
  const handleConfirmPasswordChange = (prop) => (event) => {
    setValuesConfirmPassword({
      ...valuesConfirmPassword,
      [prop]: event.target.value,
    });
  };

  /**
   * Evita que el campo de entrada pierda el foco cuando se hace clic en el botón
   * del ojo para mostrar u ocultar la contraseña
   * @param {*} event // Evento del mouse
   */
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValuesConfirmPassword({
      ...valuesConfirmPassword,
      showConfirmPassword: !valuesConfirmPassword.showConfirmPassword,
    });
  };

  /**
   * Función para redireccionar al usuario a la sección
   * correspondiente según su role
   */
  const redirectSection = () => {
    setOpen(false);
    dispatch(resetUserStatus());
    if (role === "admin") {
      navigate("/admin/home");
    } else if (role === "user") {
      navigate("/user/home");
    }
  };

  return (
    <div className="changePasswordFT__wrap">
      <Grid className="validateCredentials__leftSide">
        <Grid container direction="column" spacing={2} sx={{ margin: "0px" }}>
          <Grid className="newPasswordImage__img-container">
            <ChangePaswordImage />
          </Grid>
          <Grid item className="changePasswordFT__leftSide-instructions">
            <Grid className="changePasswordFT__leftSide-instructions--instructionSet">
              <Grid style={{ alignText: "center" }}>
                <p className="instruction-text__title">
                  {t("App.changePasswordFT.leftPanel.instructionText")}
                </p>
              </Grid>
              <Grid className="instructions-container" sx={{ display: "flex" }}>
                <Grid className="instructions-container__column">
                  <Grid container className="instruction-text__container">
                    <Grid
                      xs={1.3}
                      sm={1.3}
                      lg={1.3}
                      xl={1}
                      style={{
                        display: "flex",
                        alignContent: "center !important",
                        paddingLeft: "0px !important",
                      }}
                      item
                    >
                      {watch("password")?.length >= 8 &&
                      watch("password")?.length <= 15 ? (
                        <CheckIcon
                          fontSize="28.75px !important"
                          className="changePasswordFT__leftSide-check"
                        />
                      ) : (
                        <InfoOutlinedIcon
                          fontSize="28.75px !important"
                          className="changePasswordFT__leftSide-required"
                        />
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={10.7}
                      sm={10.7}
                      lg={10.7}
                      xl={11}
                      sx={{
                        paddingLeft: "0px !important",
                        margin: "0px !important",
                      }}
                    >
                      <label className="instruction-text">
                        {t("App.changePasswordFT.leftPanel.instructionOne")}
                      </label>
                    </Grid>
                  </Grid>

                  <Grid container className="instruction-text__container">
                    <Grid
                      xs={1.3}
                      sm={1.3}
                      lg={1.3}
                      xl={1}
                      style={{
                        display: "flex",
                        alignItems: "center !important",
                        paddingLeft: "0px !important",
                      }}
                      item
                    >
                      {REGEXP.ONE_LOWER_LETTER.test(watch("password")) ? (
                        <CheckIcon
                          fontSize="28.75px !important"
                          className="changePasswordFT__leftSide-check"
                        />
                      ) : (
                        <InfoOutlinedIcon
                          fontSize="28.75px !important"
                          className="changePasswordFT__leftSide-required"
                        />
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={10.7}
                      sm={10.7}
                      lg={10.7}
                      xl={11}
                      sx={{
                        paddingLeft: "0px !important",
                        margin: "0px !important",
                      }}
                    >
                      <label className="instruction-text">
                        {t("App.changePasswordFT.leftPanel.instructionTwo")}
                      </label>
                    </Grid>
                  </Grid>

                  <Grid container className="instruction-text__container">
                    <Grid
                      xs={1.3}
                      sm={1.3}
                      lg={1.3}
                      xl={1}
                      style={{
                        display: "flex",
                        alignItems: "center !important",
                        paddingLeft: "0px !important",
                      }}
                      item
                    >
                      {REGEXP.ONE_UPPER_LETTER.test(watch("password")) ? (
                        <CheckIcon
                          fontSize="28.75px !important"
                          className="changePasswordFT__leftSide-check"
                        />
                      ) : (
                        <InfoOutlinedIcon
                          fontSize="28.75px !important"
                          className="changePasswordFT__leftSide-required"
                        />
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={10.7}
                      sm={10.7}
                      lg={10.7}
                      xl={11}
                      sx={{
                        paddingLeft: "0px !important",
                        margin: "0px !important",
                      }}
                    >
                      <label className="instruction-text">
                        {t("App.changePasswordFT.leftPanel.instructionThree")}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid className="instructions-container__column">
                  <Grid container className="instruction-text__container">
                    <Grid
                      xs={1.3}
                      sm={1.3}
                      lg={1.3}
                      xl={1}
                      style={{
                        display: "flex",
                        alignItems: "center !important",
                        paddingLeft: "0px !important",
                      }}
                      item
                    >
                      {REGEXP.ONE_NUMBER.test(watch("password")) ? (
                        <CheckIcon
                          fontSize="28.75px !important"
                          className="changePasswordFT__leftSide-check"
                        />
                      ) : (
                        <InfoOutlinedIcon
                          fontSize="28.75px !important"
                          className="changePasswordFT__leftSide-required"
                        />
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={10.7}
                      sm={10.7}
                      lg={10.7}
                      xl={11}
                      sx={{
                        paddingLeft: "0px !important",
                        margin: "0px !important",
                      }}
                    >
                      <label className="instruction-text">
                        {t("App.changePasswordFT.leftPanel.instructionFour")}
                      </label>
                    </Grid>
                  </Grid>

                  <Grid container className="instruction-text__container">
                    <Grid
                      xs={1.3}
                      sm={1.3}
                      lg={1.3}
                      xl={1}
                      style={{
                        display: "flex",
                        alignItems: "center !important",
                        paddingLeft: "0px !important",
                      }}
                      item
                    >
                      {REGEXP.ONE_SPECIAL_CHAR.test(watch("password")) ? (
                        <CheckIcon
                          fontSize="28.75px !important"
                          className="changePasswordFT__leftSide-check"
                        />
                      ) : (
                        <InfoOutlinedIcon
                          fontSize="28.75px !important"
                          className="changePasswordFT__leftSide-required"
                        />
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={10.7}
                      sm={10.7}
                      lg={10.7}
                      xl={11}
                      sx={{
                        paddingLeft: "0px !important",
                        margin: "0px !important",
                      }}
                    >
                      <label className="instruction-text">
                        {t("App.changePasswordFT.leftPanel.instructionFive")}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="changePasswordFT__rightSide">
        <Grid
          container
          item
          direction="column"
          spacing={2}
          className="inner-container"
          sx={{ margin: "0px !important" }}
        >
          <Grid className="changePasswordFT__head-container">
            <Grid item xs={7} sm={6} md={4} lg={10} xl={12}>
              <div className="login__logo">
                <img
                  src={logo}
                  alt="Logo Prescriptiva"
                  className="logo-CENAnalíticos"
                />
              </div>
            </Grid>
            <Grid item xs={4.2} sm={2} md={3} lg={4} xl={2.8}>
              <LanguageSwitcher />
            </Grid>
          </Grid>
          <Grid item className="changePasswordFT__form-container">
            <Grid item sx={{ width: "100%" }}>
              <p className="changePasswordFT__title">
                {t("App.changePasswordFT.rightPanel.mainTitle")}
              </p>
            </Grid>

            <Grid item>
              <Grid className="changePasswordFT__form">
                {showApproved() && (
                  <Grid item className="approved-container">
                    <p className=" approved-container__text ">
                      {t("App.changePasswordFT.rightPanel.successfulText")}
                    </p>
                  </Grid>
                )}
                <Grid item>
                  <p className="changePasswordFT__instructions">
                    {t("App.changePasswordFT.rightPanel.instructionText")}
                  </p>
                </Grid>
                <form onSubmit={handleSubmit(handleChangePasswordFT)}>
                  <Grid item>
                    {errors.password ? (
                      <label className="password-labels">
                        {t("App.validateCredentials.rightPanel.password")}
                      </label>
                    ) : (
                      <label className="form__label"> </label>
                    )}
                  </Grid>
                  <Grid className="changePasswordFT__form-input__container">
                    <Controller
                      defaultValue=""
                      name="password"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          className={
                            errors.password
                              ? "changePasswordFT__form-input--error"
                              : "changePasswordFT__form-input"
                          }
                          disableUnderline
                          placeholder={t(
                            "App.validateCredentials.rightPanel.password"
                          )}
                          sx={{
                            "input::-ms-reveal, input::-ms-clear": {
                              display: "none",
                            },
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -6px",
                              alignItems: "center",
                              padding: "4px",
                              transformOrigin: "-45px -40px",
                              "&.Mui-focused": {
                                color: "#8C8D9D", // Mismo valor para mantener alineación
                              },
                            },
                          }}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleChangePassBorder(e.target.value);
                            handleShowPassword(e.target.value);
                          }}
                          type={values.showPassword ? "text" : "password"}
                          InputProps={{
                            startAdornment: null,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  sx={{
                                    padding: "0px !important",
                                    // marginRight: "-5px",
                                  }}
                                >
                                  {values.showPassword ? (
                                    <VisibilityOffIcon
                                      className={
                                        errors?.password
                                          ? "icon--error"
                                          : "icon"
                                      }
                                    />
                                  ) : (
                                    <VisibilityIcon
                                      className={
                                        errors?.password
                                          ? "icon--error"
                                          : "icon"
                                      }
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            autoComplete: "off ",
                            sx: {
                              height: "100% !important",
                              width: "100% !important",
                              "&:-webkit-autofill": {
                                ackgroundColor: "transparent !important",
                                borderRadius: "8px",
                              },
                            },
                          }}
                        />
                      )}
                    />

                    {errors.password && (
                      <p className="changePasswordFT_one__form-errorText">
                        {errors.password.message}
                      </p>
                    )}
                  </Grid>

                  <Grid item>
                    {errors.confirmPassword ? (
                      <label className="password-labels">
                        {t(
                          "App.validateCredentials.rightPanel.confirmPassword"
                        )}
                      </label>
                    ) : (
                      <label className="label-invisible"> </label>
                    )}
                  </Grid>
                  <Grid className="changePasswordFT__form-input__container">
                    <Controller
                      defaultValue=""
                      name="confirmPassword"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          className={
                            errors.confirmPassword
                              ? "changePasswordFT__form-input--error"
                              : "changePasswordFT__form-input"
                          }
                          disableUnderline
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder={t(
                            "App.validateCredentials.rightPanel.confirmPassword"
                          )}
                          sx={{
                            "input::-ms-reveal, input::-ms-clear": {
                              display: "none",
                            },
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -6px",
                              alignItems: "center",
                              padding: "4px",
                              transformOrigin: "-45px -40px",
                              "&.Mui-focused": {
                                color: "#8C8D9D", // Mismo valor para mantener alineación
                              },
                            },
                          }}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleChangeConPassBorder(e.target.value);
                          }}
                          type={
                            valuesConfirmPassword.showConfirmPassword
                              ? "text"
                              : "password"
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowConfirmPassword}
                                  sx={{
                                    padding: "0px !important",
                                    // marginRight: "-5px",
                                  }}
                                >
                                  {valuesConfirmPassword.showConfirmPassword ? (
                                    <VisibilityOffIcon
                                      className={
                                        errors?.confirmPassword
                                          ? "icon--error"
                                          : "icon"
                                      }
                                    />
                                  ) : (
                                    <VisibilityIcon
                                      className={
                                        errors?.confirmPassword
                                          ? "icon--error"
                                          : "icon"
                                      }
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            autoComplete: "off",
                            sx: {
                              height: "100% !important",
                              width: "100% !important",
                              "&:-webkit-autofill": {
                                backgroundColor: "white !important",
                                // boxShadow: "0 0 0 100px white inset !important",
                                borderRadius: "8px",
                              },
                            },
                          }}
                        />
                      )}
                    />

                    {errors.confirmPassword && (
                      <p className="changePasswordFT_one__form-errorText">
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </Grid>

                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: "22px" },
                          color: "#543AB4",
                          "&.Mui-checked": {
                            color: "#543AB4",
                          },
                          margin: "0px !important",
                        }}
                      />
                    }
                    onChange={() => setacceptTerms(!acceptTerms)}
                    label={
                      <a
                        href="https://www.carvajaltys.com/politica-de-tratamiento-de-datos/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="default-text-one__link accept-terms"
                      >
                        {t("App.changePasswordFT.rightPanel.acceptPolicies")}
                      </a>
                    }
                  />
                  <Grid
                    direction="column"
                    style={{
                      width: "100%",
                      marginTop: "2%",
                      justifyItems: "center",
                    }}
                  >
                    <Grid item>
                      <Grid item className="recaptcha_container">
                        <ReCAPTCHA
                          ref={captcha}
                          className="captcha-container__captcha-only"
                          sitekey="6LfkwBwgAAAAAIzpuBBjEZL8Pid8GvnYNq5EYE40"
                          onChange={onChange}
                          hl={t("App.login.rightPanel.recaptcha")}
                          sx={{
                            justifySelf: "center !important",
                            // width: "100% !important",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      {validCaptcha ? (
                        <p className="label-invisible"></p>
                      ) : (
                        <p className="error-message">
                          {t("App.login.rightPanel.recaptchaMessage")}
                        </p>
                      )}
                    </Grid>
                    <Grid sx={{ width: "100%", textAlign: "center" }}>
                      <button
                        className={
                          handleButtonDisabled()
                            ? "changePasswordFT__form-button--disabled"
                            : "changePasswordFT__form-button"
                        }
                        type="submit"
                        disabled={handleButtonDisabled() || isLoading}
                        style={{ marginTop: "0.7rem" }}
                      >
                        {!isLoading ? (
                          t("App.changePasswordFT.rightPanel.button")
                        ) : (
                          <CircularProgress size={16} color="inherit" />
                        )}
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {checklist && (
        <div>
          <CheckIcon></CheckIcon>
        </div>
      )}

      <ModalInfo
        title={title}
        responseData={{
          status: respCode,
          data: {
            responseCode: respCode,
            responseMessage: message,
          },
        }}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
        }}
        close={true}
      />

      <ModalInfo
        title={title}
        responseData={{
          status: respCode,
          data: {
            responseCode: respCode,
            responseMessage: message,
          },
        }}
        open={open}
        onClose={() => {
          redirectSection();
        }}
        close={false}
      />
    </div>
  );
};
