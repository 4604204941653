import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import recoverPasswordLeftSide from "../../assets/common/recoverPasswordImage.svg";

const RecoverPasswordImage = () => {
  const { t } = useTranslation();

  return (
    <Grid className="recoverPasswordImage" xl={12}>
      <Grid
        item
        className="recoverPasswordImage_title-container"
        sx={{ width: "100%" }}
      >
        <p className="recoverPasswordImage_title text-center text-pasword ">
          {t("App.login.leftPanel.recoverPassword")}
        </p>

        <img
          src={recoverPasswordLeftSide}
          alt="Login Image"
          className="recoverPasswordImage_img"
        />
      </Grid>
    </Grid>
  );
};

export default RecoverPasswordImage;
