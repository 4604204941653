import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import loginSupport from "../../assets/common/loginSupport.svg";
import dotPattern from "../../assets/common/dotPattern.svg";

const LoginSupportImage = () => {
  const { t } = useTranslation();

  return (
    <Grid className="loginImage-support" xl={12}>
      <p className="loginImage_titleSupport">{t("App.login.leftPanel.ImageTextTitleSupport")}</p>
      <img
        src={loginSupport}
        alt="Login Image"
        className="loginImage_img-support"
      />
      <Grid className="loginImage_title-container" xl={12}>
      </Grid>
    </Grid>
  );
};

export default LoginSupportImage;
