import {Grid, TextField, CircularProgress } from "@mui/material";
import {useState, useEffect, useRef} from "react";
import React, { Fragment } from 'react';
import { getUser, resetUserStatus } from "../../pages/userSlice";
import { useTranslation } from "react-i18next";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";


import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router";
import { CODES } from "../../consts/codes";
import { LanguageSwitcher } from "../../components/common/languageSwitcher";
import logo from "../../assets/common/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import ModalInfo from "../../components/common/Modals/modalInfo";
import { Controller, useForm } from "react-hook-form";
import { UpdateSupportCompany } from "../../services/admin/adminServices";
import { GetListReceiver } from "../../services/user/tracebility/traceabilityService";
import LoginSupportImage from "./loginSupportImage";
import { GetGeographiesList } from "../../services/admin/geographies/geographiesService";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ModalSupportLogin from "../../components/common/Modals/modalSupportLogin";
export const LoginSupportUser = () => {
  const userInfo = useSelector(getUser);
  
  /**
  * Use ref
  */
 const captcha = useRef(null);
 /**
  /**
   * Use State
   */
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [modalType, setModalType] = useState(null); 
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [title, setTitle] = useState(null);
  const [IsOpenSupportModal, setIsOpenSupportModal] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [respCode, setRespCode] = useState(null);
  const [isLogin, setIsLogin] = useState(false);
  const [country, setCountry] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [unfilteredList, setUnfilteredList] = useState([]);
  const [dataFilters, setDataFilters] = useState(null);
  const [dataCompany, setDataCompany] = useState (null);
  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [titleModal, setTitleModal] = useState ("");
  const [respCodeModal, setRespCodeModal] = useState ("");

  const {
    control,
    setValue, 
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {country: "",typeCompany: "",bussiness:""},
  });

    /**
   * Use Translation
   */
  const { t } = useTranslation();
  /**
   * User info 
   */

  /**
   * Funcion para deshabilitar el boton 
   */
  const handleDisabled = () => {
    return !(watch("country") && watch("typeCompany") && watch("bussiness") && validCaptcha);
};
  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSupportLogin = () => {
    setIsOpenSupportModal(true); // Aquí abres el modal, cambiando el estado
};
  /**
   * Opciones para la configuración del listado de los autocomplete
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * Opciones para el tipo de empresa
   */
  const typeComps = [
    {
      label: t("App.listUsers.businessPartner"),
      value: "C",
    },
    {
      label: t("App.listUsers.supplier"),
      value: "F",
    },
  ];


  /**
   * Actualizar el valor del captcha
   */
  const onChangeCaptcha = () => {
    if (captcha.current.getValue()) {
      setValidCaptcha(true);
    } else {
      setValidCaptcha(false); 
    }
  };
  
  /**
     * Cerrar el modal 
     */
  const handleActionSupport = () => {
    setIsOpenSupportModal(false);
  };

  /**
   * Cerrar el modal de error
   */
  const handleAction = () => {
    setIsOpenErrorModal(false);
  };

   /**
   * Consulta los países registrados en la plataforma si estos no se encuentran en Redux
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        const geographiesService = await GetGeographiesList();
 
        setLoading(true);
        setIsLoadingCountries(true);
        if (
          geographiesService.status === CODES.COD_RESPONSE_HTTP_OK &&
          (geographiesService.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST ||
            geographiesService.data.responseCode === CODES.COD_RESPONSE_SUCCESS)
        ) {
          const responseMessage = geographiesService.data.responseMessage;
 
          let countries = responseMessage.map((country) => {
            return {
              countryName: country.countryName,
              country: country.country,
            };
          });
 
          setUnfilteredList(countries);
          setCountryList(countries);
        }
 
        setLoading(false);
        setIsLoadingCountries(false);
      } catch (error) {
        console.log(
          "============== Error FiltersAdminProducts.jsx useEffect getGeographies ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };
 
    getGeographies();
  }, []);

  /**
   * Función que determina si un campo ha sido rellenado o no
   * @param {*} fieldName nombre del campo
   * @returns true si fue rellenado, false si no
   */
  const isFieldFilled = (fieldName) => {
    const currentField = watch(fieldName);
    if (!currentField) {
      return false;
    }
    switch (fieldName) {
      case "typeCompany":
        case "bussiness":
          return currentField.length !== 0;
  
      default:
        return currentField.length !== 0;
    }
  };
  /**
   * Función para desabilitar typeCompany cuando el campo pais no esta seleccionado
   */
  const handleDisableElement = () => {
    if (watch("country")) {
      return false;
    } else {
      return true;
    }
  };

  const handleDisableElementByType = () => {
    if (watch("country")&& watch("typeCompany")) {
      return false;
    } else {
      return true;
    }
  };
  /**
   * Cambiar empresa del usuario de soporte
   */
  const handleUpdate = async () => {
    const data = getValues();
    try {
      const obj = {
        eanCompany: data.bussiness.eanCompany,
        companyName: data.bussiness.companyName,
        country: data.bussiness.country,
      };
      const {
        status,
        data: { responseCode, responseMessage },
      } = await UpdateSupportCompany(obj);
      setDataFilters(obj);
      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        //setTitle(t("App.updateCompany.title"));
        setRespCode(responseCode);
        setMessage(responseMessage);
        handleSupportLogin();
      } else {
        setIsOpenErrorModal(true);
      }
    } catch (error) {
      setTitle(t("App.login.errors.supportTitle"));
      setMessage(t("App.login.errors.supportCompany"));
      setIsOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const handleCountryChange = async (values, reason) => {
    //Reseteamos los demás campos
    setValue("typeCompany", null);
    setValue("bussiness", null);
    setCompanyList([]);
    setLoading(true);
  
    //Si se limpia el field se settea "company" como null
    if (reason === "clear") {
      setLoading(false);
    } else {
      setCountry(values.country)
      setLoading(false);
    }
  };
  
  
   /**
     * Handles the input change of type company field
     * @param {array} values list of selected countries
     */
  const handleTypeCompanyChange = async (values, reason) => {
    if (reason === "clear") {
      //Reseteamos los demás campos
      setValue("bussiness", null);
      setCompanyList([]);
    }else{
      //Reseteamos los demás campos
      setValue("bussiness", null);
      setCompanyList([]);
      await getReceivers(country,values.value)
    }
    setDataCompany(values);
  }

  /**
   * Obtener lista de empresas
   */
  const getReceivers = async (country, typeCompany) => {
  try {
    const obj = {country, typeCompany}
    const {
      status,
      data: { responseCode, responseMessage },
    } = await GetListReceiver(obj);

    if (
      status === CODES.COD_RESPONSE_HTTP_OK &&
      responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      const listCompanies = responseMessage 
        .map((item) => ({
          eanCompany: item.eanCompany,
          companyName: item.companyName,
          country: item.country,
        }));
       setCompanyList(listCompanies); // Actualiza la lista de empresas.
      console.log(listCompanies)
    } else {
      setTitle("Error");
      setMessage(t("App.login.errors.supportCompany"));
      setTitleModal(t("App.support.restricted"));
      setRespCodeModal(t("App.support.message"));
      setIsOpenErrorModal(true);
    }
  } catch (error) {
    setTitle("Error");
    setMessage(t("App.login.errors.supportCompany"));
    setIsOpenErrorModal(true);
    console.error(error);
  }
};


  return (
    <div className="login__wrap">
      <Grid item className="login__leftSide-support" >
      <LoginSupportImage />
      </Grid> 
      <Grid className="login__rigthSide__support" xl={10}>
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
        <Grid item className="login__head-container-support">
          <Grid item xs={8} sm={6} md={4} lg={9.1} xl={10}>
            <div className="login__logo" >
              <img
                src={logo}
                alt="Logo Prescriptiva"
                className="logo-CENAnalíticos__support"
              />
            </div>
          </Grid>
            <Grid item xs={4.5} sm={2.2} md={3} lg={3} xl={2.1}>
              <LanguageSwitcher />
            </Grid>
        </Grid>
          <Grid item sm={3} md={3}>
            <p className="login__title-support">
              {" "}
              {t("App.login.rightPanel.support")}
            </p>
            <p className="login__subtitle-support">
              {" "}
              {t("App.login.rightPanel.supportLabel")}
            </p>
          </Grid>
        <Grid item sx={{ width: "100%", justifyItems: "center"}} className="loginSupportContainer" xs={4.2} sm={2} md={3} lg={12} xl={2}>
          <form id="supportForm" onSubmit={handleSubmit (handleUpdate)}>
          <Grid item>
            <Grid item >
            <Grid item width="100%" className="LoginsupportP" xs={12} >
              <Grid item sx={{textAlign:"left"}}>
               <label className="form__label label__text-grey">
                    {t("App.listUsers.country")}
                  </label>
                </Grid>
                <Controller
                  name={`country`}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disabled={isLoadingCountries}
                      noOptionsText={t("App.listUsers.noOptions")}
                      className={
                        !isLoadingCountries
                          ? isFieldFilled("country")
                            ? "filters-support "
                            : "filters-support__not-filled"
                          : "filters-support__disabled"
                      }
                      options={countryList}
                      getOptionLabel={(option) => option.countryName}
                      isOptionEqualToValue={(option, value) =>
                        option.country == value.country
                      }
                      popupIcon={<KeyboardArrowDownIcon />}
                      renderInput={(params) => {
                        return (
                          <TextField
                            placeholder={t("App.listUsers.countryN")}
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading ? (
                                    <CircularProgress
                                      sx={{ color: "#4DCAFA" }}
                                      size={15}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      onChange={(event, values, reason) => {
                        onChange(values);
                        handleCountryChange(
                          values,
                          reason
                        )
                      }}
                      value={value || null}
                    />
                  )}
                  control={control}
                />
                </Grid> 
              </Grid> 
              <Grid item width="100%" className="LoginsupportP">
              <Grid item sx={{textAlign:"left"}}>
               <label className="form__label label__text-grey">
                    {t("App.listUsers.typeCompany")}*
                  </label>
                </Grid>
                  <Controller
                    name={`typeCompany`}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        disabled={handleDisableElement()}
                        className={
                          !handleDisableElement()
                            ? isFieldFilled("typeCompany")
                              ? "filters-support "
                              : "filters-support__not-filled"
                            : "filters-support__disabled"
                        }
                        options={typeComps}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        popupIcon={<KeyboardArrowDownIcon />}
                        renderInput={(params) => {
                          return (
                            <TextField
                              placeholder={t("App.listUsers.typeCompany")}
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        sx={{ color: "#4DCAFA" }}
                                        size={15}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                          handleTypeCompanyChange (
                            values,
                            reason
                          )
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>
                <Grid item width="100%" className="LoginsupportP-business">
                <Grid item sx={{textAlign:"left"}}>
                  <label className="form__label label__text-grey">
                    {t("App.listUsers.companyo")}
                  </label>
                </Grid>
                <Controller
                  name={`bussiness`}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      filterOptions={filterOptions}
                      disabled={handleDisableElementByType ()}
                      noOptionsText={t("App.listUsers.noOptions")}
                      className={
                        !handleDisableElementByType()
                          ? isFieldFilled("bussiness")
                            ? "filters-support "
                            : "filters-support__not-filled"
                          : "filters-support__disabled"
                      }
                      options={companyList}
                      getOptionLabel={(option) => option.companyName}
                      isOptionEqualToValue={(option, value) =>
                        option.eanCompany === value.eanCompany
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.eanCompany}>
                          {option.companyName}
                        </li>
                      )}
                      popupIcon={<KeyboardArrowDownIcon />}
                      renderInput={(params) => (
                        <TextField
                          placeholder={t("App.listUsers.companyo")}
                          {...params}
                        />
                      )}
                      onChange={(event, values, reason) => {
                        onChange(values);
                        setDataFilters(values);
                      }}
                      value={value || null}
                    />
                  )}
                  control={control}
                />
                </Grid>
             </Grid>
            <Grid item sx={{ justifyContent: "center !important", paddingTop:"15px" }}>
              <Grid className="recaptcha_container_support">
                <ReCAPTCHA
                  ref={captcha}
                  sitekey="6LfkwBwgAAAAAIzpuBBjEZL8Pid8GvnYNq5EYE40"
                  onChange={onChangeCaptcha}
                  hl={t("App.login.rightPanel.recaptcha")}
                />
              </Grid>
            </Grid>
            <Grid item sx={{ width: "100%", height: "50%", display: "flex", justifyContent: "center"}} className="padingButton">
              <button
                disabled={handleDisabled()}
                className={
                  handleDisabled()
                  ? "login__form-button--disabled-one"
                  : "login__form-button--support_one"
              }
                type="submit"
                form = "supportForm"
              >
                {t("App.login.rightPanel.button")}
              </button>
            </Grid>
            </form>
            </Grid>
          </Grid>
        </Grid>
      <ModalInfo
        title={title}
        responseData={{
          status: respCode,
          data: {
            responseCode: respCode,
            responseMessage: message,
          },
        }}
        open={isOpenErrorModal}
        onClose={handleAction}
        close={false}
      />
        <ModalSupportLogin
            title={title}
            responseData={{
                status: respCode,
                data: {
                    responseCode: respCode,
                    responseMessage: message,
                },
            }}
            open={IsOpenSupportModal}
            onClose={handleActionSupport}
            dataCompany={dataCompany}
            dataFilters={dataFilters}
            close={false}
            setIsOpenModalInfo={setIsOpenModalInfo}
            setIsOpenSupportModal={setIsOpenSupportModal}
            setTitleModal={setTitleModal}
            setRespCodeModal={setRespCodeModal}

        />
         <ModalInfo
            title={titleModal}
            responseData={{
                status: respCodeModal,
                data: {
                    responseCode: String(respCodeModal),
                    responseMessage: respCodeModal || "Información no disponible",
                },
            }}
            open={isOpenModalInfo}
            onClose={() => {
                setIsOpenModalInfo(false);
            }}
            close={true}
            classNameTitle="modal-title"
            className="modal-subtitle"
        />
    </div>
  );
};
