import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  createTheme,
  FormControlLabel,
  Grid,
  ThemeProvider,
  Skeleton,
  Chip,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { FilterDynamicReports } from "../../../components/common/DynamicReport/filterdynamicReports";
import MUIDataTable from "mui-datatables";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import {
  DownloadLogs,
  DownloadReports,
  ListReports,
} from "../../../services/user/listDynamicReportService";
import { CODES } from "../../../consts/codes";
import { getUser, getUserStatus } from "../../userSlice";
import { useSelector } from "react-redux";

import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import PeriodicReportState from "../../../components/common/DynamicReport/periodicReportState";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

export const ListDynamicPeriodicReports = () => {
  const [data, setData] = useState([]);
  const [noReports, setNoReports] = useState(false);
  const [noFound, setNoFound] = useState(false);
  const [unfilteredData, setUnfilteredData] = useState([]);

  const [filters, setFilters] = useState({});

  const { t } = useTranslation();

  const userInfo = useSelector(getUser);

  const userStatus = useSelector(getUserStatus);

  const handleDownloadFile = async (fileName, path) => {
    const obj = {
      pathFileName: path,
      generatedFileName: fileName,
    };

    try {
      const fileService = await DownloadReports(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS ||
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const handleDownloadLogs = async (fileName, path) => {
    const obj = {
      pathLogFileName: path,
      logFileName: fileName,
    };

    try {
      const fileService = await DownloadLogs(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS ||
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: "none",
              border: "1px solid gray",
              borderRadius: "5px",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            footer: {
              border: "none",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              justifyContent: "center",
            },
            toolButton: {
              // * Secreto para que los headers del datatable queden centrados con el contenido
              margin: "0",
              padding: "0",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              textAlign: "center",
            },
          },
        },
      },
    });

  const optionsDataTable = {
    download: false,
    print: "false",
    search: false,
    searchOpen: true,
    selectableRows: "none",
    viewColumns: false,
    filter: false,
    rowHover: false,
    filterType: "dropdown",
    responsive: "standard",
    searchPlaceholder: t("App.listDynamicPeriodicReport.search"),
    elevation: 0,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const columns = [
    {
      name: "creationDate",
      label: t("App.listDynamicPeriodicReport.table.columns.column1"),
      options: {
        sort: true,
        searchable: false,
      },
    },
    {
      name: "creationUser",
      label: t("App.listDynamicPeriodicReport.table.columns.column2"),
      options: {
        sort: true,
      },
    },
    {
      name: "modificationDate",
      label: t("App.listDynamicPeriodicReport.table.columns.column3"),
      options: {
        sort: true,
        searchable: false,
        hint: t("App.listDynamicPeriodicReport.tooltips.tooltip1"),
      },
    },
    {
      name: "modificationUser",
      label: t("App.listDynamicPeriodicReport.table.columns.column4"),
      options: {
        sort: true,
        searchable: false,
        hint: t("App.listDynamicPeriodicReport.tooltips.tooltip2"),
      },
    },
    {
      name: "expirationDate",
      label: t("App.listDynamicPeriodicReport.table.columns.column5"),
      options: {
        sort: true,
        searchable: false,
      },
    },
    {
      name: "company",
      label: t("App.listUsers.enterpriseN"),
      options: {
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <p style={{ minWidth: "120px" }}>{value}</p>
        ),
      },
    },
    {
      name: "generationFrequency",
      label: t("App.listDynamicPeriodicReport.table.columns.column6"),
      options: {
        sort: true,
        searchable: false,
      },
    },
    {
      name: "typeOfDataGrouping",
      label: t("App.listDynamicPeriodicReport.table.columns.column7"),
      options: {
        sort: true,
        searchable: false,
        customBodyRender: (value) =>
          value === "Semanal" ? `${value} ISO (L-D)` : value,
      },
    },
    {
      name: "fileName",
      label: t("App.listDynamicPeriodicReport.table.columns.column8"),
      options: {
        filter: true,
        searchable: true,
        sort: true,
        hint: t("App.listDynamicPeriodicReport.tooltips.tooltip3"),
        customHeadLabelRender: (columnMeta) => (
          <p style={{ width: "130px" }}>{columnMeta.label}</p>
        ),
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            style={{ padding: "2%" }}
            label=""
            value={value.pathFileName && value.fileName}
            control={
              <a
                style={{
                  cursor: "pointer",
                }}
                className="default-text__linkBlue__sm"
                onClick={(event) => {
                  if (value.pathFileName) {
                    handleDownloadFile(value.fileName, value.pathFileName);
                  }
                }}
              >
                {value.pathFileName && value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "status",
      label: t("App.listDynamicPeriodicReport.table.columns.column9"),
      options: {
        sort: true,
        searchable: false,
        customBodyRender: (value) => <PeriodicReportState value={value} />,
      },
    },
    {
      name: "detailErrors",
      label: t("App.listDynamicPeriodicReport.table.columns.column10"),
      options: {
        sort: true,
        searchable: false,
        hint: t("App.listDynamicPeriodicReport.tooltips.tooltip4"),
        customHeadLabelRender: (columnMeta) => (
          <p style={{ width: "130px" }}>{columnMeta.label}</p>
        ),
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            style={{ padding: "1%" }}
            label=""
            value={value}
            control={
              <a
                style={{
                  cursor: "pointer",
                }}
                className="default-text__linkBlue__sm"
                onClick={(event) => {
                  if (value.logFileName) {
                    handleDownloadLogs(
                      value.logFileName,
                      value.pathLogFileName
                    );
                  }
                }}
              >
                {value.logFileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "edit",
      label: t("App.listDynamicPeriodicReport.table.columns.column11"),
      options: {
        sort: false,
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [userStatus]);

  async function fetchData(state = null) {
    setData(skeletonLine);

    try {
      if (userInfo) {
        const obj = {
          eanProvider: userInfo.company.eanCompany,
          country: userInfo.company.country,
          emails: userInfo.email,
        };

        const reportsService = await ListReports(obj);

        if (
          reportsService.status === CODES.COD_RESPONSE_HTTP_OK &&
          reportsService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          const responseMessage = reportsService.data.responseMessage;

          if (responseMessage.length === 0) {
            setNoReports(true);
          }
          const ProcessRows = responseMessage.map((item, idx) => {
            return {
              creationDate: !item.dynamicReportDTO.creationDate
                ? " - "
                : item.dynamicReportDTO.creationDate,
              creationUser: !item.dynamicReportDTO.creationUser
                ? " - "
                : item.dynamicReportDTO.creationUser,
              modificationDate: !item.dynamicReportDTO.modificationDate
                ? " - "
                : item.dynamicReportDTO.modificationDate,
              modificationUser: !item.dynamicReportDTO.modificationUser
                ? " - "
                : item.dynamicReportDTO.modificationUser,
              expirationDate: !item.dynamicReportDTO.expirationDate
                ? " - "
                : item.dynamicReportDTO.expirationDate,
              company: !item.company ? " - " : item.company,
              generationFrequency: !item.dynamicReportDTO.generationFrequency
                ? " - "
                : item.dynamicReportDTO.generationFrequency,
              typeOfDataGrouping: !item.dynamicReportDTO.typeOfDataGrouping
                ? " - "
                : item.dynamicReportDTO.typeOfDataGrouping,
              fileName: !item.dynamicReportDTO.fileName
                ? " - "
                : {
                    fileName: item.generatedFileName,
                    fileNameOrignal: item.dynamicReportDTO.fileName,
                    pathFileName: item.pathFileName,
                    // state: !master.state ? "" : master.state,
                  },
              detailErrors: !item.logFileName
                ? " - "
                : {
                    logFileName: item.logFileName,
                    pathLogFileName: item.pathLogFileName,
                    // state: !master.state ? "" : master.state,
                  },
              status: !item.state
                ? ""
                : calculateRestDays(
                    item.dynamicReportDTO.expirationDate,
                    item.state
                  ),
              edit: (
                <IconButton
                  onClick={() =>
                    navigate(
                      `/user/editDynamicPeriodicReport/${
                        item.pk.split("#")[1]
                      }/${item.sk.split("#")[1]}`
                    )
                  }
                >
                  <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
                </IconButton>
              ),
            };
          });
          if (state !== null) {
            filterOtherState(state, ProcessRows);
          } else {
            setData(ProcessRows);
          }
          setUnfilteredData(ProcessRows);
        } else {
          setNoReports(true);
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  }

  const filterOtherState = (status, ProcessRows) => {
    console.clear();
    console.log(status);
    setData(
      ProcessRows.filter((item) => {
        return item.status === status;
      })
    );
  };

  const calculateRestDays = (date, state) => {
    const currentDate = new Date();
    const expirationDate = new Date(date);
    const diferenciaMilisegundos = expirationDate - currentDate;
    const diferenciaDias = Math.floor(
      diferenciaMilisegundos / (1000 * 60 * 60 * 24)
    );
    if (diferenciaDias < 0 && state === "INACTIVO") {
      return "INACTIVO VENCIDO";
    } else if (diferenciaDias > 0 && diferenciaDias <= 30) {
      return "ACTIVO PROXIMO A VENCER";
    } else {
      return state;
    }
  };

  const skeletonLine = [
    {
      creationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      creationUser: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      modificationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      modificationUser: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      expirationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      generationFrequency: (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      ),
      company: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      typeOfDataGrouping: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      fileName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      status: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      detailErrors: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      edit: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={4}
      style={{ marginBottom: "10px" }}
    >
      <Grid item>
        <p className="display-large__primary-one">
          {t("App.listDynamicPeriodicReport.title")}
        </p>
      </Grid>

      <Grid item container justifyContent="flex-end">
        <Button
          className="btn__applyFilter dimentions-btn__applyFilter"
          startIcon={<AddIcon />}
          onClick={() => navigate("/user/createDynamicPeriodicReport")}
        >
          {t("App.listDynamicPeriodicReport.createButton")}
        </Button>
      </Grid>

      <Grid item className="full-width">
        <Grid
          className="full-width background-colors__white border-radius__default"
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ marginLeft: "0px" }}
        >
          <Grid item className="full-width u-box-padding__sm">
            <Accordion className="accordion__filter" expanded>
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "#543ab4",
                  }}
                />

                <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                  {t("App.listUsers.filter")}
                </p>
              </AccordionSummary>

              <AccordionDetails>
                <FilterDynamicReports
                  unfilteredData={unfilteredData}
                  setData={setData}
                  data={data}
                  setNoReports={setNoReports}
                  setFilters={setFilters}
                  setNoFound={setNoFound}
                  fetchData={fetchData}
                  calculateRestDays={calculateRestDays}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item className="u-marginB" style={{ width: "100%" }}>
            <Grid container direction="row" style={{ columnGap: "2%" }}>
              {filters.reportStatus && (
                <Grid item>
                  <Chip
                    className="chip__filter text-transform__capitalize"
                    label={`${t(
                      "App.listDynamicPeriodicReport.state"
                    )}: ${filters.reportStatus.toLowerCase()}`}
                  />
                </Grid>
              )}

              {filters.expirationDate && (
                <Grid item>
                  <Chip
                    className="chip__filter"
                    label={`${t(
                      "App.listDynamicPeriodicReport.expirationDate"
                    )}: ${filters.expirationDate}`}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item className="full-width" style={{ marginBottom: "10px" }}>
            <ThemeProvider theme={getMuiTheme()}>
              {!noFound ? (
                !noReports && (
                  <MUIDataTable
                    className="dataTable__base"
                    data={data}
                    columns={columns}
                    options={optionsDataTable}
                  />
                )
              ) : (
                <NoMatchImage />
              )}
            </ThemeProvider>
          </Grid>

          {noReports && <NoInfoComponent type="dynamicReport" />}
        </Grid>
      </Grid>
    </Grid>
  );
};
