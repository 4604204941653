import { Box, Button, Grid, IconButton, Modal } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

const ModalSeeCompanies = ({ data, userName }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
        <Button
          fullWidth
          className="btn__outlined__see-company"
          onClick={handleOpen}
          >
            <p className="button__primary-one"
              style={{ marginRight: "5px"}}
            >
              {t("App.modalSeeCompanies.seeCompanies")}
            </p>
            {open && (
              <VisibilityOutlined sx={{ color: "#553ab6", height: "14px"}} />
            )}
            {!open && (
              <VisibilityOffOutlined sx={{ color: "#553ab6", height: "14px"}} />
            )}
        </Button>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal-box-seeCompanies">
          <Grid container direction="column">
            <Grid item container justifyContent="flex-end">
              <IconButton onClick={handleClose} component="span">
                <CloseIcon sx={{ color: "#543AB4", fontSize: "1.8rem" }} />
              </IconButton>
            </Grid>
            <Grid item>
              <h1 className="display-large__primary-one">
                {t("App.modalSeeCompanies.title")} {userName}
              </h1>
            </Grid>
            <br />
            <Grid container item className="box-list">
              <Grid
                item
                container
                direction="column"
                xs={5}
                lg={6}
                sx={{ justifyContent: "space-between" }}
              >
                <Grid item>
                  <h3 className="label__primary-one">
                    {t("App.modalSeeCompanies.company")}
                  </h3>
                </Grid>

                {data.map((company) => (
                  <Grid item key={company.eanCompany}>
                    <p>{company.renameCompany}</p>
                  </Grid>
                ))}
              </Grid>

              <Grid item container direction="column" xs={5} lg={6}>
                <Grid sx={{ justifyContent: "flex-end" }}>
                  <p className="label__primary-one">
                    {t("App.modalSeeCompanies.ean")}
                  </p>
                </Grid>

                {data.map((company) => (
                  <Grid item key={company.eanCompany}>
                    <p>{company.eanCompany}</p>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <br />
            <Grid item container justifyContent="flex-end">
              <Button
                variant="contained"
                className="modal-button-action"
                onClick={handleClose}
              >
                {t("App.buttonLabels.accept")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ModalSeeCompanies;
