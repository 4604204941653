import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

export const ModalDecision = ({
  isOpen,
  setIsOpen,
  message,
  handleAgree,
  handleDisagree,
  title,
  agreeText,
  disagreeText,
  customClass,
}) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  return (
    <div>
      <Dialog
        className="modal__decision"
        onClose={handleClose}
        open={isOpen}
        aria-labelledby="customized-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "47rem",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
            },
          },
        }}
      >
        <DialogTitleContainer className="col-start" onClose={handleClose}>
          {customClass === "validateCredentials_modal" ? (
            <WarningAmberOutlinedIcon
              sx={{ color: "#ffa836", fontSize: "4rem" }}
            />
          ) : (
            <InfoOutlinedIcon sx={{ color: "#4dcafa", fontSize: "56px" }} />
          )}
        </DialogTitleContainer>
        <DialogContent className="col-start">
          <Grid container direction="row" className="col-start">
            <Grid item style={{ marginBottom: "2%" }}>
              <label className={`modal-info-title ${customClass}`}>
                {" "}
                {title}
              </label>
            </Grid>

            <Grid
              item
              sx={{
                maxHeight: "200px",
                overflowY: "auto",
              }}
              className="regular-text_black"
            >
              {message}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            className="col-end modal-decision-btn__container"
            style={{ marginBottom: "1%", marginRight: "1%", columnGap: "5%" }}
          >
            <Grid item>
              <Button
                className="btn__filled__gradient modal-decision-btn"
                style={{ height: "34px" }}
                onClick={handleDisagree}
              >
                {disagreeText}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className="btn__filled__blue modal-decision-btn"
                style={{ height: "34px" }}
                onClick={handleAgree}
              >
                {agreeText}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
