import React, { useState, useEffect } from "react";
import {
  Dialog,
  IconButton,
  DialogTitle,
  Button,
  InputAdornment,
  TextField,
  Grid,
} from "@mui/material";
import {
  getUser,
  resetUserStatus, 
  fetchUser, 
  getUserStatus
} from "../../../pages/userSlice";
import CloseIcon from "@mui/icons-material/Close";
import {CheckSession, LoginService, LogoutService} from "../../../services/auth/authServices";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import ModalInfo from "./modalInfo";
import { CODES } from "../../../consts/codes";
import {useDispatch ,useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

  const ModalSupportLogin = ({responseData, onClose, open, close = true, dataFilters, dataCompany, setIsOpenModalInfo, setIsOpenSupportModal,setRespCodeModal,setTitleModal}) => {
    
    const { data, status } = responseData;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isBorderFocus, setIsBorderFocus] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [invalidPasswordMessage, setInvalidPasswordMessage] = useState(false);
    const [validCaptcha, setValidCaptcha] = useState(true);
    const [respCode, setRespCode] = useState(null);
    const [isopen, setOpen] = useState(false);
    const [message, setMessage] = useState({});
    const [title, setTitle] = useState(null);
    const [error, setError] = useState(false);
    const [noUserMessage, setNoUserMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { control, watch, formState: { errors } } = useForm({
        defaultValues: { email: "", password: "" },
    });

   
    
  /**
   * Lista de rutas de home que no son de ventas e inventarios
   */
  const othersHome = [
    "/user/homeBillCE",
    "/user/homePayrollCEN",
    "/user/homeEmissionCEN",
    "/user/homeReceptionCEN",
  ];


  /**
   * Cerrar el modal de error
   */
  const handleAction = () => {
    setIsOpenSupportModal(false);
  };

  /**
  * Use Dispatch
  */
  const dispatch = useDispatch();

  /**
  * Use Selector
  */
  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);
   /**
   * Obtener información del usuario
   */
       useEffect(() => {
        // Validar que haya token de acceso
        const loadUserInfo = async () => {
          if (localStorage.getItem("infoToken")) {
            //Valida si la pestaña o navegador fue cerrado
            const activeSession = sessionStorage.getItem("activeSession");
            if (activeSession) {
              fetchData();
            } else {
              await handleLogOut();
            }
          }
        };
        loadUserInfo();
      }, [userStatus, dispatch]);
  const handleClose = () => {
      onClose();
  };


    const handleChangeBorder = (value) => {
        setIsBorderFocus(!!value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLoginDisabled = () => {
        return !(watch("email") && watch("password"));
    };
      /**
   * Función para buscar si se tiene contratado
   * el módulo de Ventas e Inventario
   * @param {*} menu lista de opciones de menú contratadas
   * @returns true si se tiene contratado el módulo
   */
  const findHomeSalesStock = (menu) => {
    if (!Array.isArray(menu)) {
      console.error("`menu` no es un arreglo:", menu);
      return false;
    }
    for (const optionMenu of menu) {
      if (optionMenu.menuOptionName === "Ventas e Inventarios" || optionMenu.nameEnglish === "Sales and Stocks") {
        return true;
      }
    }
    return false;
  };
  
   /**
   * Función identificar si la url es de un home
   * @param {*} url url a revisar
   * @returns true si es un home
   */
   const excludeUrl = (url, routes) => {
    for (const urlHome of routes) {
      if (url.includes(urlHome)) {
        return true;
      }
    }
    return false;
  };
/**
   * Función que cierra la sesión del usuario
   */
const handleLogOut = async () => {
  try {
    const {
      status,
      data: { responseCode },
    } = await LogoutService();

    if (
      status === CODES.COD_RESPONSE_HTTP_OK &&
      (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
        responseCode === CODES.COD_RESPONSE_HTTP_BAD_REQUEST)
    ) {
      localStorage.clear();
    }
  } catch (error) {
    console.log("==============Error handleLogout======================");
    console.log(error);
    console.log("====================================");
  }
};
   /**
   * Función para buscar la primera opción de home que no sea la de ventas e inventarios
   * @param {*} menu lista de opciones de menú contratadas
   * @returns url del home
   */
   const findFirstOtherHome = (menu) => {
    let urlHome = "";
    for (const optionMenu of menu) {
      if (urlHome !== "") {
        break;
      } else {
        for (const option of optionMenu.levels) {
          if (
            option?.url?.includes("/user/home") &&
            excludeUrl(option.url, othersHome)
          ) {
            urlHome = `${option.url.split("user")[1]}`;
            break;
          } else {
            urlHome = "/home";
          }
        }
      }
    }
    return urlHome;
  };
   
   /**
   * Función para obtener la ruta del home inicio
   * @param {*} menu lista de opciones de menú contratadas
   * @returns ruta del home
   */
   const getHomeRoute = (menu) => {
    if (!Array.isArray(menu)) {
      return "/home"; 
    }
    let route = "";
    if (findHomeSalesStock(menu)) {
      route = "/home";
    } else {
      route = findFirstOtherHome(menu);
    }
    return route;
  };
  

  const handleLogin = async (data) => {
    try {
        setIsLoading(true);
        const { email, password } = data;

        // Credenciales válidas
        const obj = {
            email: "soporteprescriptiva@gmail.com",
            password: "Soporte1**",
        };

        // Validar credenciales
        if (email === obj.email && password === obj.password) {
            console.log("Login exitoso");
            try {
                const {
                    status: checkStatus,
                    data: { responseCode: responseCodeCheck },
                } = await CheckSession(obj);

                if (checkStatus === CODES.COD_RESPONSE_HTTP_OK) {
                    // Login exitoso
                    if (responseCodeCheck === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
                        const {
                            status: loginStatus,
                            data: {
                                responseCode: responseCodeLogin,
                                responseMessage: responseMessageLogin,
                            },
                        } = await LoginService(obj);

                        // Verificar si se realizó el login correctamente
                        if (loginStatus === CODES.COD_RESPONSE_HTTP_OK) {
                            switch (responseCodeLogin) {
                                case CODES.COD_RESPONSE_SUCCESS: {
                                    // Login exitoso
                                    sessionStorage.setItem("activeSession", "true");
                                    dispatch(resetUserStatus());

                                    const accessToken = responseMessageLogin.accessToken;
                                    localStorage.setItem("accessToken", accessToken);

                                    const refreshToken = responseMessageLogin.refreshToken;
                                    localStorage.setItem("refreshToken", refreshToken);

                                    const jwtTokenInfo = responseMessageLogin.idToken;
                                    localStorage.setItem("infoToken", jwtTokenInfo);

                                    const payloadToken = responseMessageLogin.idToken;
                                    localStorage.setItem(
                                        "payloadToken",
                                        JSON.stringify(payloadToken)
                                    );

                                    localStorage.setItem("tmpEmail", email);
                                    localStorage.setItem("tmpPassword", password);
                                    await fetchData();
                                    break;
                                }

                                case CODES.COD_RESPONSE_LOGIN_FIRST_TIME:
                                    // Login primera vez
                                    localStorage.setItem("tmpPassword", data.password);
                                    localStorage.setItem("tmpEmail", data.email);
                                    sessionStorage.setItem("activeSession", "true");
                                    navigate("/changePasswordFT");
                                    break;

                                case CODES.COD_RESPONSE_ERROR_LOGIN:
                                    // Login error
                                    setInvalidPasswordMessage(true);
                                    break;

                                default:
                                    setError(true);
                                    setIsOpenModalInfo(true);
                                    break;
                              }
                          }
                      } else if (responseCodeCheck === CODES.COD_RESPONSE_ERROR_LOGIN) {
                          setNoUserMessage(true);
                      } else {
                          localStorage.setItem("tmpEmail", data.email);
                          setError(true);
                          setIsOpenModalInfo(true);
                      }
                  } else {
                      setValidCaptcha(false);
                  }
              } catch (error) {
                  console.log(
                      "===============ERROR LOGIN.JSX handleLogin ====================="
                  );
                  console.log(error);
                  console.log("====================================================");
              }

              // Extraer datos de `dataFilters`
              const { companyName } = dataFilters;
              const { value } = dataCompany;
              console.log(dataFilters);
              console.log(dataCompany);

              if (!companyName || !value) {
                  console.error("Datos incompletos de la empresa en `dataFilters`");
                  setIsOpenModalInfo(true);
                  return;
              }

              // Guardar información en localStorage
              localStorage.setItem("selectedCompanyName", companyName);
              localStorage.setItem("typeCompany", value);
              navigate("/user" + getHomeRoute(userInfo.lstMenu));
          } else {
            setIsOpenModalInfo(true);
            handleAction();
            setTitleModal(t("App.support.restricted"));
            setRespCodeModal(t("App.support.message"));
            
          }
      } catch (error) {
        setIsOpenModalInfo(true);
      } finally {
          setIsLoading(false);
      }
  };

  /**
     * Obtener la información del usuario
     */
  const fetchData = async () => {
    try {
      if (userStatus === "idle") {
        dispatch(fetchUser());
      }
      if (userInfo) {
        if (localStorage.getItem("infoToken")) {
          const lastUpdate = userInfo?.lastPasswordChange || "2021-10-10";
          // Verificar que sea vigente la contraseña
          verifyValidatePassword(lastUpdate);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
   /**
   * Verificar si la contraseña está vigente o no
   * de acuerdo a la fecha de actualización
   * @param {*} date // fecha de actualización de la contraseña
   */
   const verifyValidatePassword = (date) => {
    const fecha = new Date();
    const currentYear = fecha.getFullYear();
    const currentMonth = fecha.getMonth() + 1;

    // Obtener la fecha pasada
    const pastDate = new Date(date);
    const pastYear = pastDate.getFullYear();
    const pastMonth = pastDate.getMonth() + 1;
    const differenceInMonths =
      (currentYear - pastYear) * 12 + (currentMonth - pastMonth);

    // Verificar si la contraseña tiene más de 3 meses de antigüedad
    if (differenceInMonths >= 3) {
      setTitle(t("App.validatePassword.mainTitle"));
      setRespCode(CODES.COD_RESPONSE_HTTP_BAD_REQUEST);
      setMessage(t("App.validatePassword.message"));
      setIsOpenModalInfo(true);
    } else {
      setIsOpenModalInfo(false);
      localStorage.removeItem("tmpPassword");
      localStorage.removeItem("tmpEmail");
    }
  };


  let titleColor = "warning";
  if (status === CODES.COD_RESPONSE_HTTP_OK) {
      titleColor =
      data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
      data.responseCode === CODES.COD_RESPONSE_HTTP_OK ||
      data.responseCode === CODES.COD_RESPONSE_HTTP_CREATED
          ? "success"
          : "error";
  } else if (status === CODES.COD_RESPONSE_HTTP_ERROR) {
      titleColor = "error";
  }
  const DialogTitleContainer = (props) => {
    const { children, onClose, close = true, ...other } = props;
  
    return (
      <DialogTitle {...other}>
        {children}
        {onClose && close && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              width: 48, // Aumenta el tamaño del botón
              height: 48,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ color: "#543ab4",fontSize: 22}} />
          </IconButton>
        )}
      </DialogTitle>
    );
  };
  return (
    <div>
      <Dialog
        className="modal__support"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
        maxWidth= "100%"
      >
        <Grid className="modal__support__body" style={{ padding: "2rem"}} maxWidth= "100%" xl={12} >
          <Grid item className="heading-secondary__md-one" xs="5">
            <p>
            {t("App.validationMessages.supportLogin")}
            </p>
            <DialogTitleContainer onClose={handleClose}></DialogTitleContainer>
          </Grid>
          <Grid item  className="heading-secondary__md-two"  maxWidth="100%" sx={{ alignItems: "left"}}  xl={12}>
            <Controller
              defaultValue=""
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  className={
                    errors.email
                      ? "login__form-input--error"
                      : isBorderFocus
                      ? "login__form-input--focus"
                      : "login__form-input"
                  }
                  type="email"
                  name="email"
                  id="email"
                  label={t("App.login.rightPanel.email")}
                  sx={{
                    marginBottom: "16px",
                    label: {
                      fontFamily: "Roboto !important",
                      fontSize: "14px !important",
                      translate: "0px -3px",
                      alignItems: "center", 
                      padding: "4px",
                      transformOrigin: "-45px -40px",
                      "&.Mui-focused": {
                        color: "#8C8D9D", // Mismo valor para mantener alineación
                      },
                    },
                  }}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleChangeBorder(e.target.value);
                  }}
                  InputProps={{
                    autoComplete: "none !important",
                    sx: {
                      height: "100% !important",
                      width: "100% !important",
                      "&:-webkit-autofill": {
                        backgroundColor: "white !important",
                        boxShadow: "0 0 0 100px white inset !important",
                        borderRadius: "8px",
                      },
                    },
                  }}
                />
              )}
            />
            <Grid item sx={{ paddingTop:"16px"}}>
              {errors?.email ? (
                <p className="changePasswordFT__form-errorText">
                  {errors?.email?.message}
                </p>
              ) : null}
              {noUserMessage ? (
                <p className="login-error-message">
                  {t("App.login.errors.noUser")}
                </p>
              ) : null}
            </Grid>
            <Controller
              defaultValue=""
              name="password"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  className={
                    errors?.password
                      ? "login__form-input--error"
                      : isBorderFocus
                      ? "login__form-input--focus"
                      : "login__form-input"
                  }
                  disableUnderline
                  id="password"
                  label={t("App.login.rightPanel.password")}
                  sx={{
                    '& .MuiInputBase-input': {
                            marginTop: '2px',
                            borderRadius: '8px',
                            padding: '8px 4px 7px 14px',
                          },
                    label: {
                      fontFamily: "Roboto !important",
                      fontSize: "14px !important",
                      translate: "0px -5px",
                      alignItems: "center", 
                      padding: "4px",
                      transformOrigin: "-45px -36px",
                      "&.Mui-focused": {
                        color: "#8C8D9D",
                      },
                    },
                  }}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => {
                    onChange(e);
                    handleChangeBorder(e.target.value);
                  }}
                  value={value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          sx={{
                            padding: "0px !important",
                            marginRight: "-10px",
                          }}
                        >
                          {showPassword ? (
                            <VisibilityIcon
                              className={
                                errors?.password
                                  ? "icon--error"
                                  : isBorderFocus
                                  ? "icon--focus"
                                  : "icon"
                              }
                            />
                          ) : (
                            <VisibilityOffIcon
                              className={
                                errors?.password
                                  ? "icon--error"
                                  : isBorderFocus
                                  ? "icon--focus"
                                  : "icon"
                              }
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    autoComplete: "off ",
                    sx: {
                      height: "100% !important",
                      width: "100% !important",
                      "&:-webkit-autofill": {
                        backgroundColor: "white !important",
                        // boxShadow: "0 0 0 100px white inset !important",
                        borderRadius: "8px",
                      },
                    },
                  }}
                />
              )}
            />
        </Grid>
        
        <div className="button__container-support">
            <button
                variant="contained"
                className="login__form-button--support_cancel"
                onClick={handleClose}
            >
                {t("App.buttonLabels.cancel")}
            </button>
            <button
                disabled={handleLoginDisabled()}
                className={
                    handleLoginDisabled()
                    ? "login__form-button--disabled-support"
                    : "login__form-button--support"
                }
                onClick={() => handleLogin({ email: watch("email"), password: watch("password") })}
                
            >
                {!isLoading ? (
                    t("App.login.rightPanel.button")
                ) : (
                    <CircularProgress size={16} color="inherit" />
                )}
            </button>
          </div>
        </Grid>
        </Dialog>
    </div>
  );
};

export default ModalSupportLogin;
